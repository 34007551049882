
const Rodo = () => {
    return (
        <div className="regulations-wrapper">
            <div className="text">
                Szanowny czytelniku, 25 maja 2018 roku weszło w życie Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r (RODO). Potrzebujemy Twojej zgody na przetwarzanie Twoich danych osobowych przechowywanych w plikach cookies. Poniżej znajdziesz pełny zakres informacji na ten temat.<br/>
                Zgadzam się na przechowywanie na urządzeniu, z którego korzystam tzw. plików cookies oraz na przetwarzanie moich danych osobowych pozostawianych w czasie korzystania przeze mnie ze stron internetowych lub serwisów oraz innych parametrów zapisywanych w plikach cookies w celach marketingowych, w tym na profilowanie i w celach analitycznych przez Vysoko Group Łukasz Wysoczański
            </div>
            <div className="regulations-bold regulations-subtitle-privacy">Administratorzy danych / Podmioty którym powierzenie przetwarzania powierzono</div>
            <ul>
                <li>Vysoko Group Łukasz Wysoczański z siedzibą przy al. Obrońców Lwowa 12/1, 56-100 Wołów, NIP 9880264456, REGON 022524861</li>
            </ul>
            <div className="regulations-bold">Cele przetwarzania danych</div>
            <ul>
                <li>marketing, w tym profilowanie i cele analityczne</li>
                <li>świadczenie usług drogą elektroniczną</li>
                <li>dopasowanie treści stron internetowych do preferencji i zainteresowań</li>
                <li>wykrywanie botów i nadużyć w usługach</li>
                <li>pomiary statystyczne i udoskonalenie usług (cele analityczne)</li>
            </ul>

            <div className="regulations-bold">Podstawy prawne przetwarzania danych</div>
            <ul>
                <li>marketing, w tym profilowanie oraz cele analityczne - zgoda</li>
                <li>świadczenie usług drogą elektroniczną - niezbędność danych do świadczenia usługi</li>
                <li>pozostałe cele - uzasadniony interes administratora danych</li>
            </ul>
            <div className="regulations-bold regulations-subtitle-privacy">Odbiorcy danych</div>
            <div className="text">
                Podmioty przetwarzające dane na zlecenie administratora danych, w tym Zaufani Partnerzy, agencje marketingowe oraz podmioty uprawnione do uzyskania danych na podstawie obowiązującego prawa.
            </div>
            <div className="regulations-bold regulations-subtitle-privacy">Prawa osoby, której dane dotyczą</div>
            <div className="text">
                Przysługują Ci następujące prawa w związku z przetwarzaniem Twoich danych osobowych:
            </div>
            <ul>
                <li>prawo dostępu do Twoich danych, w tym uzyskania kopii danych,</li>
                <li>prawo żądania sprostowania danych,</li>
                <li>prawo do usunięcia danych (w określonych sytuacjach),</li>
                <li>prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych,</li>
                <li>prawo do ograniczenia przetwarzania danych.</li>
            </ul>
            <div className="text">
                W zakresie w jakim Twoje dane są przetwarzane na podstawie zgody lub w ramach świadczonej usługi (dane są niezbędne w celu świadczenia usługi) możesz dodatkowo skorzystać z poniższych praw:
            </div>
            <ul>
                <li>prawo do wycofania zgody w zakresie w jakim są przetwarzane na tej podstawie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.</li>
                <li>prawo do przenoszenia danych osobowych, tj. do otrzymania od administratora Twoich danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego.</li>
            </ul>
            <div className="text">
                W celu skorzystania z powyższych praw Grupa AlpIndus Łukasz Wysoczański udostępnia następujący kanały komunikacji:
            </div>
            <ul>
                <li>forma pisemna</li>
            </ul>
            <div className="text">
                Vysoko Group Łukasz Wysoczański, Al. Obrońców Lwowa 12/1, 56-100 Wołów
            </div>
            <ul>
                <li>forma mailowa </li>
            </ul>
            <div className="text">
                dragopigula@gmail.com
            </div>
            <div className="regulations-bold"><br />Informacje dodatkowe</div>
            <div className="text">
                Więcej o zasadach przetwarzania danych w "Polityce prywatności"
            </div>
        </div>
    )
}

export default Rodo