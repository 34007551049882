import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import Header from "./Header"
import { REGISTER_RESET } from '../constants/types'
import { accountActivation } from '../actions/user'
import { Redirect, useHistory } from 'react-router-dom'
import SiteNotice from './SiteNotice'
import { ACCOUNT_ACTIVATION_FAILED_RESET } from '../constants/types'
const RegisterActivation = () => {
    const [formData, setFormData] = useState({
        email: "",
        code: ""
    })
    const dispatch = useDispatch()
    const history = useHistory()
    const [registerData, setRegisterData] = useState("")
    const emailUrl = window.location.href    

    const { accActivation, accActivationFailed } = useSelector((state) => state.user)
    useEffect(() => {
        dispatch({ type: REGISTER_RESET })
    }, [])

    const accountActivate = (e) => {
        e.preventDefault()
        dispatch(accountActivation(formData))
    }

    const changeFormData = (e) => {
        setFormData({
            ...formData,
            email: registerData,
            code: e.target.value
        })
    }

    useEffect(() => {
        if (accActivation) history.push('/user/register-confirm')
    }, [accActivation, history])

    useEffect(() => {
        if (accActivationFailed) {
            dispatch({ type: ACCOUNT_ACTIVATION_FAILED_RESET })
        }
    }, [formData, dispatch])

    useEffect(() =>{
        if(emailUrl.includes('email=')){
            setRegisterData(emailUrl.split('=').slice(1).join(''))
        }
        if(!emailUrl.includes('email=') && JSON.parse(localStorage.getItem('registerData'))){
            setRegisterData(JSON.parse(localStorage.getItem('registerData')))
        }
        else {
            return <Redirect to='/' />;
        }
    }, [])
   
    return (

        <div className="page__layout">
            <header className="page__header header">
                <Header />
            </header>

            <div className="page__mainWpr">
                <main className="page__main">
                    <div className="user-register-form user-register-form--activation">
                        <div className="user-register-confirmation">
                            <h2 className="block-title">Dziękujemy za rejestrację</h2>
                            <p className="confirmation-msg">Na Twoją skrzynkę e-mail wysłaliśmy pin aktywacyjny.</p>
                            <p className="confirmation-msg">By aktywować konto użyj kodu pin.</p>

                            <form className="form__activation" onSubmit={accountActivate}>
                                <div className="form__item">
                                    <div className="description">Kod Pin</div>
                                    <label className="form__label visually-hidden">Kod pin</label>
                                    <input type="text" className="form-text form__field" placeholder="Pin" value={formData.code} onChange={changeFormData} />
                                </div>
                                <input className="btn btn-user form_submit" type="submit" value="Aktywuj konto" />

                            </form>
                        </div>
                        {accActivationFailed && <SiteNotice passwordChange={true} />}
                    </div>

                </main>

            </div>

        </div>
    )
}

export default RegisterActivation