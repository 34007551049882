import { SET_SUBSCRIPTIONS_PLANS, CREATE_SUBSCRIPTIONS_PLAN, SELECTED_PLAN, DISCOUNT_CODE, DISCOUNT_CODE_FAILED, DISCOUNT_CODE_FAILED_RESET  } from "../constants/types";

const initialState = {
    subscriptionPlans: [],
    newSubscription: "",
    selectedPlan: "",
    discountCode: "",
}
const subscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBSCRIPTIONS_PLANS:
            return {
                ...state, subscriptionPlans: action.payload
            }
        case CREATE_SUBSCRIPTIONS_PLAN:
            return {
                ...state, newSubscription: action.payload
            }
      
        case SELECTED_PLAN:
            return {
                ...state, selectedPlan: action.payload
            }
        default:
            return state;
    }
}

export default subscriptionsReducer