const validationForm = (isregistration, values, checkboxValue, inputTypes) => {
    let errors = {};

    if (inputTypes.campaignCode && !values.campaignCode) {
        errors.campaignCode = "Kod polecenia wymagany"
    }
    if (inputTypes.campaignTitle && !values.campaignTitle) {
        errors.campaignTitle = "Nazwa kampanii wymagana"
    }
    if (inputTypes.email && !values.email) {
        errors.email = "Email wymagany"
    } else if(inputTypes.email && !/\S+@\S+\S+/.test(values.email)){
        errors.email = "Błędny format adresu e-mail"
    }
    if(inputTypes.existingpass && !values.existingpass){
        errors.existingpass = "Hasło wymagane"
    }
     if(inputTypes.pass && !values.pass) {
        errors.pass = "Hasło wymagane"
    } 
   else if (inputTypes.pass && !values.pass.match(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/) && isregistration) {
        errors.pass = "Hasło musi zawierać minimum 8 znaków, przynajmniej jedną wielką literę, przynajmniej jedną cyfrę"
    }
    if(inputTypes.checkboxValue && !checkboxValue){
        errors.checkbox = "Musisz zaakceptować regulamin"
    }
    return errors
}

export default validationForm