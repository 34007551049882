import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../pages/Login'
import Register from '../pages/Register'
import RegisterActivation from '../components/RegisterActivation'
import RegisterConfirm from '../components/RegisterConfirm'
import DeleteAccountConfirm from '../components/DeleteAccountConfirm'
import Password from '../pages/Password'
import Home from '../pages/Home'

import PasswordNew from '../components/passwordReset/PasswordNew'
import Regulations from '../components/Regulations'
import Rodo from '../components/Rodo'
import PrivacyPolicy from '../components/PrivacyPolicy'
import PaymentConfirm from '../components/accountManagement/PaymentConfirm'
import Affiliate from '../components/Affiliate'
import ContactRules from "../pages/ContactRules";

const Routes = () => {
    return (
        <Switch>
            <Route path={'/user/login'} component={Login} />
            <Route path={'/user/register'} component={Register} />
            <Route path={'/user/register-activation'} component={RegisterActivation} />
            <Route path={'/user/register-confirm'} component={RegisterConfirm} />
            <Route path={'/user/password'} component={Password} />
            <Route path={'/user/password-new'} component={PasswordNew} />
            <Route path={'/user/privacy-policy'} component={PrivacyPolicy} />
            <Route path={'/user/rodo'} component={Rodo} /> 
            <Route path={'/user/regulations'} component={Regulations} />
            <Route path={'/delete-confirm'} component={DeleteAccountConfirm} />
            <Route path={'/payment-confirm'} component={PaymentConfirm} />
            <Route path={'/affiliate/:id'} component={Affiliate} />
            <Route path={'/'} component={Home} />
        </Switch>
    );
};

export default Routes