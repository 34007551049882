const FunctionalityNotAvailableModal = ({ closeModal }) => {
    return (
        <>
            <div className="modal__wrapper">Sekcja komentarzy aktualnie jest niedostępna. 
            <p>Pracujemy nad tym.</p></div>
            <button className="modal__close btnReset" onClick={closeModal}/>
        </>
    )
}

export default FunctionalityNotAvailableModal