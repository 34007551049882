import { useSelector } from "react-redux"
import { useSiteMessage } from "../hooks/useSiteMessage"
const SiteNotice = (props) => {
    const { typeMessage } = useSelector((state) => state.user)
    const sitemessage = useSiteMessage()
    return (
        sitemessage ? (
            <div className={typeMessage === "confirm" ? "siteNotice siteNotice--confirm" : "siteNotice siteNotice--alert"}>
                <div className={typeMessage === "confirm" ? "siteNotice__item siteNotice__item--confirm" : "siteNotice__item siteNotice__item--alert"}>
                    <div role="contentinfo" aria-label={props.type}>
                        <div role="alert" className={typeMessage === "confirm" ? "siteNotice__message siteNotice__message--confirm" : "siteNotice__message siteNotice__message--alert"}>
                            <h2 className="visually-hidden">{props.type}</h2>
                            {sitemessage}
                        </div>
                    </div>
                </div>
            </div>
        )
            : null
    )
}

export default SiteNotice