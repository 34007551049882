const ReleaseBar = (props) => {

    return (
        <>
            {props.releaseNumber ? (
                <div className={props.index === 0 ? "article__edition first" : "article__edition"}>
                    <div className="edition__number">#{props.releaseNumber}</div>
                    <div className="edition__text">Wydanie {props.releaseDate}</div>
                </div>
            ) : (
                <div className="article__edition">
                    <div className="edition__text">Wiadomości nieposortowane według wydań</div>
                </div>
            )}
        </>
    )
}

export default ReleaseBar