import {useDispatch, useSelector} from "react-redux";
import {SHOW_MODAL} from "../../constants/types";

const BurgerMenu = () => {

    const {modal, isMobile} = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const showMobileMenu = () => {
        dispatch({ type: SHOW_MODAL, payload: 'mobile-sidebar' })
    }

    if(isMobile === "0" && modal === 'mobile-sidebar'){
        dispatch({ type: 'CLOSE_MODAL'})
    }
    return (
        <div className="menu__button" onClick={showMobileMenu}/>
    )
}

export default BurgerMenu