export const API_URL = 'https://api.infopigula.pl'

export const setHeaders = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    const csrf_token = JSON.parse(localStorage.getItem('csrf_token'));
    if (token) {
        return {
            'Content-type': 'application/json',
            'X-CSRF-Token': csrf_token,
            'Authorization': `Bearer ${token}`
        };
    }
}
