const NotFound = (props) => {
    return (
       <div className="eror__content">
           <div className="Etitle">404</div>
           <div className="Etext">Page not found <b>:(</b></div>
           <div className="gears">
               <div className="gear one">
                   <div className="bar"></div>
                   <div className="bar"></div>
                   <div className="bar"></div>
               </div>
               <div className="gear two">
                   <div className="bar"></div>
                   <div className="bar"></div>
                   <div className="bar"></div>
               </div>
               <div className="gear three">
                   <div className="bar"></div>
                   <div className="bar"></div>
                   <div className="bar"></div>
               </div>
           </div></div>
    )
}

export default NotFound