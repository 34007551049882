import optionPanel from '../../abv/src/images/opcje.png'
import optionList from '../../abv/src/images/opcjeLista.png'
const IosInfoModalSafari = ({ closeModal }) => (
    <>
    <div className="modal__wrapper">
       <div className="modal__sectionTitle">By pobrać aplikację na system iOS należy wykonać następujące kroki:</div>
       <ul className="modal__instructionList">
           <li>1. Przejdź do widoku <span className="bold">Opcje</span></li>
           <li className="modal__instructionImg"><img src={optionPanel} alt="x" /></li>
           <li>2. Wybierz opcję: 'Dodaj do ekranu początk.'</li>
           <li className="modal__instructionImg"><img src={optionList} alt="" /></li>
           <li>3. Po zatwierdzeniu aplikacja zostanie zainstalowana.</li>
       </ul>
    </div>

        <button className="modal__close btnReset" onClick={closeModal}>
            <span className="visually-hidden">Close modal window</span>
        </button>
    </>
)

export default IosInfoModalSafari