import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from 'react-player'


import { useSelector } from "react-redux";
const ImageModal = ({ closeModal, nid, articles }) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true
    };

   const multimedia = articles.filter(row => row.nid === nid)

   const field_multimedia = multimedia[0].field_multimedia_slider ? multimedia[0].field_multimedia_slider?.split(",") : null
   const video = multimedia[0].youtube_video ? multimedia[0].youtube_video?.split(",") : null

    const playerStyle = {
      margin: "auto",
      width: '100%',
      maxWidth: '640px'
    }

    const images = field_multimedia ? field_multimedia.map(item => (
        <div>
            <img src={item} alt="x" />
        </div>
    )): null

    const videos = video ? video.map(item => (
        <div>
            <ReactPlayer url={item} controls={true} style={playerStyle} width={'100%'}/>
        </div>
    )) : null

    return (
        <div className="imgModal-wrapper">
            <Slider {...settings}>
                {images}
                {videos}
            </Slider>
            <button className="modal__close btnReset" onClick={closeModal}>
                <span className="visually-hidden">Close modal window</span>
            </button>
        </div>
    );
}

export default ImageModal