import {useState} from "react";
import { useSelector } from "react-redux";

const FaqTutorial = () => {

    const {nativeApp} = useSelector(state => state.user)

    const Block = (props) =>{
        const [show, setShow] = useState(false)
        return(
            <>
                <button onClick={()=>setShow(!show)} className={show ? "accordion active" : "accordion"}>{props.element.title}</button>
                <div className={show ? "panel" : "panel hide"}>
                    <div className="panel__text">
                        {props.element.text}
                    </div>
                </div>
            </>
        )
    }

    const elements = [
        {
        title: "Ocena wagi",
        text: <div>
                <div>Oceniamy wagę / doniosłość zdarzenia w skali 1-5. Ty decydujesz co jest najważniejsze, a Twój głos wiele znaczy! Dlatego prosimy – oddaj go rozsądnie.</div> 
                <div>Uwaga: nie oceniamy tego, czy dany news nam się podoba! </div> 
                <div>Infopiguła zakłada, że każdy news w naszym wydaniu jest ważny. Jednak to Wy ocenicie jak bardzo. Oto nasza propozycja skali:</div>
                <div>1 – mogłoby tego newsa nie być jak dla mnie</div>
                <div>2 – trochę istotne</div>
                <div>3 – mocna pozycja w wydaniu, ale nie doniosła </div> 
                <div>4 – bardzo istotne (wg nas np. powódź w Niemczech, otwarcie nowego odcinka drogi ekspresowej etc.)</div>
                <div>5 – doniosłe w skali całego roku, kraju lub świata (np. wybór nowego prezydenta Polski, wybuch wojny między ważnymi państwami, wielka katastrofa, budowa gazoportu, skuteczny lek na raka, itd.)</div>
                <div> Nasz system przelicza średnią Waszych głosów i ją uwidacznia. Oddany głos zmienia gwiazdkę z czarnej na niebieską, co jest fajnym odznaczeniem tego co już przeczytaliście.</div> 
                <div>Uwaga: dzięki systemowi oceny wagi możesz filtrować newsy z dowolnego okresu wg wagi!
                Przykład? Chcesz po pół roku przypomnieć sobie najważniejsze wydarzenia?</div> 
                <div>A może nie zaglądałeś do nas przez cały tydzień i 120 newsów z tego okresu to zbyt wiele, więc chciałbyś poznać tylko to co najważniejsze?</div>
                <div>Zaznaczasz wydania z dowolnego okresu i wybierasz "sortuj wg wagi".</div>
                <div>Gotowe! Teraz od góry lecą najważniejsze newsy wg Waszych ocen. Czytasz tyle newsów ile chcesz, na pewno nic ważnego Cię nie ominie.</div>
        </div>
    },
    {
        title: "2 tryby przeglądania najnowszego wydania",
        text: <div>
                <div>Wolisz scrollować news po newsie w dół w widoku klasycznym?</div> 
                <div>A może wolisz slajdnąć palcem po ekranie i czytać grubszą czcionkę, gdzie 1 slajd = 1 news?</div>
                <div>Takie rzeczy tylko w Infopigule – połączenie 2 lubianych trybów w 1 serwisie informacyjnym i wybór dla użytkownika.</div>
                <div>Służy temu przełącznik, który widzisz w prawym rogu ekranu telefonu w formie niebieskich strzałeczek.</div>
                <div>Uwaga: opcja działa tylko na smartfonach i dotyczy tylko poszczególnych wydań.</div> 
        </div>
    },
    {
        title: "Twórcy",
        text: <div>
            W menu po lewej widnieje pozycja "Twórcy". Będziemy tam systematycznie dodawać twórców najbardziej wartościowego kontentu w polskim internecie. Wybierasz z którym z nich chcesz być na bieżąco i otrzymasz cały utworzony przez niego kontent (tekst lub video) w swoim najnowszym wydaniu. Dzięki temu nie ominie Cię nic co lubisz, a Infopiguła stanie się miejscem, w którym będziesz miał nie tylko najnowsze informacje, ale i swój ulubiony kontent. Jest to autorski pomysł Infopiguły.
        </div>
    },
    {
        title: "Live",
        text: <div>
            Serwis informacyjny poza skrótami najważniejszych wydarzeń powinien "na gorąco" informować Was o tym co najpilniejsze z pilnych (nowe obostrzenia, wielkie odkrycie, wybuch dużej wojny etc.). Tak też robimy. Informacja która nie powinna Cię ominąć dotrze na Twój telefon powiadomieniem typu "push" oraz pojawi się w ostatnim wydaniu Infopiguły w kanale "Live", który zmieni kolor z szarego na niebieski, gdy pojawi się tam coś super ważnego.
        </div>
    },
    {
        title: "Polecamy",
        text: <div>
            Będą to twórcy wartościowego naszym zdaniem kontentu w polskim internecie, których szczerze polecamy, lub z którymi (z ww. powodu) weszliśmy we współpracę (np. wzajemnie się promujemy).  Logotypy przekierują Was do miejsc, gdzie owi twórcy kontentu posiadają swoje podstawowe kanały (youtube, facebook, własna strona etc.).
        </div>
    },
    {
        title: "Ulubione",
        text: <div>
            Kliknij serduszko pod interesującym Cię newsem a zapisze się on w Ulubionych w lewym dolnym rogu menu. Teraz będziesz go miał pod ręką.
        </div>
    },
    {
        title: "Personalizacja wyglądu",
        text: <div>
            Kliknij "Zarządzanie kontem" w menu z lewej strony. Tam możesz:
            <ul>
                <li>wyłączyć wysyłkę wydań Infopiguły na Twój adres mailowy.
                    Wtedy mailowo otrzymasz jedynie sporadyczne, najważniejsze informacje dotyczące naszego projektu, tak żebyś wiedział o nadchodzącej dużej zmianie / nowości.</li>
                <li>ustawić sobie tryb dark mode</li>
                <li>wyjustować tekst newsów</li>
                <li>wybrać swój preferowany kolor tła dla newsów w trybie slajdów (efekt widoczny tylko w smartfonach)</li>
                <li>wybrać rodzaj i rozmiar czcionki w jakim czyta Ci się najlepiej</li>
            </ul> 
        </div>
    },
    {
        title: "Bezpieczeństwo Waszych danych",
        text: <div>
            Zbieramy jedynie Wasze adresy mailowe. Nie przekazujemy ich nigdzie i nikomu. 
            Po usunięciu konta w Infopigule usuniemy Twój adres z systemu.
        </div>
    },
    {
        title: "Cena",
        text: <div>
                <div>Cena premium w przypadku wykupienia tylko 1 miesiąca z góry została ustalona na 9 zł.
                Jednak już przy wykupieniu pół roku z góry cena spada poniżej 7 zł / m-c, a przy roku z góry aż do poniżej 6 zł / m-c.</div> 
                <div>Cena za pojedynczy miesiąc uległa zwiększeniu, gdyż: a{')'} stworzyliśmy dla Was zaawansowane narzędzia, których działanie w krótkim okresie czasu stanie się niezawodne, b{')'} musieliśmy uwzględnić 15% prowizji pobierane przez sklepy Google / Apple, gdy opublikujemy w nich nasze aplikacje.
                </div>
        </div>
    },
    {
        title: "VIP",
        text: <div>
                <div>Nasza strona / aplikacje tylko z pozoru są proste i minimalistyczne. W praktyce zaprogramowaliśmy w nich wiele skomplikowanych procesów, które działają właśnie po to, żebyście otrzymali wiele ale w formie minimalistycznej i estetycznej.</div>
                <div>Koszt produkcji przekroczył nasze oczekiwania i jest kwotą 6-cyfrową. </div>
                <div>Wybierając plan VIP po prostu sprzyjasz znacznie bardziej naszemu wzrostowi, który odbywa się bez reklam i bez inwestorów. Stajesz się prawdziwym Przyjacielem i Mecenasem projektu.</div>
                <div>Z czasem wprowadzimy dla Ciebie bonusy, które będą dostępne tylko dla VIP-ów. </div>
                <div>Będziemy konsultowali z Tobą kierunek ewolucji projektu. </div>
                <div>Otrzymasz dostęp do materiałów extra z serii "Infopiguła poleca". Będą to najlepsze artykuły, czy filmy (np. dokumentalne, wywiady etc.) z jakimi zetkniemy się w toku przeczesywania internetu.
                Twój awatar będzie podpisany "VIP" i będzie on widoczny dla innych, gdy skomentujesz któryś news.</div>
        </div>
    },
    {
        title: "Nie otrzymuję maila - co zrobić?",
        text: <div>
                <div>Po 1. upewnij się, że mail z PIN / linkiem aktywacyjnym / wydaniem newsów nie wpadł do któregoś z folderów: spam / oferty / społeczność itp.</div>
                <div>Po 2. dodaj nasz @ infopigula@infopigula.pl do kontaktów swojej skrzynki pocztowej. To zwiększy docieralność.</div>
                <div>Jeśli oba kroki zawiodły, wyślij nam opis problemu podając rodzaj przeglądarki, poczty i urządzenia na adres kontaktowy <a className="contact-mail" href="mailto:newsy@infopigula.pl">newsy@infopigula.pl</a>
                </div>
                <div>Obiecujemy szybką reakcję.</div>
        </div>
    }
];

    return (
        <div className="content">

            <div className="myContent faq">
                {elements.map(item =>{
                    if(nativeApp && (item.title === "Cena" || item.title === "VIP" ) ) return
                    return(
                        <Block element={item}/>
                    )
                })}

            </div>
        </div>
    )
}

export default FaqTutorial