import PasswordReset from '../components/passwordReset/PasswordReset'
import Header from '../components/Header'

const Password = () => {
    return (
        <div className="page__layout">
            <header className="page__header header">
                <Header />
            </header>

            <div className="page__mainWpr">
                <main className="page__main">
                    <PasswordReset />
                </main>

            </div>

        </div>
    )
}

export default Password

