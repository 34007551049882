import { useSelector } from "react-redux"

const PrivacyPolicy = () => {

    const {nativeApp } = useSelector(state => state.user)
    return (

        <div className="regulations-wrapper">
            <div className="regulations-info regulations-bold">
                <div>Ochrona prywatności</div>
                <div className="regulations-subtitle-privacy">Poniższa <span className="regulations-bold">POLITYKA PRYWATNOŚCI</span> – obowiązuje od 16 sierpnia 2018 r.</div>
            </div>
            <div className="regulations-subtitle-privacy">DEFINICJE</div>
            <div className="regulations-definitions">
                <div className="text text-privacy-policy">
                    <span className="regulations-bold">Administrator/My:</span> Vysoko Group Łukasz Wysoczański, Al. Obrońców Lwowa 12/1, 56-100 Wołów, NIP 9880264456, REGON 022524861
                </div>
                <div className="text text-privacy-policy">
                    <span className="regulations-bold">Dane osobowe:</span> wszystkie informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej poprzez jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej, w tym nr IP urządzenia, dane o lokalizacji, identyfikator internetowy oraz informacje gromadzone za pośrednictwem plików cookie lub innych podobnych technologii.
                </div>
                <div className="text text-privacy-policy">
                    <span className="regulations-bold">Polityka:</span> niniejsza „Polityka prywatności”.
                </div>
                <div className="text text-privacy-policy">
                    <span className="regulations-bold">RODO:</span> Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
                </div>
                <div className="text text-privacy-policy">
                    <span className="regulations-bold">Serwisy:</span> wydawane przez administratora serwisy internetowe oraz aplikacje mobilne, do których
                    stosuje się niniejszą politykę: Infopigula.pl
                </div>
                <div className="text text-privacy-policy">
                    <span className="regulations-bold">Użytkownik/Ty:</span> każda osoba fizyczna odwiedzająca serwis lub korzystająca z jednej lub kilku usług lub funkcjonalności udostępnianych w serwisie.
                </div>
                <div className="text text-privacy-policy">
                    <span className="regulations-bold">Profilowanie:</span> zautomatyzowane przetwarzanie danych osobowych, które polega na wykorzystaniu danych osobowych do oceny niektórych czynników osobowych osoby fizycznej, w szczególności do analizy lub prognozy aspektów dotyczących efektów pracy tej osoby fizycznej, jej sytuacji ekonomicznej, zdrowia, osobistych preferencji, zainteresowań, wiarygodności, zachowania, lokalizacji lub przemieszczania się.
                </div>
            </div>

            <div className="text">
                W związku z korzystaniem z naszych serwisów zbieramy i przetwarzamy twoje dane osobowe.
                Poniżej znajdziesz szczegółowe zasady oraz cele przetwarzania twoich danych osobowych.
            </div>
            <div>Ustawienia zgód</div>
            <div className="regulations-bold regulations-subtitle-privacy">W JAKIM CELU I NA JAKIEJ PODSTAWIE PRAWNEJ PRZETWARZAMY TWOJE DANE</div>
            <div className="text">
                Twoje dane takie, jak adres IP lub inne identyfikatory oraz informacje gromadzone za pośrednictwem plików cookies lub innych podobnych technologii pozyskujemy, gdy odwiedzasz nasze serwisy (także, jako użytkownik niezalogowany). Przetwarzamy je w następujących celach:
            </div>
            <ul>
                <li>w celu udostępniana treści gromadzonych w serwisie – wówczas podstawą prawną przetwarzania jest nasz uzasadniony interes polegający na rozpowszechnianiu naszych treści (art. 6 ust. 1 lit. f) RODO{`)`};
                </li>
                <li>w celach analitycznych i statystycznych – wówczas podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO{`)`}, polegający na prowadzeniu analiz aktywności użytkowników, a także ich preferencji, w celu poprawy stosowanych funkcjonalności i świadczonych usług;
                </li>
                <li>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO{`)`} polegający na ochronie naszych praw;
                </li>
                <li>w celach marketingowych naszych oraz innych podmiotów, zasady przetwarzania danych osobowych w celach marketingowych zostały opisane niżej w sekcji MARKETING.
                </li>
            </ul>
            <div className="text">
                Twoja aktywność w serwisie, w tym twoje dane osobowe, są rejestrowane w logach systemowych (specjalnym programie komputerowym służącym do przechowywania chronologicznego zapisu zawierającego informacje o zdarzeniach i działaniach dotyczących systemu informatycznego służącego do świadczenia usług) oraz poprzez skrypty analityczne. Z tych danych korzystamy przede wszystkim w celach związanych ze świadczeniem naszych usług, w celach technicznych, administracyjnych, na potrzeby zapewnienia bezpieczeństwa systemu informatycznego oraz zarządzania nim, a także w celach analitycznych i statystycznych. W powyższym zakresie podstawą prawną przetwarzania danych osobowych jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO{`)`}.
            </div>
            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">Rejestracja w serwisie</div>
                <div className="text">
                    Aby otrzymywać dostęp do pełni funkcji serwisu, jesteś proszony/a o podanie danych niezbędnych do utworzenia i obsługi konta tj. adresu e-mail. Takie dane można w każdym czasie usunąć – w tym celu na dole każdego maila od nas jest stopka, gdzie w prosty sposób możesz wypisać swój mail z serwisu. Ponadto usuniemy Twój adres e-mail z naszej bazy danych również po wyrażeniu takiego oczekiwania w wiadomości mailowej przesłanej na nasz redakcyjny adres podany na naszej stronie w dziale "O nas". Wtedy rezerwujemy sobie 7 dni roboczych na odczytanie wiadomości i usunięcie danych. Podanie danych oznaczonych jako obowiązkowe jest wymagane w celu założenia i obsługi konta, a ich niepodanie skutkuje brakiem możliwości założenia konta.
                </div>

                <div className="text">
                    <br/>
                    W związku z założeniem i obsługą konta przetwarzamy twoje dane w następujących celach:
                    <ul>
                        <li>w celu świadczenia usług związanych z prowadzeniem i obsługą konta w serwisie – podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy (art.6 ust. 1 lit. b) RODO{`)`}, a w zakresie danych podanych fakultatywnie – podstawą prawną przetwarzania jest zgoda {`(`}art. 6 ust. 1 lit. a{`)`} RODO{`)`};
                        </li>
                        <li>w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO{`)`}, polegający na prowadzeniu analiz aktywności użytkowników w serwisie i sposobu korzystania z konta, a także ich preferencji, w celu poprawy stosowanych funkcjonalności;
                        </li>
                        <li>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą
                            prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO{`)`} polegający na
                            ochronie swoich praw;
                        </li>
                        <li>w celach marketingowych naszych oraz innych podmiotów – zasady przetwarzania danych osobowych w celach marketingowych zostały opisane niżej w sekcji MARKETING
                        </li>
                    </ul>
                </div>
                <div>Dane osobowe innych osób (adres e-mail) możesz umieścić tylko pod warunkiem, że nie naruszysz praw tych osób.</div>
            </div>
            {!nativeApp &&
                <div className="regulations-paragraph">
                    <div className="regulations-bold regulations-subtitle-privacy">Składanie zamówień</div>
                    <div className="text">
                        Złożenie i realizacja zamówienia (zakupu towaru lub usługi) wiąże się z przetwarzaniem twoich danych osobowych. Podanie danych oznaczonych jako obowiązkowe jest wymagane w celu przyjęcia i obsługi zamówienia, a ich niepodanie skutkuje brakiem przyjęcia zamówienia. Podanie pozostałych danych jest fakultatywne.
                    </div>
                    <div className="text">
                        Dane osobowe są przetwarzane:
                        <ul>
                            <li>w celu realizacji złożonego zamówienia (w tym ewentualnych reklamacji) – podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b) RODO{`)`} ; w zakresie danych podanych fakultatywnie, podstawą prawną przetwarzania jest zgoda (art. 6 ust. 1 lit. a) RODO{`)`} ;</li>
                            <li>
                                w celu realizacji obowiązków ustawowych ciążących na administratorze, wynikających w szczególności z przepisów podatkowych i przepisów o rachunkowości – podstawą prawną przetwarzania jest obowiązek prawny (art. 6 ust. 1 lit. c) RODO{`)`};

                            </li>
                            <li>w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest uzasadniony interes administratora (art. 6 ust. 1 lit. f) RODO{`)`}, polegający na prowadzeniu analiz aktywności użytkowników w serwisie, a także ich preferencji zakupowych, w celu doskonalenia stosowanych funkcjonalności;
                            </li>
                            <li>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO{`)`}, polegający na ochronie naszych praw.
                            </li>
                        </ul>
                    </div>
                    <div className="regulations-bold regulations-subtitle-privacy">MARKETING</div>
                    <div className="text">
                        Twoje dane osobowe przetwarzamy w celu realizowania działań marketingowych, które mogą polegać na:
                        <ul>
                            <li>wyświetlaniu ci treści marketingowych niezależnych od twoich preferencji (w tym reklama standardowa);</li>
                            <li>umożliwieniu bezpłatnego dostępu do serwisów płatnych;</li>
                            <li>kierowaniu e-mailowych powiadomień o interesujących ofertach lub treściach, które w niektórych przypadkach zawierają informacje handlowe (usługa newslettera);</li>
                            <li>prowadzeniu innego rodzaju działań związanych z marketingiem bezpośrednim towarów i usług (przesyłanie informacji handlowych drogą elektroniczną oraz działania telemarketingowe).</li>
                        </ul>
                    </div>
                </div>
            }
            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">Reklama standardowa</div>
                <div className="text">Reklama standardowa jest to reklama niezależna od preferencji użytkownika. W przypadku emisji takiej reklamy dane osobowe są przetwarzane w celach marketingowych w związku z realizacją naszego uzasadnionego interesu (tj. na podstawie art. 6 ust. 1 lit. f) RODO{`)`}.</div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">Reklama behawioralna</div>
                <div className="text">Reklama behawioralna jest to reklama, która jest dopasowana do preferencji użytkownika. Wyświetlenie ci reklamy behawioralnej opiera się m.in. na profilowaniu, czyli wykorzystaniu twoich danych osobowych zebranych za pośrednictwem plików cookies oraz innych podobnych technologii. Profilowanie dla celów marketingowych zarówno przez nas, jak i przez naszych zaufanych partnerów (tu link) ma miejsce pod warunkiem, że wyrazisz zgodę (tj. odbywa się na podstawie art. 6 ust 1 lit a RODO). Zgoda ta jest dobrowolna. Zgoda może być wyrażona także poprzez akt jednoznacznej czynności potwierdzającej wyrażenie zgody wskazany w komunikacie wyświetlonym w procesie zbierania zgód. Zgodę możesz cofnąć w każdym momencie, ale nie ma to wpływu na legalność profilowania w celach marketingowych przed jej cofnięciem.</div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">Newsletter</div>
                <div className="text">Osobom, które w tym celu podały swój adres e-mail, świadczymy usługę newslettera. Podanie danych wskazanych przy zapisaniu się na newsletter (adresu e-mail) jest dobrowolne, ale niezbędne do wysyłki newslettera.</div>

                <div className="text">
                    Dane osobowe są przetwarzane:
                    <ul>
                        <li>w celu świadczenia usługi wysyłki newslettera – podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b) RODO{')'};</li>
                        <li>w przypadku kierowania do użytkownika treści marketingowych w ramach newslettera – podstawą prawną przetwarzania, w tym profilowania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO{')'};</li>
                        <li>w celach analitycznych i statystycznych – podstawą prawną przetwarzania jest nasz uzasadniony interes, (art. 6 ust. 1 lit. f) RODO{')'} polegający na prowadzeniu analiz aktywności użytkowników w serwisie, w celu doskonalenia stosowanych funkcjonalności;</li>
                        <li>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą
                            prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO{')'}.</li>
                    </ul>
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">Marketing bezpośredni</div>
                <div className="text">
                    Ewentualny marketing bezpośredni kierowany za pośrednictwem poczty e-mail wymaga twojej zgody (tj. na podstawie art. 6 ust. 1 pkt a) RODO. Zgodę możesz cofnąć w każdym momencie.
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">PLIKI COOKIES ORAZ PODOBNA TECHNOLOGIA</div>
                <div className="text">
                    Pliki cookies to małe pliki tekstowe instalowane na twoim urządzeniu. Cookies zazwyczaj zawierają nazwę domeny serwisu internetowego, z którego pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny identyfikator. W niniejszej polityce informacje dotyczące cookies mają zastosowanie również do innych podobnych technologii wykorzystywanych w ramach serwisu.
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">Cookies „serwisowe”</div>
                <div className="text">
                    Wykorzystujemy tzw. cookie serwisowe przede wszystkim w celu wyświetlania treści oraz pamiętania zalogowania, poprawy jakości naszych usług. W związku z tym wraz z podmiotami świadczącymi na naszą rzecz usługi analityczne oraz statystyczne korzystamy z plików cookies i technologii LocalStorage, przechowując informacje lub uzyskując dostęp do informacji już przechowywanych w twoim komputerze, telefonie, tablecie itp.{')'}.</div>
                <div className="text">
                    Pliki cookies wykorzystywane w tym celu obejmują:
                    <ul>
                        <li>pliki cookies z danymi wprowadzanymi przez ciebie (ang. user input cookies);</li>
                        <li>uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania na czas trwania sesji (ang. authentication cookies);</li>
                        <li>pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania (ang. user centric security cookies);</li>
                        <li>sesyjne pliki cookies w tym odtwarzaczy multimedialnych (np. pliki cookies odtwarzacza video), na czas trwania sesji (ang. multimedia player session cookies);</li>
                        <li>trwałe pliki cookies służące do personalizacji twojego interfejsu[KM1] (ang. user interface customization cookies),</li>
                        <li>pliki cookies służące do monitorowania ruchu na stronie internetowej, tj. analityki danych, w tym cookies:
                            – Google Analytics (są to pliki wykorzystywane przez spółkę Google – tj. podmiot, któremu Administrator powierzył przetwarzanie danych osobowych – w celu prowadzenia analizy sposobu korzystania z serwisu przez użytkownika, w tym tworzenia statystyk i raportów dotyczących funkcjonowania serwisu).
                        </li>
                    </ul>
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">Cookies „marketingowe”</div>
                <div className="text">
                    My oraz nasi zaufani partnerzy wykorzystujemy również pliki cookies do celów marketingowych. W tym celu my oraz nasi zaufani partnerzy przechowujemy informacje lub uzyskujemy dostęp do informacji już przechowywanych w twoim komputerze, telefonie, tablecie itp.{')'}. Wykorzystanie plików cookies oraz zebranych za ich pośrednictwem danych osobowych w celach marketingowych, wymaga uzyskania twojej zgody. Zgodę możesz cofnąć w każdym momencie, ale nie ma to wpływu na legalność przetwarzania twoich danych w celach marketingowych przed jej cofnięciem.
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">OKRES PRZETWARZANIA DANYCH OSOBOWYCH</div>
                <div className="text">
                    Okres przetwarzania danych zależy od rodzaju świadczonej usługi i celu przetwarzania. Co do zasady dane przetwarzane są przez czas świadczenia usługi lub zrealizowania zamówienia, do czasu wycofania wyrażonej zgody lub zgłoszenia skutecznego sprzeciwu lub skutecznego żądania usunięcia danych.

                    Okres przetwarzania danych może być przedłużony w przypadku, gdy przetwarzanie jest niezbędne do ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami, a po tym okresie, jedynie w przypadku i w zakresie, w jakim będą wymagać tego przepisy prawa.
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">TWOJE PRAWA</div>
                <div className="text">
                    Przysługuje ci prawo: dostępu do treści twoich danych oraz żądania ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych do innego administratora oraz prawo wniesienia sprzeciwu względem przetwarzania danych, a także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych.

                    W zakresie, w jakim twoje dane przetwarzane są na podstawie zgody, można ją wycofać w dowolnym momencie kontaktując się z nami zgodnie z danymi podanymi w serwisie

                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">Prawo do sprzeciwu</div>
                <div className="text">
                    Masz prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania twoich danych:
                    <ul>
                        <li>w celu marketingu bezpośredniego;</li>
                        <li>z przyczyn związanych z twoją szczególną sytuacją w przypadkach, gdy podstawą prawną przetwarzania twoich danych jest nasz uzasadniony interes (np. nasze cele marketingowe, statystyczne, analityczne), tj. gdy twoje dane są przetwarzane na podstawie art. 6 ust. 1 pkt f{')'} RODO;</li>
                    </ul>
                </div>
                <div className="text">
                    Więcej informacji o twoich uprawnieniach wynikających z RODO znajduje się w polityce transparentności, stanowiącej załącznik do niniejszej polityki prywatności.
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">ODBIORCY DANYCH</div>

                <div className="text">
                    W związku z realizacją naszych usług twoje dane osobowe mogą być ujawniane zewnętrznym podmiotom, w tym w szczególności dostawcom odpowiedzialnym za obsługę systemów informatycznych służących do świadczenia usług, podmiotom, takim jak banki i operatorzy płatności, firmy badawcze, podmioty świadczące usługi księgowe, firmy realizujące usługi kurierskie i przesyłkowe agencjom marketingowym (w zakresie usług marketingowych) oraz podmiotom, które są z nami powiązane – w tym spółkom z naszej grupy kapitałowej.
                </div>
                <div className="text">
                    O ile wyrazisz taką zgodę, twoje dane mogą zostać także udostępnione innym podmiotom dla ich własnych celów, w tym marketingowych.            </div>
                <div className="text">
                    Twoje dane mogą zostać ujawnione właściwym organom bądź osobom trzecim, które zgłoszą żądanie udzielenia takich informacji, w oparciu o odpowiednią podstawę prawną, z której wynika obowiązek prawny udzielenia informacji oraz zgodnie z przepisami obowiązującego prawa.
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">PRZEKAZYWANIE DANYCH POZA EUROPEJSKI OBSZAR GOSPODARCZY</div>
                <div className="text">Możliwe jest, że przekażemy twoje dane poza Europejski Obszar Gospodarczy (EOG) – w szczególności administratorom portali społecznościowych. Takie przekazanie nastąpi, o ile zapewniony zostanie odpowiedni stopień ochrony twoich danych tylko gdy zapewniony będzie odpowiedni stopień ochrony twoich danych, który zostanie stwierdzony w szczególności poprzez:
                    <ul>
                        <li>współpracę z podmiotami przetwarzającymi dane osobowe w państwach, w odniesieniu do których została wydana stosowna decyzja Komisji Europejskiej;</li>
                        <li>stosowanie standardowych klauzul umownych wydanych przez Komisję Europejską;</li>
                        <li>stosowanie wiążących reguł korporacyjnych zatwierdzonych przez właściwy organ nadzorczy;</li>
                        <li>w razie przekazywania danych do USA – współpracę z podmiotami uczestniczącymi w programie Tarcza Prywatności (Privacy Shield), zatwierdzonym decyzją Komisji Europejskiej.
                        </li>
                    </ul>
                    Na twoje żądanie udostępnimy ci kopię twoich danych, które zostaną przekazane poza obszar EOG.
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">BEZPIECZEŃSTWO DANYCH OSOBOWYCH</div>
                <div className="text">
                    Na bieżąco prowadzimy analizę ryzyka w celu zapewnienia, że dane osobowe przetwarzane są przez nas niego w sposób bezpieczny, zapewniający przede wszystkim, że dostęp do danych mają jedynie osoby upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze względu na wykonywane przez nie zadania. Dbamy o to, by wszystkie operacje na danych osobowych były rejestrowane i dokonywane jedynie przez uprawnionych pracowników i współpracowników.
                    <br/>
                    Podejmujemy wszelkie niezbędne działania, by także nasi podwykonawcy i inne podmioty współpracujące dawały gwarancję stosowania odpowiednich środków bezpieczeństwa w każdym przypadku, gdy przetwarzają dane osobowe na nasze zlecenie.
                </div>
            </div>

            <div className="regulations-paragraph">
                <div className="regulations-bold regulations-subtitle-privacy">DANE KONTAKTOWE</div>
                <div className="text">
                    E-mail dragopigula@gmail.com, adres: Al. Obrońców Lwowa 12/1, 56-100 Wołów
                </div>
                <div className="text">
                <br/>
                    Wyznaczyliśmy Inspektora Ochrony Danych, z którym można skontaktować się na wskazany powyżej adres e-mail.
                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicy