import { Route, Switch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState} from 'react'
import Header from '../components/Header'
import Content from '../components/home/Content'
import SidebarLeft from '../components/home/SidebarLeft'
import SidebarLeftMobile from '../components/home/SidebarLeftMobile'
import SidebarRight from '../components/home/SidebarRight'
import AccountManagement from './AccountManagement'
import MyContent from './MyContent'
import Recommended from './Recommended'
import ActivePremium from './ActivePremium'
import Premium from './Premium'
import AboutUs from './AboutUs'
import Favorites from './Favorites'
import FaqTutorial from './FaqTutorial'
import AffiliateSettings from './AffiliateSettings'
import Sticky from 'react-sticky-el';
import SidebarLeftDown from "../components/home/SidebarLeftDown";
import NotFound from "./NotFound"
import BurgerMenu from "../components/home/BurgerMenu";

import { CLOSE_MODAL } from '../constants/types'
import Modal from '../components/modals/Modal'
import Footer from "../components/Footer";
import ContactRules from "./ContactRules";

const Home = () => {
    const [showModal, setShowModal] = useState(false)
    const [typeModal, setTypeModal] = useState("")
    const [browserType, setBrowserType] = useState("")

    const closeModal = () => {
        dispatch({type: CLOSE_MODAL})
        setShowModal(false)
    }

    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(()=>{
        dispatch({type: 'SET_SEARCH_VALUE', payload: ""})
        location.pathname === "/" || location.pathname === "/favorites"
            ? dispatch({type: 'SET_VISIBILITY', payload: true})
            : dispatch({type: 'SET_VISIBILITY', payload: false})
    }, [location])

    const { modal, userDetails, loggedIn } = useSelector((state) => state.user)
    const userLog = {
        "customer_id": userDetails.uid
    }
    console.log(JSON.stringify(userLog))

    const customStyleObject = {
        top: 97,
        bottom:20,
    }

    const rightBarStyle = {
        top: loggedIn ? 0 : 97,
        bottom:20,
        minHeight: 500,
    }

    const headerStyle = {
        top: 0
    }
    return (
        <div className={modal ? `page__layout modal-open ${modal}` : 'page__layout'}>

            <SidebarLeftMobile setShowModal={setShowModal} setTypeModal={setTypeModal} setBrowserType={setBrowserType}/>

            <div className="page__mainWpr">


                <aside className="sidebar leftBar">
                    <Sticky stickyStyle={customStyleObject} topOffset={-20}>
                    <SidebarLeft  setShowModal={setShowModal} setTypeModal={setTypeModal}/>
                    </Sticky>
                    <SidebarLeftDown />
                </aside>


                <main className="page__main">
                <Sticky id="sticky" className="sticky-wrapper"
                        stickyClassName={`sticky-topbar sticky-light`}
                        hideOnBoundaryHit={false}
                        stickyStyle={headerStyle}
                    >
                        <header className="page__header header">
                            <Header />
                            <BurgerMenu />
                           
                        </header>
                        </Sticky>
           
                    <Switch>
                        <Route path={['/', '/affiliate/:id']} exact component={Content} />
                        <Route path={'/settings'} component={AccountManagement} />
                        <Route path={'/activepremium'} component={ActivePremium} />
                        <Route path={'/premium'} component={Premium} />
                        <Route path={'/recommended'} component={Recommended} />
                        <Route path={'/mycontent'} component={MyContent} />
                        <Route path={'/affiliate-settings'} component={AffiliateSettings} />
                        <Route path={'/aboutus'} component={AboutUs} />
                        <Route path={'/contact-rules'} component={ContactRules} />
                        <Route path={'/favorites'} component={Favorites} />
                        <Route path={'/faq'} component={FaqTutorial} />
                        <Route component={NotFound} />
                    </Switch>
                </main>

                <aside className="sidebar rightBar">
                    <Sticky stickyStyle={rightBarStyle} topOffset={-60}>
                    <SidebarRight setShowModal={setShowModal} setTypeModal={setTypeModal}/>
                    </Sticky>
                </aside>
            </div>
            {showModal && typeModal && <Modal type={typeModal} closeModal={closeModal} browserType={browserType}/>}
            <Footer/>
        </div>
    )
}

export default Home

