import axios from 'axios'
import { SET_ARTICLES, GET_CREATORS } from '../constants/types'

import { API_URL, setHeaders } from '../api/index'

export const getArticles = (filteredData, articlesType) => async (dispatch) => {
  try {
    const isCreatorsType = articlesType === 'myContent'
    const res = await axios({
      method: 'get',
      url: `${API_URL}/api/v1/news-app${isCreatorsType ? '/creators' : ''}?_format=json`, 
      headers: setHeaders(),
      params: filteredData,
    })

    dispatch({
      type: SET_ARTICLES,
      payload: res.data
    })
  } catch (error) {
    dispatch({type: 'SET_ARTICLES_ONCE_AGAIN', payload: true })
  }
}

export const getCreators = (data) => async (dispatch) => {
  try {
    const res = await axios({
      method: 'get',
      url: `${API_URL}/api/v1/creators-app?_format=json`, 
      headers: setHeaders(),
      params: data
    })
    dispatch({
      type: GET_CREATORS,
      payload: res.data
    })
  } catch (error) {
    console.log(error)
  }
}

export const setVote = (vote) => async (dispatch) => {
  try {
    const res = await axios({
      method: 'patch',
      url: `${API_URL}/api/v1/canonical_news_fivestar?_format=json`, 
      headers: setHeaders(),
      data: vote
    })
    dispatch({
      type: 'SET_VOTE',
      payload: res.data
    })
  } catch (error) {
    console.log(error)
  }
}

export const addToFav = (data) => async (dispatch) => {
  try {
    const res = await axios({
      method: 'patch',
      url: `${API_URL}/api/v1/canonical_user_favourites_news?_format=json`, 
      headers: setHeaders(),
      data: {
        "news_flags": [
          {
            "id": data.id,
            "action" : data.action
          }
        ]
     }
    })
  } catch (error) {
    console.log(error)
  }
}