import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserDetails } from '../../actions/user'
import Dropdown from "../home/Dropdown";
import Loader from "../home/Loader";

const Personalization = () => {

    const { userDetails, settingPreferences, isMobile, isAccountSettingsLoaded } = useSelector((state) => state.user)
    const [isLoaded, setIsLoaded] = useState(false)
    const [preferences, setPreferences] = useState({
        "dark_mode": '',
        "justified_text": '',
        "send_releases_by_email": '',
        "slider_background": '',
        "font": '',
        "font_size": '',
        "display_mode": ''
    })

    const dispatch = useDispatch()
    
    const changePreferences = (e) => {
        const name = e.target.getAttribute("name");
        if (name === 'slider_background') {
            setPreferences({
                ...preferences,
                [name]: e.target.id
            })
        }
        else {
            if (e.target.id === "send_releases_by_email") {
                if (e.target.checked) {
                    setPreferences({
                        ...preferences,
                        [e.target.id]: "0"
                    })
                }
                else {
                    setPreferences({
                        ...preferences,
                        [e.target.id]: "1"
                    })
                }
            }
            else {
                if (e.target.checked) {
                    setPreferences({
                        ...preferences,
                        [e.target.id]: "1"
                    })
                    if(e.target.id === "dark_mode") localStorage.setItem('mode', "1")

                }
                else {
                    setPreferences({
                        ...preferences,
                        [e.target.id]: "0"
                    })
                    if(e.target.id === "dark_mode") localStorage.setItem('mode', "0")
                }
            }
        }
    }

    const changeFont = (e) => {
        if (e.name === 'font_size' || 'font') {
            setPreferences({
                ...preferences,
                [e.name]: e.label
            })
        }
    }

    const changeDisplayMode = (e) => {
        setPreferences({
            ...preferences,
            "display_mode": e.target.id
        })
    }

    useEffect(() => {
        if (isLoaded && userDetails) {
            setPreferences({
                "dark_mode": settingPreferences.dark_mode,
                "justified_text": settingPreferences.justified_text,
                "send_releases_by_email": settingPreferences.send_releases_by_email,
                "slider_background": settingPreferences.slider_background,
                "font": settingPreferences.font,
                "font_size": settingPreferences.font_size,
                "display_mode": settingPreferences.display_mode ? settingPreferences.display_mode : 'classic'
            })
        }
    }, [userDetails, isLoaded])

    useEffect(() => {
        if (isLoaded && isAccountSettingsLoaded) {
            dispatch(setUserDetails(preferences))
        }
    }, [dispatch, preferences])

    useEffect(() => {
        setIsLoaded(true)
    }, [dispatch])


    const optionsFont = [
        { name:"font", value: 'OpenSans', label: 'Open Sans' },
        { name:"font", value: 'Arial', label: 'Arial' },
        { name:"font", value: 'Lato', label: 'Lato' },
        { name:"font", value: 'NotoSerif', label: 'Noto Serif' },
        { name:"font", value: 'Raleway', label: 'Raleway' },
        { name:"font", value: 'Ubuntu', label: 'Ubuntu' },
        { name:"font", value: 'Montserrat', label: 'Montserrat' },
    ]

    const optionsSize = [
        { name:"font_size", value: '12', label: '12' },
        { name:"font_size", value: '14', label: '14' },
        { name:"font_size", value: '15', label: '15' },
        { name:"font_size", value: '16', label: '16' },
        { name:"font_size", value: '18', label: '18' },
    ]

    const textSwitcher =[
        {id:"classic", label:'Klasyczny'},
        {id:"slider", label:'Slajdy'}
    ]

    const sliderBackground =[
        {id:"FFF", name:'slider_background', dataClassName:'white'},
        {id:"F7E8E5", name:'slider_background', dataClassName:'pink'},
        {id:"EAEAEA", name:'slider_background', dataClassName:'grey'},
        {id:"000000", name:'slider_background', dataClassName:'black'},
    ]

   const FontSizeDefaultValue = optionsSize.find(el => JSON.parse(el.value) === (preferences.font_size ? JSON.parse(preferences.font_size) : userDetails.font_size))

   const FontDefaultValue = optionsFont.find(el => el.value === preferences.font)

    return (
        preferences.font_size ?
        <> 
            <div className="switch-wrapper">
                <div className="switcher">
                    <label className="switch">
                        <input
                            id="send_releases_by_email"
                            type="checkbox"
                            checked={preferences.send_releases_by_email === "0" ? true : false}
                            onChange={changePreferences}
                        />
                        <span className="slider round"></span>
                        <span className="switch__description">{preferences.send_releases_by_email === "0" ? "Teraz wysyłka wydań newsletterem jest wyłączona." : "Teraz wysyłka wydań newsletterem jest włączona."}</span>
                    </label>
                </div>

                <div className="switcher">
                    <label className="switch">
                        <input
                            id="dark_mode"
                            type="checkbox"
                            checked={preferences.dark_mode === "1" ? true : false}
                            onChange={changePreferences}
                        />
                        <span className="slider round"></span>
                        <span className="switch__description">Dark mode</span>
                    </label>
                </div>

                <div className="switcher">
                    <label className="switch">
                        <input
                            id="justified_text"
                            type="checkbox"
                            checked={preferences.justified_text === "1" ? true : false}
                            onChange={changePreferences}
                        />
                        <span className="slider round"></span>
                        <span className="switch__description">Justowanie tekstu</span>
                    </label>
                </div>
            </div>

            {isMobile === "1" &&
             <div className="display__mode">
             <div className="display__mode--title">Tryb wyświetlania </div>

                 <div className="text__switcher">
                     {textSwitcher.map((item) => {
                             return <div id={item.id}
                                         className={"display__mode--item " + item.id + (preferences.display_mode === item.id ? " active" : "")}
                                         onClick={changeDisplayMode}>{item.label}
                                    </div>

                     })}
             </div>
         </div>
            }
           

            <div className="content__background">
                <div className="content__background--title">Wybór tła dla wyświetlania newsów w trybie <br/> slajdów pełnoekranowych</div>
                <ul className="content__background--list listReset" onClick={changePreferences}>
                    {sliderBackground.map((item) => {
                        return <div id={item.id}
                                   name={item.name}
                                    data-class={item.dataClassName}
                                    className={"content__background--item content__background--" + item.dataClassName + (preferences.slider_background === item.id ? " content__background--active" : "")}>
                                </div>

                    })}
                </ul>
            </div>

            <div className="content__typo">
                <div className="content__typo--title">Wybór typografii dla wyświetlania newsów w trybie klasycznym</div>

                <div className="content__typo--wrapper">
                    <div className="select-wrapper">
                        <div className="content__type--title" >Krój pisma</div>

                        <Dropdown options ={optionsFont} handleFilter={changeFont} dropdownIndicator={true} defaultValue={FontDefaultValue} width={"100%"} height={48}/>

                    </div>

                    <div className="select-wrapper">
                        <div className="content__type--title">Rozmiar</div>

                         <Dropdown options ={optionsSize} handleFilter={changeFont} dropdownIndicator={true} defaultValue={FontSizeDefaultValue} width={"100%"} height={48}/>
                       

                    </div>
                </div>
            </div>

            <div className="content__example" style={{ fontSize: parseInt(preferences.font_size), fontFamily: preferences.font }}>
                <p>To jest przykładowy news, zmień powyżej krój pisma oraz jego wielkość aby dostosować paragrafy do swoich potrzeb. To jest przykładowy news, zmień powyżej krój pisma oraz jego wielkość aby dostosować paragrafy do swoich potrzeb.</p>
            </div>
        </>
            : <Loader type="settings"/>
    )
}

export default Personalization

