import { useSelector } from 'react-redux'
import ActivePlan from '../components/ActivePlan'
import PlansList from '../components/PlansList'
import { Redirect, Link } from 'react-router-dom'

const ActivePremium = () => {
    const { loggedIn } = useSelector((state) => state.user)
    const { subscription_plan_id } = useSelector((state) => state.user.userDetails)
    const { subscriptionPlans } = useSelector((state) => state.subscriptions)

    if (subscription_plan_id === "1") {
        return <Redirect to='/premium' />;
    }
    if (!loggedIn) {
        return <Redirect to='/' />;
    }
    return (
        subscription_plan_id ? (
            <div className="content">
            <div className="content-inner">

                <div className="acc-bundle">
                    <ActivePlan />
                    <div className="profile__plan--description">
                        <div className="profile__plan--title">Premium</div>
                        <ul className="profile__plan--list listReset">
                        <PlansList plan={subscription_plan_id - 1} subscriptionPlans={subscriptionPlans} classname="profile__plan--item" />
                          
                        </ul>
                    </div>

                    <Link to='/premium' className="profile__managePayments">Zarządzaj płatnościami</Link>

                </div>
            </div>
        </div>
        ): null
        
    )
}

export default ActivePremium