const IosInfoModalOtherBrowser = ({ closeModal }) =>  (
        <>
            <div className="modal__wrapper">
                Niestety przeglądarka, której aktualnie używasz nie obsługuje tego typu operacji.
                <div>By pobrać aplikację <span className="bold">Infopiguła</span> na swój telefon, skorzystaj z przeglądarki Safari.</div>
            </div>
            <button className="modal__close btnReset" onClick={closeModal}>
                <span className="visually-hidden">Close modal window</span>
            </button>
        </>
    )


export default IosInfoModalOtherBrowser