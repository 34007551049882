import { useSelector } from 'react-redux'
import Loader from "./home/Loader";
const ActivePlan = () => {
    const { subscription_plan_id, subscription_plan_name, subscription_end } = useSelector((state) => state.user.userDetails)
    return (
        <div className="profile__plan">
            {subscription_plan_id
                ? <>
                <div className="profile__plan--icon">{subscription_plan_name}</div>
                <div className="profile__plan--wrapper">
                    <div className="profile__plan--name">Posiadasz pakiet {subscription_plan_name}</div>
                    {subscription_plan_id !== "1" && <div className="profile__plan--expire">Wygasa: {subscription_end}</div>}
                </div>
                </> : <Loader type="free"/>}

        </div>
    )
};

export default ActivePlan