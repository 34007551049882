import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {WhatsappShareButton, EmailShareButton, TwitterShareButton, FacebookShareButton, FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon  } from "react-share";
const ShareButton = (props) => {

    const [share, setShare] = useState(false)
    const {loggedIn} = useSelector((state) => state.user)
    const url = window.location.href
    const body = props.body.replace(/<[^>]*>?/gm, '')

    if (share) {
        const slider = document.querySelector('.slick-slider')
        if (slider) slider.addEventListener('click', () => {
            setShare(false)
        })
    }
    return (
        <>
        <div className="menu bottomRight">
            <li className={`filter-icon ${share ? `share ${props.position}` : "share"}`}>
                <div type="radio" onClick={()=>{setShare(!share) }} 
                    className="article__share article__icon"/>
                    {share && 
                        <ul className="submenu" onClick={()=>{setShare(false)}}>
                            <li >
                                <WhatsappShareButton url={url} className="share__buttons" title={body}>
                                    <WhatsappIcon size={30} round />
                                </WhatsappShareButton>
                            </li>
                            <li>
                                <TwitterShareButton url={url} className="share__buttons" title={props.title} >
                                    <TwitterIcon size={30} round />
                                </TwitterShareButton>
                            </li>
                            <li>
                                <EmailShareButton subject={props.title}
                                    body={body} url={url} className="share__buttons" >
                                    <EmailIcon size={30} round />
                                </EmailShareButton>
                            </li>
                            <li>
                                <FacebookShareButton href={url}
                                url={url} className="share__buttons" quote={body} subject={body} ><FacebookIcon size={30} round /></FacebookShareButton>
                            </li>
                        </ul>
                    }
            </li>
            <div className={share? "sharingPanel" : ""} onClick={()=>{setShare(false) }}/>
        </div>
           </>
    )
}

export default ShareButton