import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getArticles, setVote, addToFav, getCreators} from '../../actions/articles'
import {setUserDetails} from '../../actions/user'
import { Link, useHistory } from 'react-router-dom'
import Articles from './Articles'
import InfiniteScroll from 'react-infinite-scroll-component'
import Modal from '../modals/Modal'
import {SHOW_MODAL, CLOSE_MODAL, SET_SEARCH_VALUE} from '../../constants/types'
import Information from './Information'
import Dropdown from './Dropdown'
import ReleasesSelect from './ReleasesSelect'
import Slider from "./Slider"
import Loader from "./Loader";
import FiltersMobile from './FiltersMobile'
import Sticky from 'react-sticky-el';
import { IsFreeAccount } from '../../utils/userDetails'
import { useDeviceDetect } from '../../hooks/useDeviceDetect'

import { useSwipeable } from 'react-swipeable';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { scroll } from '../../utils/userDetails'

const Content = (props) => {
    const dispatch = useDispatch()
    const {articles, searchValue} = useSelector((state) => state.articles)
    const {subscription_plan_name} = useSelector((state) => state.user.userDetails)
    const {display_mode} = useSelector((state) => state.user.settingPreferences)
    const {loggedIn, isMobile, isAccountSettingsLoaded, againCall} = useSelector((state) => state.user)
    const {form_options} = useSelector((state) => state.articles.articles)
    const { creators } = useSelector((state) => state.articles)
    const [options, setOptions] = useState([])
    const [changeReleases, setChangeReleases] = useState(true)
    const [searchValueMobile, setSearchValueMobile] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [typeModal, setTypeModal] = useState("")
    const [loadedData, setLoadedData] = useState(false)
    const [initial, setInitial] = useState(false)
    const [articlesType, setArticlesType] = useState("poland")
    const [hasMore, setHasMore] = useState(true)
    const [loadedArticles, setLoadedArticles] = useState([])
    const [rating, setRating] = useState("")
    const [activeVotingArticle, setActiveVotingArticle] = useState("")
    const [releasePeriod, setReleasePeriod] = useState({
        value: 'last',
        label: 'Ostatnie'
    })
    const [articlesData, setArticlesData] = useState(
        props.fav ? {
            page: 0,
            show_favourites_news: 1,
            show_favourites_creators: 1
        } : {
            group_target_id: 2,
            page: 0,
            keys: "",
            show_last_release: 1,
        })
    const [notice, setNotice] = useState({
        title: "",
        information: "",
    })

    const [ratingValue, setRatingValue] = useState({
        id: "",
        rating: ""
    })
    const [addToReleaseNumbers, setAddToReleaseNumbers] = useState(false)
    const [releaseNumbers, setReleaseNumbers] = useState([])
    const [activeFilter, setActiveFilter] = useState(false)
    const [articlesDataMobile, setArticlesDataMobile] = useState(articlesData)
    const {vote} = useSelector((state) => state.articles)
    const {settingPreferences} = useSelector((state) => state.user)
    const [isCreators, setIsCreaotrs] = useState(creators?.filter(item => item.flagged === '1').length)
    const [favClicked, setFavClicked] = useState(false)
    const [sortType, setSortType] = useState('default')
    const isSliderMode = settingPreferences.display_mode === 'slider'
    const artcilesTypeForSlider = articlesType === 'poland' || articlesType === 'global'

    const newGroups = form_options?.groups?.filter(group => group.target_id > 5)

    const { modal } = useSelector((state) => state.user)
    const deviceDetect = useDeviceDetect()
    const freeAccount = IsFreeAccount()

    useEffect(() => {
        if (loggedIn) {
            dispatch(getCreators())
            setNotice({
                title: "",
                information: "",
            })
        }
    }, [])

    useEffect(() => {
        setIsCreaotrs(creators?.filter(item => item.flagged === '1').length)
    }, [creators])


    useEffect(() => {
        if (changeReleases) {
            setOptions(form_options?.last_releases)
        }
    }, [form_options])

    useEffect(()=> {
        setReleaseNumbers(
            list => {
                const releaseChecked = options?.filter(el => el.checked === true);
                const checked = releaseChecked?.map(el => el.target_id)
                return checked
            }
        ) 
    }, [options])

    useEffect(() => {
        scroll()
    },[articlesData.sort_options, articlesData.show_last_release, articlesData.release_publication_date_ago, articlesData.release_nids])

    useEffect(()=>{
        if(addToReleaseNumbers && (options?.findIndex(el => el.checked === true) !== -1)){
            setArticlesData({
                ...articlesData,
                page: 0,
                show_last_release: "",
                release_publication_date_ago: "",
                release_nids: releaseNumbers.join(',')
            })
            setAddToReleaseNumbers(false)
        }
          
    }, [releaseNumbers])

    const addRelease = (index, e) => {
        setChangeReleases(false)
        setLoadedArticles([])
        setReleasePeriod({
            value: "",
            label: ""
        })
        if (options[index].checked) {
            setAddToReleaseNumbers(true)
            setOptions(list => {
                const index = list.findIndex(el => el.target_id === e.target.id);
                const newList = [...list];
                newList[index] = {
                    ...newList[index],
                    checked: false,
                }
                return newList
            })
        } else {
            setAddToReleaseNumbers(true)
            setOptions(list => {
                const index = list.findIndex(el => el.target_id === e.target.id);
                const newList = [...list];
                newList[index] = {
                    ...newList[index],
                    checked: true,
                }
                return newList
            })
        }
    }

    useEffect(() => {
        if (options?.findIndex(el => el.checked === true) === -1) {
            if (releasePeriod.label === "") {
                setLoadedArticles([])
                setReleasePeriod({
                    value: 'last',
                    label: 'Ostatnie'
                })
                setArticlesData({
                    ...articlesData,
                    page: 0,
                    show_last_release: 1,
                    release_publication_date_ago: "",
                    release_nids: ""
                })
            }
        }

    }, [options])

    const openModal = (e) => {
        setShowModal(true)
        dispatch({type: SHOW_MODAL, payload: e})
    }

    const shareError = () => {
        setTypeModal("unavailableForNotLoggedInModal")
        openModal("modal--change")
    }

    const functionalityNotAvailable = () => {
        if(loggedIn){
            setTypeModal("functionalityNotAvailable")
            openModal("modal--change")
        }
        else {
            setTypeModal("unavailableForNotLoggedInModal")
            openModal("modal--change")
        } 
    }

    const closeModal = () => {
        setShowModal(false)
        dispatch({type: CLOSE_MODAL})
    }

    const [nidModal, setNidModal] = useState("")
    const Multimedia = (e, nid) => {
        if (loggedIn) {
            setNidModal(nid)
            setTypeModal("imageModal")
            openModal("modal--image")
        }
        else {
            setTypeModal("unavailableForFreeModal")
            openModal("modal--change")
        }
    }

    const handleClick = (e) => {
        setNotice({
            title: "",
            information: "",
        })
        window.scrollTo(0,0)
        setChangeReleases(false)
        const filteredGroup = newGroups?.filter(n => n.value === e.target.id)
        const isFilteredGroup = filteredGroup && filteredGroup[0]?.target_id

        if (e.target.id === "live" && subscription_plan_name === "Free") {
            setTypeModal("unavailableLiveModal")
            openModal("modal--change")
            return
        }
        if (isFilteredGroup && subscription_plan_name === "Free") {
            setTypeModal("unavailableFilterForFreeModal")
            openModal("modal--change")
            return
        }
        
        else if ((e.target.id === "myContent" || e.target.id === "live" || isFilteredGroup ) && !loggedIn) {
            setNotice({
                title: "",
                information: "",
            })
            setTypeModal("unavailableForNotLoggedInModal")
            openModal("modal--change")
        } else {

            if (e.target.id === "poland") {
                setArticlesType("poland")

                const testArticlesData = Object.keys(articlesData).filter(key =>
                    key !== 'show_favourites_creators').reduce((obj, key) =>
                    {
                        obj[key] = articlesData[key];
                        return obj;
                    }, {}
                );

                setArticlesData({...testArticlesData, group_target_id: 2, page: 0})
                setArticlesDataMobile({...testArticlesData, group_target_id: 2, page: 0})

            }
            if (e.target.id === "global") {
                setArticlesType("global")
                setArticlesData({...articlesData, group_target_id: 3, page: 0, show_favourites_creators: ""})
                setArticlesDataMobile({...articlesData, group_target_id: 3, page: 0, show_favourites_creators: ""})


            }
            if (e.target.id === "live") {
                setArticlesType("live")
                setArticlesData({...articlesData, group_target_id: 5, page: 0, show_favourites_creators: ""})
                setArticlesDataMobile({...articlesData, group_target_id: 5, page: 0, show_favourites_creators: ""})

            }
            if (e.target.id === "myContent") {
                setArticlesType("myContent")
                setArticlesData({...articlesData, show_favourites_creators: 1, group_target_id: null, page: 0, })
                setArticlesDataMobile({...articlesData, show_favourites_creators: 1, group_target_id: null, page: 0, })

            }

            else {
                const filteredGroup = newGroups?.filter(n => n.value === e.target.id)

                const testArticlesData = Object.keys(articlesData).filter(key =>
                    key !== 'show_favourites_creators').reduce((obj, key) =>
                    {
                        obj[key] = articlesData[key];
                        return obj;
                    }, {}
                );

                if (filteredGroup && filteredGroup[0]?.target_id) {
                    setArticlesType(`${filteredGroup[0].value}`)
                    setArticlesData({ ...testArticlesData, group_target_id: filteredGroup[0].target_id, page: 0})
                    setArticlesDataMobile({ ...testArticlesData, group_target_id: filteredGroup[0].target_id, page: 0})
                }

            }

            setLoadedArticles([])
            setInitial(true)
            setHasMore(true);
        }
    }
    const handleFilter = (e) => {
        setLoadedArticles([])
        if (e.target.id === 'all') {
            setArticlesData({
                ...articlesData,
                page: 0,
                release_publication_date_ago: "",
                show_last_release: "",
                release_nids: ""
            })
        } else if (e.target.id === 'last') {
            setArticlesData({
                ...articlesData,
                page: 0,
                show_last_release: 1,
                release_publication_date_ago: "",
                release_nids: ""
            })
        } else {
            const sortDate = Math.floor(new Date(Date.now() - e.target.id * 24 * 60 * 60 * 1000).getTime() / 1000)
            setArticlesData({
                ...articlesData,
                page: 0,
                show_last_release: "",
                release_publication_date_ago: sortDate,
                release_nids: ""
            })
        }
    }

    const handleSortByRating = (e) => {
        setLoadedArticles([])
        if (e.value === 'byRating') {
            setArticlesData({
                ...articlesData,
                page: 0,
                sort_options: 'rating_results'
            })
        }
        else {
            setArticlesData({
                ...articlesData,
                page: 0,
                sort_options: null
            })

        }
    }
    useEffect(() => {
    window.scrollTo(0,0)
    setHasMore(false);
    setLoadedArticles([])
    setInitial(true)
}, [])

    const [filtersMobile, setFiltersMobile] = useState(false)

    useEffect(() => {
        if (isAccountSettingsLoaded) {
            if (!isSliderMode) return
            if (filtersMobile) {
                dispatch(getArticles(articlesDataMobile, articlesType))
                setLoadedData(true)
                setFiltersMobile(false)
            }
            else{
                dispatch(getArticles(articlesData, articlesType))
                setLoadedData(true)
            }
        }

    }, [dispatch, articlesData.page, articlesDataMobile.page, isAccountSettingsLoaded])

    const [againCallDone, setAgainCallDone] = useState(false)

    useEffect(() => {
        if(!loggedIn) {
            dispatch(getArticles(articlesData, articlesType))
            setLoadedData(true)
        }
        if (isAccountSettingsLoaded) {
            if (isSliderMode && isMobile === '1') return
            dispatch(getArticles(articlesData, articlesType))
            setLoadedData(true)
        }
    }, [dispatch, articlesData, articlesDataMobile, isAccountSettingsLoaded,])


    useEffect(() => {
        if(againCall && !againCallDone) {
            setAgainCallDone(true)
            dispatch(getArticles(articlesData, articlesType))
            setLoadedData(true)
            dispatch({type: 'SET_ARTICLES_ONCE_AGAIN', payload: false })
        }
    }, [againCall])


    useEffect(() => {
        if (loadedData) {
            if (!searchValueMobile) {
                if (searchValue) {
                    setLoadedArticles([])
                    setArticlesData({
                        ...articlesData,
                        page: 0,
                        keys: searchValue
                    })
                } else if (!searchValue && !props.fav) {
                    setLoadedArticles([])
                    setArticlesData({
                        ...articlesData,
                        page: 0,
                        keys: ""
                    })
                }
            }
        }
    }, [searchValue])

    useEffect(() => {
        setNotice({
            title: "",
            information: "",
        })
        if (articles.pager?.total_items === 0 && loadedData) {
            if (searchValue) {
                setNotice({
                    title: "Brak rezultatów",
                    information: "Zmień kryteria wyszukiwania",
                })
            }
            if (!searchValue) {
                if(articlesType === 'myContent'){
                    setNotice({
                        title: "Brak rezultatów",
                        information: "W okresie ostatniego wydania żaden z wybranych Twórców nie stworzył nowej treści."
                    })
                }
                else{
                    setNotice({
                        title: "Brak treści",
                        information: "Nie znaleziono żadnych wiadomości",
                    })
                }
            }
            if (!isCreators) {
                if (articlesType === "myContent") {
                    setNotice({
                        title: "Nie masz wybranych Twórców",
                        information: <Link  className="link-creators" to={'/myContent'}>Kliknij by przejść do zakładki Twórcy i wybrać tych, z którymi będziesz na bieżąco</Link>,
                    })
                }
            }
            if (articlesType === "live") {
                setNotice({
                    title: "Aktualnie brak newsów Live",
                    information: " Live to rzadkie, wyjątkowo ważne info, które warto odczytać na bieżąco. Powiadomienie o nich otrzymasz pushem na swój smartfon",
                })
            }
        }
    }, [articles, showModal])


    useEffect(() => {
        if (initial) {
                if (articles.rows.length !== 0 && loadedArticles.length <= 10 * articlesData.page) {
                    if (loadedArticles.length !== JSON.parse(articles.pager.total_items)) {
                        const articlesRows = articles.rows.map(item => {
                            const newsContent = typeof item.field_news_content === 'string' ? item.field_news_content.replaceAll('<a', '<a target="_blanc"') : ''
                            item['field_news_content'] = newsContent
                            return item
                        })

                setLoadedArticles(prev => prev.concat(articlesRows))
                        setHasMore(true);
                    }
                }
                if (articles.rows.length === 0 && loadedData) setHasMore(false);
            }
    }, [articles.rows])

    const fetchMoreData = () => {
        setArticlesData(prev => ({...prev, page: prev.page + 1}))
    }

    const VotingPanel = (e, active) => {
        setActiveVotingArticle(e.target.id)
        setLoadedArticles(
            list => {
                const index = list.findIndex(el => el.nid === parseInt(e.target.id));
                const newList = [...list];
                newList[index] = {
                    ...newList[index],
                    voteActive: active,
                }
                return newList;
            })
    }

    const showVotingPanel = (e) => {
        setRatingValue({
            id: '',
            rating: ''
        })
        setRating('')
        if (freeAccount){
            setTypeModal("unavailableForFreeModal")
            openModal("modal--change")
        } 
        else if (!loggedIn){
            setTypeModal("unavailableForNotLoggedInModal")
            openModal("modal--change")
        }
        else VotingPanel(e, true)
    }
    const closeVotingPanel = (e) => {
        VotingPanel(e, false)
        setRating("")
    }

    const Voting = (id, rating) => {
        setLoadedArticles(
            list => {
                const index = list.findIndex(el => el.nid === id);
                const newList = [...list];
                newList[index] = {
                    ...newList[index],
                    newRating: rating,
                }
                return newList;
            })
    }

    const closeAfterVote = (id) => {
        setLoadedArticles(
            list => {
                const index = list.findIndex(el => el.nid === parseInt(id));
                const newList = [...list];
                newList[index] = {
                    ...newList[index],
                    voteActive: false,
                }
                return newList;
            })
    }

    const closeAll = () => {
        setLoadedArticles(
            list => {
                const newList = [...list];
                newList.map((item) => item.voteActive = false)
                return newList;
            })
    }
    const articlesVoting = (id, rating) => {
        setRating(rating)
        Voting(id, rating)
        setRatingValue({
            id: id,
            rating: rating
        })
        closeAfterVote(id)
    }
    useEffect(() => {
        if (vote) {
            setLoadedArticles(
                list => {
                    const index = list.findIndex(el => el.nid === ratingValue.id);
                    const newList = [...list];
                    newList[index] = {
                        ...newList[index],
                        newRating: ratingValue.rating,
                        vote_average: vote.vote_average,
                        actual_count: vote.vote_count
                    }
                    return newList;
                })
        }
    }, [vote])

    useEffect(() => {
        if (rating && activeVotingArticle) {
            const vote = {
                "nid": JSON.parse(activeVotingArticle),
                "rating": rating
            }
            dispatch(setVote(vote))
        }
    }, [rating, activeVotingArticle, dispatch])

    const addToFavorite = (id, value) => {
        setFavClicked(true)
        if (props.fav) {
            setLoadedArticles(
                list => {
                    const newList = list.filter(el => el.nid !== id);
                    return newList;
                })
        } else {
            setLoadedArticles(
                list => {
                    const index = list.findIndex(el => el.nid === id);
                    const newList = [...list];
                    newList[index] = {
                        ...newList[index],
                        favourite: value,
                        flagged: value,
                    }
                    return newList;
                })
        }
    }

    const addingToFavorite = (id, value) => {

        if (loggedIn) {
            addToFavorite(id, value)
            const data = {
                id,
                "action": value ? "flag" : "unflag"
            }
            dispatch(addToFav(data))
        } else {
            setTypeModal("unavailableForNotLoggedInModal")
            openModal("modal--change")
        }
    }

    const temporalDistancing = [
        {value: 'last', label: 'Ostatnie'},
        {value: 'all', label: 'Wszystkie'},
        {value: '7', label: 'Ostatni tydzień'},
        {value: '14', label: 'Ostatnie 2 tygodnie'},
        {value: '31', label: 'Ostatni miesiąc'},
    ]

    const optionsSort = [
        {value: 'default', label: 'Domyślne'},
        {value: 'byRating', label: 'Wg wagi'},
    ]

    const changeMobileView = () => {
        if(settingPreferences.display_mode && !disableSliderView){
            setArticlesData({
                ...articlesData,
                page: 0,
                show_last_release: 1,
                release_nids: "",
                release_publication_date_ago: "",
            })
            setArticlesDataMobile({
                ...articlesDataMobile,
                page: 0,
                show_last_release: 1,
                release_nids: "",
                release_publication_date_ago: "",
            })
            setLoadedArticles([])
            if (settingPreferences.display_mode === 'slider') {
                const preferences = {
                    ...settingPreferences,
                    display_mode: 'classic'
                }
                dispatch(setUserDetails(preferences))
            } else {
                const preferences = {
                    ...settingPreferences,
                    display_mode: 'slider'
                }
                dispatch(setUserDetails(preferences))
            }
        }
        else {
            setTypeModal("unavailableForNotLoggedInModal")
            openModal("modal--change")
        }
        
    }

    useEffect(()=>{
        setMobileFilters(false)
    }, [settingPreferences.display_mode])

    const [mobileFilters, setMobileFilters] = useState(false)
    const showMobileFilters = () => {
        setMobileFilters(!mobileFilters)
    }

    useEffect(() => {
        if(favClicked){
            setFavClicked(false)
                if(props.fav && loadedArticles.length === 0){
                setNotice({
                    title: "Usunąłeś wszystkie wiadomości",
                    information: "By dodać wiadmości do ulubionych przejdź na główną stronę",
                })
            }
        }
    }, [favClicked])

    const groups = newGroups?.map(group => (
        <div id={group.value}
            className={`content-country ${articlesType ===  group.value ? "active-country" : ""}`}
            onClick={handleClick}>{group.value}
        </div>
    ))
    const disableSliderView = articlesType === 'live' || articlesType === 'myContent'

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const topbarPosition = width <= 767 ? 46 : 96

    const topbarStyle = {
        top: topbarPosition,
    }

    const changeFiltersAfterSwipe = (articlesType) => {
        window.scrollTo(0,0)

        if(props.fav) return
        setNotice({
            title: "",
            information: "",
        })
        if (articlesType === "live" && subscription_plan_name === "Free") {
            setTypeModal("unavailableLiveModal")
            openModal("modal--change")
            setArticlesType('myContent')
            setArticlesDataMobile({ ...articlesData, show_favourites_creators: 1, group_target_id: null, page: 0, })
            return
        } else if ((articlesType === "myContent" || articlesType === "live") && !loggedIn) {
            setTypeModal("unavailableForNotLoggedInModal")
            openModal("modal--change")
            setArticlesType('global')
            setArticlesDataMobile({ ...articlesData, group_target_id: 3, page: 0, show_favourites_creators: "" })
            return
        } else {
       
            if (articlesType === "poland") {
                setArticlesData({...articlesData, group_target_id: 2, page: 0, show_favourites_creators: ""})
                setArticlesDataMobile({...articlesData, group_target_id: 2, page: 0, show_favourites_creators: ""})

            }
            if (articlesType === "global") {
                setArticlesData({...articlesData, group_target_id: 3, page: 0, show_favourites_creators: ""})
                setArticlesDataMobile({...articlesData, group_target_id: 3, page: 0, show_favourites_creators: ""})
            }
            if (articlesType === "live") {
                setArticlesData({...articlesData, group_target_id: 5, page: 0, show_favourites_creators: ""})
                setArticlesDataMobile({...articlesData, group_target_id: 5, page: 0, show_favourites_creators: ""})
            }
            if (articlesType === "myContent") {
                setArticlesData({...articlesData, show_favourites_creators: 1, group_target_id: null, page: 0, })
                setArticlesDataMobile({...articlesData, show_favourites_creators: 1, group_target_id: null, page: 0, })
            }

            else {
                const filteredGroup = newGroups?.filter(n => n.value === articlesType)

                if (filteredGroup && filteredGroup[0]?.target_id) {
                    setArticlesType(`${filteredGroup[0].value}`)
                    setArticlesData({ ...articlesData, group_target_id: filteredGroup[0].target_id, page: 0, show_favourites_creators: "" })
                    setArticlesDataMobile({ ...articlesData, group_target_id: filteredGroup[0].target_id, page: 0, show_favourites_creators: "" })
                }
            }
        }

            setLoadedArticles([])
            setInitial(true)
            setHasMore(true);
    }

    const newFilters = newGroups ? newGroups.map(item =>item?.value): ""

    const filters = ['poland', 'global', 'myContent', 'live', ...newFilters]


    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if(isMobile === '0' || isSliderMode) return
            const index = filters.indexOf(articlesType)
            if(index === filters.length - 1) return
            const nextFilter = filters[index + 1]
            setArticlesType(nextFilter)
            changeFiltersAfterSwipe(nextFilter)
        },
        onSwipedRight: () => {
            if(isMobile === '0' || isSliderMode) return
            const index = filters.indexOf(articlesType)
            if(index === 0) return
            const prevFilter = filters[index - 1]
            setArticlesType(prevFilter)
            changeFiltersAfterSwipe(prevFilter)
        },
        delta: 170,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
      });


      const isMinPremium = subscription_plan_name === 'Premium' || subscription_plan_name ===  'VIP'
      
      const endMessage = isMinPremium || props.fav || articlesType === 'myContent'

    // TODO: use after changing from infinite scroll to pagination

    // useEffect(() => {
    //     const articles = Object.keys(articlesData).map((key) => [String(key), articlesData[key]]);
    //     let params = ''
    //     articles.forEach((item, index) => {
    //         params += (item[1] === null || item[1] === '') ? '' : `${index ? '&' : '?'}${item[0]}=${item[1]}`
    //     })
    //     history.push({
    //         search: params
    //     })
    // }, [articlesData])

    // const history = useHistory()

    // const location = useLocation();
    // const [params, setParams] = useState('')

    // useEffect(() => {
    //     const queryStr = queryString.parse(location.search)
    //     setParams(queryStr)
    // }, [])

    // useEffect(() => {
    //     if (!params.filter) return
    //     setArticlesType(params.filter)

    //     setArticlesData(params)
    //     setArticlesDataMobile(params)
    // }, [params])

    return (
        <>
            <div className="content" {...handlers}>
                <div className="content-inner" >
                <Sticky stickyStyle={topbarStyle} topOffset={-topbarPosition} id="sticky" className="sticky-wrapper"
                stickyClassName = {`sticky-topbar ${settingPreferences.dark_mode && settingPreferences.dark_mode==="1" ? 'sticky-dark':'sticky-light'}`}
                hideOnBoundaryHit={false}
                >
                    <div className="content-filter main-page">
                        {!props.fav && <>
                            <div className="content-wrapper choose-info">
                                <div id="poland"
                                     className={articlesType === "poland" ? "content-country active-country" : "content-country "}
                                     onClick={handleClick}>Polska
                                </div>
                                <div id="global"
                                     className={articlesType === "global" ? "content-country active-country" : "content-country "}
                                     onClick={handleClick}>Świat
                                </div>
                                {(settingPreferences.display_mode === 'classic' || isMobile === '0' || !loggedIn) &&
                                <>
                                    <div id="myContent"
                                         className={articlesType === "myContent" && loggedIn ? "content-country active-country" : "content-country "}
                                         onClick={handleClick}>Twórcy
                                    </div>
                                    {form_options?.groups[0].is_content && isMinPremium ? (
                                            <div id="live"
                                                className={articlesType === "live" && loggedIn ? "content-country active-country iscontent" : "content-country iscontent"}
                                                onClick={handleClick}>Live
                                            </div>) : 
                                            (
                                            <div id="live"
                                                className={articlesType === "live" && loggedIn ? "content-country active-country" : "content-country "}
                                                onClick={handleClick}>Live
                                            </div>
                                        )
                                    }
                                </>
                                }
                                {isMobile === '0'
                                        ? groups
                                        : !isSliderMode && groups
                                    }
                            </div>

                            <div className="content-wrapper">
                                <ReleasesSelect
                                    opt={options}
                                    setOpt={setOptions}
                                    addRelease={addRelease}
                                    releasePeriod={releasePeriod}
                                    setReleasePeriod={setReleasePeriod}
                                    options={temporalDistancing}
                                    handleFilter={handleFilter}
                                />
                                      <div className="content__sort" onClick={()=>{
                                        if(!loggedIn){
                                            openModal("modal--change")
                                            setTypeModal("unavailableForNotLoggedInModal")
                                        }
                                        if(subscription_plan_name==="Free"){
                                            openModal("modal--change")
                                            setTypeModal("unavailableForFreeModal")                                        }
                                        
                                    }}>
                                    <div className="content__type--title">Sortowanie</div>
                                    <Dropdown type='sorting' options={optionsSort} handleFilter={handleSortByRating}
                                              dropdownIndicator={true} width={144}/>
                                </div>
                                    {(settingPreferences.display_mode === 'classic' || !loggedIn) &&
                                        <div className={"content__sort--mobile " + (activeFilter ? "active" : "")} onClick={showMobileFilters} />}
                                   
                                    {artcilesTypeForSlider &&
                                        <div
                                            className={settingPreferences.display_mode === 'slider' ? "content__sort--fullOpenClose" : "content__sort--fullOpen"}
                                            onClick={changeMobileView}
                                        />
                                    }
                            </div>
                        </>
                        }
                    </div>
                    </Sticky>
                    {isMobile === "1" && mobileFilters ? (
                        <FiltersMobile
                            opt={options}
                            setOpt={setOptions}
                            addRelease={addRelease}
                            setChangeReleases={setChangeReleases}
                            setMobileFilters={setMobileFilters}
                            articlesData={articlesData}
                            setArticlesData={setArticlesData}
                            setLoadedArticles={setLoadedArticles}
                            setSearchValueMobile={setSearchValueMobile}
                            searchValueMobile={searchValueMobile}
                            searchValue={searchValue}
                            options={temporalDistancing}
                            setReleasePeriod={setReleasePeriod}
                            releasePeriod={releasePeriod}
                            setActiveFilter={setActiveFilter}
                            setFiltersMobile={setFiltersMobile}
                            articlesDataMobile={articlesDataMobile}
                            setArticlesDataMobile={setArticlesDataMobile}
                            sortType={sortType}
                            setSortType={setSortType}
                        />
                    ) : (
                        (isMobile === "1" && display_mode === "slider" && !props.fav ? (
                                <Slider type={articlesType}
                                        changeSlide={handleClick}
                                        loadedArticles={loadedArticles}
                                        searchValue={searchValue}
                                        votingPanel={showVotingPanel}
                                        articlesVoting={articlesVoting}
                                        closeVotingPanel={closeVotingPanel}
                                        rating={rating}
                                        setRating={setRating}
                                        shareError={shareError}
                                        addingToFavorite={addingToFavorite}
                                        hasMore={hasMore}
                                        fetchMoreData={fetchMoreData}
                                        functionalityNotAvailable={functionalityNotAvailable}
                                        closeAll={closeAll}
                                />
                            )
                            :
                            (((favClicked ? !props.fav : props.fav) ? loadedArticles.length !== 0: articles && articles.pager?.total_items)  ? (
                                        <InfiniteScroll
                                        style={{ overflow: "visible" }}
                                        dataLength={loadedArticles.length}
                                        scrollableTarget="scrollableDiv"
                                        next={fetchMoreData}
                                        hasMore={hasMore}
                                        scrollThreshold={0.8}
                                        endMessage={ endMessage ? (
                                            <Information
                                                title="Koniec"
                                                information="To już wszystkie wiadomości"
                                            />
                                        ) : (
                                                <div className="read-more">
                                                    <div className="read-moreMsg">Jeżeli chcesz przeglądać dalszą część wydania‚ {loggedIn ? "wybierz plan" : "zaloguj się"}</div>
                                                    {loggedIn ? (
                                                        <Link className="btn btn-readMore" to="/premium">wybierz plan</Link>
                                                    ) :
                                                        (
                                                            <Link className="btn btn-readMore" to="/user/login">Zaloguj się</Link>
                                                        )}
                                                </div>

                                        )

                                        }
                                        >
                                            {loadedArticles.length > 0
                                                ? <Articles
                                                    openMulimedia={Multimedia}
                                                    loadedArticles={loadedArticles}
                                                    searchValue={searchValue}
                                                    searchValueMobile={searchValueMobile}
                                                    votingPanel={showVotingPanel}
                                                    closeVotingPanel={closeVotingPanel}
                                                    rating={rating}
                                                    setRating={setRating}
                                                    articlesVoting={articlesVoting}
                                                    addingToFavorite={addingToFavorite}
                                                    shareError={shareError}
                                                    isFavoritesPage={props.fav}
                                                    articlesType={articlesType}
                                                    functionalityNotAvailable={functionalityNotAvailable}
                                                    typeModal={setTypeModal}
                                                    showModal={setShowModal}
                                                />
                                                : [...Array(10)].map((x, i) =>
                                                    <Loader type="article"/>
                                                )}

                                        </InfiniteScroll>
                                    ) :
                                    notice.title
                                        ?
                                        <Information title={notice.title} information={notice.information}/>
                                        :
                                        <>
                                            {[...Array(10)].map((x, i) =>
                                                <Loader type="article"/>
                                            )}
                                        </>
                            ))

                    )}

                    {/* <div className="read-more">
                    <div className="read-moreMsg">Jeżeli chcesz przeglądać dalszą część wydania‚ wybierz plan (tekst do ustalenia)</div>
                    <button className="btn btn-readMore">Wybierz plan</button>
                </div> */}
                </div>
            </div>
            {showModal && (
                typeModal==="imageModal" ? <Modal type={typeModal} closeModal={closeModal} nid={nidModal} articles={loadedArticles}/> : <Modal type={typeModal} closeModal={closeModal}/>)
            }

            {/* {showModal && typeModal==="imageModal" ? 
            <Modal type={typeModal} closeModal={closeModal}/>
            } */}
        </>
    )
}

export default Content
