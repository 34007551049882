import ModalSwitch from './ModalSwitch'

const Modal = ({ type, closeModal, nid, articles, browserType }) => {
    return (
                <section onClick={closeModal} className="modal" >
                    <div onClick={e => {e.stopPropagation()}} className="modal__inner">
                        <div className="modal__content">
                        <ModalSwitch type={type} closeModal={closeModal} nid={nid} articles={articles} browserType={browserType}/>
                        </div>
                    </div>
                </section>
            )
}

export default Modal