import firefoxInstall from '../../abv/src/images/firefoxInstall.png'
import firefoxOptions from '../../abv/src/images/firefoxOptions.png'
import operaInstall from '../../abv/src/images/operaInstall.png'
import operaOptions from '../../abv/src/images/operaOptions.png'

const AndroidInfoModalFirefoxOpera = ({ closeModal, browserType }) => {

    return (
        <>
            <div className="modal__wrapper">
                <div className="modal__sectionTitle">By pobrać aplikację należy wykonać następujące kroki:</div>
                <ul className="modal__instructionList">
                    <li>1. Przejdź do widoku <span className="bold">Opcje</span></li>
                    <li className="modal__instructionImg"><img src={browserType === "firefox" ? firefoxOptions : operaOptions} alt="x" /></li>
                    <li>2. Wybierz opcję: {browserType === "firefox" ? 'Zainstaluj' : 'Ekran główny'}</li>
                    <li className="modal__instructionImg"><img src={browserType === "firefox" ? firefoxInstall : operaInstall} alt="" /></li>
                    <li>3. Po zatwierdzeniu aplikacja zostanie zainstalowana.</li>
                </ul>
            </div>

            <button className="modal__close btnReset" onClick={closeModal}>
                <span className="visually-hidden">Close modal window</span>
            </button>
        </>
    )
}
export default AndroidInfoModalFirefoxOpera