import RegisterForm from '../components/RegisterForm'
import Header from '../components/Header'
import {useSelector} from 'react-redux'
const Register = () => {
const {modal} = useSelector((state) => state.user)
    return (
            <div className={modal ? `page__layout page--login modal-open ${modal}` : 'page__layout page--login'}>
            <header className="page__header header">
                <Header />
            </header>

            <div className="page__mainWpr">
                <main className="page__main">
                    <RegisterForm />
                </main>

            </div>
        </div>
    )
}

export default Register