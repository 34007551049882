import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom'

const Regulations = () => {

    const {nativeApp} = useSelector(state => state.user)

    return (
        <div className="regulations-wrapper">
            <div className="regulations-title regulations-bold">Regulamin</div>
            <div className="regulations-info regulations-bold">
                {nativeApp
                    ? <div className="regulations-bold">WARUNKI FUNKCJONOWANIA SERWISU</div>
                    : <div className="regulations-bold">WARUNKI SPRZEDAŻY ABONAMENTU I FUNKCJONOWANIA SERWISU</div>
                }
                <Link className="regulations-link regulations-bold" to="/">http://www.infopigula.pl</Link>
            </div>

            <ol className="regulations-bold">
                <li>
                    <div className="regulations-bold">Postanowienia ogólne</div>
                    <div className="text">
                        Za serwis <Link className="regulations-link regulations-bold"
                                        to="/">http://www.infopigula.pl</Link>
                        {!nativeApp && <> i sprzedaż abonamentów dostępowych w ramach serwisu <span
                            className="regulations-bold">Infopiguła </span></>} odpowiedzialny jest Łukasz Wysoczański
                        Vysoko Group z siedzibą w 56-100 Wołów, Al. Obrońców Lwowa 12/1, zwany dalej Wydawnictwem.
                    </div>
                </li>
                <li>
                    <div className="regulations-bold">Definicje</div>
                    <div className="text">
                        Serwis – <Link className="regulations-link regulations-bold"
                                       to="/">http://www.infopigula.pl</Link>, umożliwiający dostęp do elektronicznej
                        treści
                        Wydawnictwa, w tym także wysyłanych drogą mailową pod wskazany adres mailowy.<br/>
                        <span className="regulations-bold">Zamawiający</span> – osoba fizyczna niebędąca konsumentem w
                        rozumieniu art. 22¹
                        Kodeksu cywilnego*, osoba prawna bądź jednostka organizacyjna nieposiadająca
                        osobowości prawnej składająca zamówienie na dostęp do Serwisu.<br/>
                        {!nativeApp && <><span className="regulations-bold">Abonament</span> – pod pojęciem abonamentu
                            należy rozumieć czasowy i płatny lub
                            bezpłatny dostęp do serwisu i/lub czasopisma (w zależności od dokonanego na
                            stronie <Link to="/">www.infopigula.pl</Link> wyboru abonamentu Użytkownika).<br/></>}

                        <span className="regulations-bold">Użytkownik</span> – Zamawiający jako osoba fizyczna lub
                        wskazana przez Zamawiającego
                        osoba fizyczna korzystająca z Serwisu.
                    </div>
                </li>
                <li>
                    <div className="regulations-bold">Składanie zamówień</div>
                    <div className="text">
                        Podstawowym warunkiem realizacji zamówienia jest prawidłowo złożone zamówienie
                        poprzez:
                        <ul>
                            <li>wypełnienie i przesłanie przez Zamawiającego formularza dostępnego na stronie
                                internetowej Wydawnictwa
                            </li>
                        </ul>
                        Składając zamówienie, Zamawiający:
                        <ul>
                            <li>oświadcza, iż zapoznał się z niniejszym regulaminem sprzedaży i akceptuje jego
                                postanowienia,
                            </li>
                        </ul>
                    </div>
                </li>
                {!nativeApp &&
                <>
                    <li>
                        <div className="regulations-bold">Rodzaje abonamentów i zasady</div>
                        <div className="text">
                            <ul>
                                <li>Cena abonamentu zależna jest od wybranego okresu korzystania z usługi i wynosi 9
                                    zł miesięcznie w przypadku planu Premium oraz 12 zł miesięcznie w przypadku planu
                                    VIP. Ceny mogą ulegać obniżeniu przy wykupieniu z góry co najmniej 3 miesięcy
                                    abonamentu wg planu Premium / VIP zgodnie z aktualnie obowiązującą polityką
                                    rabatową serwisu, która jest zmienna. Rabat będzie naliczany po wybraniu określonej
                                    długości dostępu, a w podsumowaniu przed zapłatą widoczna będzie jego wysokość.
                                </li>
                                <li>Infopiguła <strong>nie jest agregatem</strong> mediów informacyjnych. Infopiguła nie
                                    kopiuje <strong>żadnych</strong> treści stworzonych przez redakcje innych mediów informacyjnych. Wszystkie treści
                                    dostępne w serwisie są w całości wynikiem pracy autorskiej redaktora serwisu i są
                                    zastrzeżone.
                                </li>
                                <li>
                                    Infopiguła jest serwisem, który oferuje odpłatny dostęp do całości stworzonych przez
                                    siebie treści jak i funkcji serwisu. Wyjątkiem jest bezpłatny miesiąc próbny
                                    przysługujący użytkownikowi rejestrującemu w serwisie po raz pierwszy swój adres
                                    e-mail.
                                </li>
                                <li>Zamawiający dokonując płatności przede wszystkim nabywa:</li>
                                <ul>
                                    <li>autorski dobór wydarzeń dokonany przez Wydawcę wg kryterium ważności</li>
                                    <li>dostęp do pełnej wersji serwisu, a więc całości treści i wszystkich wydarzeń z kraju i ze świata, z których większość jest niedostępna w wersji bezpłatnej. Pełna wersja obejmuje także niedostępne w wersji bezpłatnej: najważniejsze wydarzenie wydania (wg wydawcy), pozytyw kończący wydanie, wydarzenia "live"  oraz tematyczne działy specjalne </li>
                                    <li>dostęp do wszystkich funkcjonalności i interakcji przewidzianych w serwisie dla planów płatnych. </li>
                                </ul>
                                <li>Pełna lista interakcji i funkcjonalności przypisanych dla danego Planu widnieje w menu serwisu w zakładce Premium, bądź na etapie Rejestracji w serwisie pod aktywnym linkiem o nazwie "Zobacz porównanie planów". </li>
                                <li>Z uwagi na charakter prezentowania przez nas informacji – który polega na bardzo zwięzłej formie – nasz serwis w niektórych przypadkach dołącza wyszukany losowo w internecie artykuł poszerzający kontekst danej informacji, odbywa się to bez kopiowania jakichkolwiek cudzych treści.
                                </li>
                                <li>Użytkownik ma prawo wybrać wersję bezpłatną, za którą nie ponosi żadnych opłat.
                                    Jest ona jednak wersją niepełną, która nie obejmuje całości treści wysyłanych w
                                    ramach
                                    usługi abonamentu płatnego i która nie zapewnia dostępu do szeregu funkcjonalności i
                                    możliwości interakcji w serwisie.
                                </li>
                                <li>
                                    Wydawnictwo zastrzega sobie możliwość zmiany ceny abonamentu w przyszłości
                                    oraz stosowanie cen promocyjnych dla wszystkich bądź wybranych grup Zamawiających.
                                    Ewentualna zmiana ceny zostanie opublikowana w serwisie. Zmiana ceny nie powoduje
                                    różnicy w należności wobec Wydawnictwa dla Użytkowników, którzy zaakceptowali
                                    niniejszy regulamin wraz z wyżej wymienioną ceną.
                                </li>
                                <li>
                                    Dostęp do treści płatnych jest płatny z góry za cały okres ustalonego przez
                                    <span className="regulations-bold"> użytkownika</span> czasu trwania <span
                                    className="regulations-bold">abonamentu.</span>
                                </li>
                                <li>Posiadacz abonamentu w ramach planu Premium chcąc ulepszyć swój abonament na plan VIP powinien poczekać do końca wykupionego okresu w ramach planu Premium. Jeśli tego nie zrobi i w trakcie trwania abonamentu planu Premium wykupi plan VIP, wtedy plan VIP nadpisze posiadany wcześniej plan Premium, a niewykorzystany okres planu Premium przepadnie bezpowrotnie.
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="regulations-bold">Realizacja zamówień (dostęp do serwisu poprzez otrzymywanie
                            abonamentu
                            drogą mailową)
                        </div>
                        <div className="text">
                            <ul>
                                <li>Z chwilą złożenia zamówienia dochodzi do jego realizacji oraz do zawarcia pomiędzy
                                    Wydawnictwem a Zamawiającym umowy o udostępnienie zawartości Serwisu.

                                </li>
                                <li>Termin realizacji zamówienia po odnotowaniu płatności jest natychmiastowy –
                                    Zamawiający od razu zyskuje dostęp do wykupionych treści.

                                </li>
                                <li>
                                    Zamawiający otrzyma na podany przez siebie adres skrzynki e-mail unikalny,
                                    czterocyfrowy kod PIN, po wpisaniu którego może od razu korzystać z zawartości
                                    serwisu według wybranego przez siebie planu.
                                </li>
                                <li>
                                    Zamówione produkty dostarczane są za pośrednictwem serwisu <Link
                                    className="regulations-link" to="/"> www.infopigula.pl </Link>
                                    jak i operatora internetowej skrzynki pocztowej.
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className="regulations-bold">Sposób płatności</div>
                        <div className="text">
                            Po złożeniu zamówienia, Zamawiający ma możliwość wybrania sposobu płatności za
                            zamówiony abonament za pośrednictwem operatora Przelewy24, z którym Wydawnictwo
                            współpracuje. Zamawiający ma dwie możliwości wykupienia abonamentu:
                            <ul>
                                <li>Pierwsza możliwość to cykliczna płatność autoodnawialna. Pobierana będzie raz w
                                    miesiącu, w dniu odpowiadającym dniowi zamówienia. Pierwszy miesiąc
                                    abonementu jest bezpłatny, natomiast pierwsza płatność z karty zostanie pobrana
                                    miesiąc później. Zamawiający każdorazowo otrzyma potwierdzenie pobrania
                                    płatności na swoją skrzynkę mailową.
                                </li>
                                <li>Druga możliwość to wykupienie abonamentu z góry opłacając wybrany przez
                                    Zamawiającego okres dostępu do pełnej treści wynikającej z planu Premium lub
                                    VIP. Dostępne do wyboru okresy to 1 miesiąc, 3 miesiące, 4 miesiące, 6 miesięcy i
                                    12 miesięcy.
                                </li>

                            </ul>
                        </div>
                    </li>
                </>}
                <li>
                    <div className="regulations-bold">Korespondencja i powiadomienia pocztą elektroniczną</div>
                    <div className="text">
                        <ul>
                            <li>Wszelka korespondencja elektroniczna kierowana jest na adres podany przez
                                Zamawiającego w formularzu zamówienia.
                            </li>
                            <li>Powiadomienia wysyłane drogą elektroniczną przygotowywane są przez
                                Wydawnictwo z poszanowaniem wszelkich standardów przyjętych w komunikacji
                                internetowej: nie zawierają zarówno zainfekowanych wirusami komputerowymi
                                załączników, jak i linków do stron internetowych zawierających wirusy lub
                                wyłudzających dane.
                            </li>
                            <li>
                                Wydawnictwo nie ponosi odpowiedzialności za niedostarczone powiadomienia na
                                adres e-mail Zamawiającego, jeżeli oprogramowanie pocztowe lub serwer poczty
                                Zamawiającego zablokuje odbieranie wiadomości z serwera, z którego Wydawnictwo
                                wysyła powiadomienia do Zamawiającego.

                            </li>

                        </ul>
                    </div>
                </li>
                <li>
                    <div className="regulations-bold">Wymagania techniczne</div>
                    <div className="text">
                        Do korzystania z serwisu wymagane jest posiadanie przez Użytkownika:
                        <ul>
                            <li>dostępu do Internetu,
                            </li>
                            <li>indywidualnego konta poczty elektronicznej e-mail,
                            </li>
                            <li>dowolna aktualna przeglądarka internetowa (IE 7 i nowsze, Firefox 3.x i nowsze,
                                Safari 4.x i nowsze, Google Chrome)
                            </li>

                        </ul>
                    </div>
                </li>
                <li>
                    <div className="regulations-bold">Licencja</div>
                    <div className="text">
                        Uzyskanie dostępu do serwisu w ramach konta użytkownika oznacza udzielenie przez
                        Wydawnictwo (Licencjodawca) Zamawiającemu (Licencjobiorca) niewyłącznej ograniczonej
                        czasowo licencji bez prawa udzielania sublicencji, na korzystanie z informacji zawartych w
                        serwisie wyłącznie dla własnych potrzeb Zamawiającego, zgodnie z zasadami i warunkami
                        określonymi w przedmiotowym Regulaminie, za pośrednictwem sieci Internet.
                        Licencja udzielona zostaje na czas określony, odpowiadający okresowi dostępu do serwisu.
                        Licencja nie uprawnia do:
                        <ul>
                            <li>rozpowszechniania informacji pobranych z serwisu, w tym w szczególności na
                                zasadach użyczenia, najmu, dzierżawy lub sprzedaży,
                            </li>
                            <li>udostępniania dostępu do serwisu osobom trzecim, w tym do udostępniania hasła
                                osobom trzecim,
                            </li>
                            <li>utrwalania i zwielokrotniania danych pobranych z serwisu, za wyjątkiem
                                sporządzania wydruków wyłącznie na własne potrzeby,
                            </li>
                            <li>
                                przeniesienia praw wynikających z niniejszej umowy na osobę trzecią.
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="regulations-bold">Dane osobowe</div>
                    <div className="text">
                        <ol>
                            <li>Podanie przez Zamawiającego jego danych osobowych na potrzeby związane z
                                korzystaniem z serwisu, jest równoznaczne z dobrowolnym wyrażeniem zgody na
                                przetwarzanie (w tym umieszczenie) podanych danych osobowych Zamawiającego w
                                bazie danych Wydawnictwa.
                            </li>
                            <li>
                                Administratorem zbioru danych osobowych jest Wydawnictwo.
                            </li>
                            <li>
                                Udostępnione dane osobowe, o których mowa w ust. 1, przetwarzane będą
                                wyłącznie dla celów realizacji usługi dostępu do serwisu oraz dla celów
                                marketingowych Wydawnictwa
                            </li>
                            <li>
                                Na zasadach określonych przez powszechnie obowiązujące przepisy prawa
                                Zamawiający ma prawo żądania uzupełnienia, uaktualnienia, sprostowania danych
                                osobowych, czasowego lub stałego wstrzymania ich przetwarzania lub ich usunięcia,
                                jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane z
                                naruszeniem prawa albo są już zbędne do realizacji celu, dla którego zostały zebrane,
                                a także ma prawo wniesienia pisemnego żądania zaprzestania przetwarzania jego
                                danych osobowych.
                            </li>
                        </ol>
                    </div>
                </li>
                {!nativeApp &&
                <li>
                    <div className="regulations-bold">Reklamacje, rezygnacje i noty korygujące</div>
                    <div className="text">
                        <ol>
                            <li>Reklamacje należy składać drogą mailową na adres: <a
                                href="mailto:newsy@infopigula.pl">newsy@infopigula.pl</a> lub <a
                                href="mailto:dragopigula@gmail.com">dragopigula@gmail.com</a></li>
                            <li>
                                Termin rozpatrzenia reklamacji wynosi 7 dni roboczych.
                            </li>
                            <li>
                                Zamawiającemu nie przysługuje prawo anulowania abonamentu wykupionego na
                                okres wykupiony z góry. Niekorzystanie z serwisu nie jest równoznaczne z rezygnacją
                                ani nie jest podstawą do anulowania zamówienia. Zamawiający, który opłaca
                                abonament płatnością cykliczną - autoodnawialną ma możliwość anulowania
                                najbliższej nadchodzącej płatności w każdej chwili. Aby tego dokonać wystarczy wejść
                                na podstronę serwisu <Link className="regulations-link"
                                                           to="/"> www.infopigula.pl</Link> o nazwie "Zarządzanie
                                kontem". Tam należy
                                wybrać zakładkę "Subskrybcja", następnie kliknąć aktywny link o nazwie "Anuluj
                                płatność autoodnawialną". Kliknięcie linka oznaczać będzie rezygnację z opłacania
                                kolejnych okresów rozliczeniowych abonamentu. Dostęp do treści wg opłaconego
                                planu wygaśnie z chwilą zakończenia wykupionego okresu rozliczeniowego, czyli w
                                dniu w którym nie dojdzie do skutku anulowana płatność cykliczna.
                            </li>
                            <li>
                                Zgodnie z rozporządzeniem Ministra Finansów z dnia 28 marca 2011 w sprawie
                                zwrotu podatku niektórym podatnikom, wystawiania faktur, sposobu ich
                                przechowywania oraz listy towarów i usług, do których nie mają zastosowania
                                zwolnienia od podatku od towarów i usług (Dz. U. z 2011r. Nr 68, poz.360), pomyłki
                                w dokumentach sprzedaży dotyczące danych płatnika (nazwa, adres, nr NIP)
                                korygujemy, akceptując noty korygujące wystawiane przez Klienta.
                            </li>
                            <li>
                                W sprawach nieuregulowanych w niniejszym regulaminie będą miały
                                zastosowanie przepisy Kodeksu cywilnego, ustawy o prawie autorskim i prawach
                                pokrewnych oraz ustawy o świadczeniu usług drogą elektroniczną.
                            </li>
                            <li>
                                Niniejszy regulamin dostępny jest na stronach internetowych:
                                <Link className="regulations-link" to="/"> http://www.infopigula.pl</Link>. Złożenie
                                zamówienia dostępu do serwisu jest
                                równoznaczne z akceptacją treści regulaminu.
                            </li>
                            <li>
                                W przypadku jakichkolwiek wątpliwości związanych z regulaminem sprzedaży,
                                prosimy o kontakt: <span
                                className="regulations-bold regulations-mail">newsy@infopigula.pl</span><br/>
                                *{')'} Art. 221 KC - Za konsumenta uważa się osobę fizyczną dokonującą z przedsiębiorcą
                                czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub
                                zawodową.
                            </li>
                        </ol>
                    </div>
                </li>
                }
            </ol>
            {!nativeApp && <>Niniejszy regulamin sprzedaży nie znajduje zastosowania do Konsumentów.</>}
            <div className="regulations-contact regulations-bold">
                <br/>
                Kontakt:
                <ul className="regulations-contact--list">
                    <li><a href="mailto:newsy@infopigula.pl">newsy@infopigula.pl</a></li>
                    <li>VYSOKO Group Łukasz Wysoczański</li>
                    <li>Al. Obrońców Lwowa 12/1, 56-100 Wołów</li>
                </ul>
            </div>
        </div>
    )
}

export default Regulations;