import axios from 'axios'
import {API_URL, setHeaders} from '../api/index'
import {SET_SUBSCRIPTIONS_PLANS, CREATE_SUBSCRIPTIONS_PLAN, DISCOUNT_CODE, DISCOUNT_CODE_FAILED} from '../constants/types'

export const getSubscriptionPlans = () => async (dispatch) => {
    try {
      const res = await axios({
        method: 'get',
        url: `${API_URL}/api/subscription_plans?_format=json `,
      })
      dispatch({
        type: SET_SUBSCRIPTIONS_PLANS,
        payload: res.data[0].data
      })
    } catch (error) {
      console.log(error)
    }
  }

  export const createSubscriptionPayment = (data) => async (dispatch) => {
    try {
      const res = await axios({
        method: 'post',
        url: `${API_URL}/api/v1/create_subscription_payment_once_p24 `,
        headers: setHeaders(),
        data: data
      })
      dispatch({
        type: CREATE_SUBSCRIPTIONS_PLAN,
        payload: res.data
      })
    } catch (error) {
      console.log(error)
    }
  }