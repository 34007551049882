const VoteRating = (props) => {
    return (
        <ul >
            {[...Array(5)].map((item, index) => {
                const ratingValue = 5 - index;
                return (
                    <li key={index} value={ratingValue} onClick={() => props.articlesVoting(props.id, ratingValue)} className={(props.newRating ? props.newRating : props.actualRating) >= ratingValue ? "rate__weight__star rate__weight__star-clicked" : "rate__weight__star"}>
                    </li>
                )
            })}
        </ul>
    )
}

export default VoteRating