import { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createAffiliateCampaign, getAffiliateCampaign, getAllAffiliateCampaigns, getAllAffiliateClients } from '../actions/user'
import validationForm from '../validation/validationForm'
import SiteNotice from '../components/SiteNotice'
import { CREATE_CAMPAIGN_RESET } from '../constants/types'
import { isRecommendedRole } from '../utils/userDetails'

const AffiliateSettings = () => {

    const [formData, setCampaignTitle] = useState({
        campaignTitle: ""
    })
    const [errors, setErrors] = useState({})
    const [correctData, setCorrectData] = useState(false)


    const inputTypes = {
        campaignTitle: true, 
    }

    const dispatch = useDispatch()

    const { createCampaignSuccessful, siteMessage, allCampaigns, affiliateClients, userRole } = useSelector(state => state.user)

    const changeTitle = (e) => {
        setCampaignTitle({
            campaignTitle: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors(validationForm(false, formData, true, inputTypes))
        setCorrectData(true)
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && correctData ) {
            dispatch(createAffiliateCampaign(formData))
        }
    }, [errors, correctData])

    useEffect(() => {
        setErrors({
            campaignTitle: ''
        })
        if(siteMessage){
            dispatch({type: CREATE_CAMPAIGN_RESET})
        }
    }, [formData])

    useEffect(() => {
        dispatch(getAllAffiliateCampaigns())
    }, [])

    useEffect(()=> {
        if(!createCampaignSuccessful) return
        dispatch(getAllAffiliateCampaigns())
    }, [createCampaignSuccessful])
  
    const [campaigns, setCampaigns] = useState(undefined)

    useEffect(() => {
        setCampaigns(allCampaigns)
    }, [allCampaigns])

    const originUrl = window.location.origin
    const campaignsRender = campaigns?.map((item, index) => {
        return (
            <tr className='affiliate-settings__row'>
                <th className="affiliate-settings__item">{index + 1}</th>
                <th className="affiliate-settings__item">{item.title}</th>
                <th className="affiliate-settings__item">{item.short_tag}</th>
                <th className="affiliate-settings__item">
                    {`${originUrl}/#/affiliate/${item.short_tag}`}
                </th>
            </tr>
        )
    })

    // useEffect(() => {
    //     if(!campaigns) return
    //     campaigns.map(campaign => {
    //         dispatch(getAllAffiliateClients(campaign.id))
    //     })
    // }, [campaigns])

    // const [clients, setClients] = useState([])

    // useEffect(() => {
    //     if(!affiliateClients) return
    //     setClients(oldArray => [...oldArray, affiliateClients])
    // }, [affiliateClients])

    const recommendedRole = isRecommendedRole(userRole)
    
    if (!recommendedRole) {
        return <Redirect to='/' />;
    }
    return (
        campaigns !== undefined &&
        <div className="content">
            <div className="content-inner">
                <div className="affiliate-settings">
                    <h4 className="block-title">Ustawienia afiliacji</h4>
                    <div className="affiliate-settings__description" > Stwórz kampanię afiliacyjną, następnie wygeneruj link i przekaż go innym. </div>

                        {campaigns.length ?
                            <>
                                <p className='block-title'>Twoje kampanie</p>
                                <table className='affiliate-settings__table'>
                                    <tr className='affiliate-settings__row affiliate-settings__row--top'>
                                        <th className="affiliate-settings__item affiliate-settings__item--topRow">Lp.</th>
                                        <th className="affiliate-settings__item affiliate-settings__item--topRow">Nazwa</th>
                                        <th className="affiliate-settings__item affiliate-settings__item--topRow">Kod</th>
                                        <th className="affiliate-settings__item affiliate-settings__item--topRow">Link</th>
                                    </tr>
                                    {campaignsRender}
                                </table>
                                <div className="affiliate-settings__description" ></div>
                            </>
                            :
                            <>
                                <p className='block-title'>Nie posiadasz żadnych kampanii.</p>
                                <div className="affiliate-settings__description" >Utwórz kampanię korzystając z formularza poniżej.</div>
                            </>
                        }   
                    <form className="affiliate-settings-form" onSubmit={handleSubmit}>
                        <p className='block-title'>Utwórz kampanię</p>
                        <div className={errors.campaignTitle ? "form__item input-error": "form__item"}>
                            <div className="description">Nazwa kampanii</div>
                            <label className="form__label visually-hidden">Nazwa kampanii</label>
                            <input className="form-text form__field" name="affiliate-title" value={formData.campaignTitle} onChange={changeTitle}/>
                        </div>

                        {errors.campaignTitle
                            &&
                            <div className="form__item">
                                <p className="form-error">
                                    {errors.campaignTitle}
                                </p>
                            </div>
                        }

                        <div className="form-wrapper">
                            <input className="button form-submit" type="submit" value="Utwórz kampanię" />
                        </div>
                    </form>
                    {createCampaignSuccessful && <SiteNotice />}
                </div>
            </div>
        </div>
    )
}

export default AffiliateSettings