import { Link } from "react-router-dom"
const UnavailableForNotLoggedInModal = ({ closeModal }) => {
    return (
        <>
            <div className="modal__sectionTitle">Ta opcja dostępna jest dla zalogowanych użytkowników</div>
            <div className="modal__wrapper">
                <Link to="/user/login" className="modal__confirmAction btn form-submit" onClick={closeModal}>Zaloguj się</Link>
            </div>
            <div className="modal__register">Nie masz jeszcze konta? <Link onClick={closeModal} to="/user/register">Zarejestruj się</Link></div>
            <button className="modal__close btnReset" onClick={closeModal}/>
        </>
    )
}

export default UnavailableForNotLoggedInModal