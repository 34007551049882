import { Link, Redirect } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/user'
import SiteNotice from './SiteNotice'
import validationForm from '../validation/validationForm'
import {LOGIN_FAILED_RESET} from '../constants/types'

const LoginForm = () => {
    const dispatch = useDispatch()
    const [passVisibility, setPassVisibility] = useState(false)
    const [errors, setErrors] = useState({})
    const [correctData, setCorrectData] = useState(false)
    const { loggedIn, loginFailed } = useSelector((state) => state.user)
    const [formData, setFormData] = useState({
        email: "",
        pass: ""
    })
    const inputTypes = {
        email: true, 
        pass: true
    }
    //TODO - custom hook 
    const togglePassVisibility = () => {
        setPassVisibility(!passVisibility)
    }
    const changeFormData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors(validationForm(false, formData, true, inputTypes))
        setCorrectData(true)
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && correctData ) {
            const data = {
                name: formData.email,
                pass: formData.pass
            }
            dispatch(login(data))
        }
    }, [errors, correctData])

    useEffect(()=>{
        if(!loggedIn){
            dispatch({type: LOGIN_FAILED_RESET})
        }
    }, [formData, loggedIn, dispatch])

    useEffect(()=>{
        if(loginFailed){
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'auto'
            });
        }
        else{
            window.scrollTo(0,0);
        }
    }, [loginFailed])

    if (loggedIn) {
        return <Redirect to='/' />;
    }
    return (
        <form className="user-login-form" onSubmit={handleSubmit}>
              <div className={loginFailed ? "login-content--alert" : "login-content"}>
            <h2 className="block-title">Logowanie</h2>
            <div className={errors.email ? "form__item input-error": "form__item"}>
                <div className="description">Adres e-mail</div>
                <label className="form__label visually-hidden">Adres e-mail</label>
                <input className="form-text form__field" name="email" value={formData.email} onChange={changeFormData} />
            </div>

                {errors.email
                &&
                <div className="form__item">
                    <p className="form-error">{errors.email}</p>
                </div>}

            <div className={errors.pass ? "form__item input-error": "form__item"}>
                <label className="form__label visually-hidden">Hasło</label>
                <input className="form-text form__field" name="pass" placeholder="Hasło" type={passVisibility? "text": "password"} value={formData.pass} onChange={changeFormData} />
                <span className={passVisibility ? "form__field-icon form__field-icon--hide" : "form__field-icon form__field-icon--show"} onClick={togglePassVisibility}></span>
            </div>

                {errors.pass
                &&
                <div className="form__item">
                    <p className="form-error">{errors.pass}</p>
                </div>}

            <div className="form-wrapper">
                <input className="button form-submit" type="submit" value="Zaloguj się" />
                <Link to="/user/password">Nie pamiętasz hasła?</Link>
            </div>

            <div className="register-wrapper">
                <p>Nie masz jeszcze konta? <Link to="/user/register">Zarejestruj się</Link></p>
            </div>
            </div>
            {loginFailed ? <SiteNotice message={"Błędny login lub hasło"} type={"Error message"}/> : null}

        </form>
    )
}

export default LoginForm