import { useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { extendSubscription } from '../../actions/user'
import { useSelector } from 'react-redux'
import Dropdown from "../home/Dropdown"
import { useEffect } from 'react'
import { createSubscriptionPayment } from '../../actions/subscriptions'
import { activationDiscount } from '../../actions/user'
import { getUserDetails } from '../../actions/user'
import SiteNotice from '../SiteNotice'
import { DISCOUNT_CODE_FAILED_RESET } from '../../constants/types'
import { isSafari } from 'react-device-detect';

const SubscriptionExtension = () => {
    const dispatch = useDispatch()
    const { selectedPlan, subscriptionPlans, discountCode, newSubscription, discountCodeFailed } = useSelector((state) => state.subscriptions)
    const { subscription_plan_name, user_email } = useSelector((state) => state.user.userDetails)
    const { siteMessage } = useSelector((state) => state.user)
    const [duration, setDuration] = useState(1)
    const [sum, setSum] = useState(duration * subscriptionPlans[selectedPlan - 1]?.amount / 100)
    const [withoutDiscount, setWithoutDiscount] = useState("")
    const [discount, setDiscount] = useState(true)
    const [createSubscriptionPlan, setCreateSubscriptionPlan] = useState({
        "subscription_duration": "+1month",
        "subscription_plan_id": selectedPlan,
        "amount": subscriptionPlans[selectedPlan - 1]?.amount,
        "url_return": window.location.href.replace('settings/subscription/extension', 'payment-confirm'),
        "discount_code": ""
    })
    const [code, setCode] = useState("")
    const [discountCodeValue, setDiscountCodeValue] = useState(0)
    const [monthlyDiscountValue, setMonthlyDiscountValue] = useState(0)
    const [discountSum, setDiscountSum] = useState(0)
    const [price, setPrice] = useState(0)
    const [paymentMade, setPaymentMade] = useState(false)

    const history = useHistory();

    useEffect(() =>{
        if(newSubscription.data?.subscription_added && paymentMade){history.push("/payment-confirm");
            
            dispatch(getUserDetails())
            dispatch({type: 'DISCOUNT_CODE', payload: ""})
        }
        if (newSubscription.data?.redirect_url && paymentMade) {
            if (isSafari) window.location.assign(newSubscription.data.redirect_url)
            else {
                const a = document.createElement("a");
                a.setAttribute('href', newSubscription.data.redirect_url);
                a.setAttribute('target', '_blank');
                a.click();
            }
        }
    }, [newSubscription])

    useEffect(()=>{
        setCode("")
        dispatch({type: 'DISCOUNT_CODE', payload: ""})
    }, [selectedPlan])

    useEffect(()=>{
        if(discountCode.discount_amount){
            setDiscountCodeValue(discountCode.discount_amount / 100)
            setCreateSubscriptionPlan({
                ...createSubscriptionPlan,
                "discount_code": code
            })
        }
    }, [discountCode])

    useEffect(()=>{
        setDiscountSum(monthlyDiscountValue + discountCodeValue)
    }, [monthlyDiscountValue, discountCodeValue])

    useEffect(()=>{
        setCreateSubscriptionPlan({
            ...createSubscriptionPlan,
            "amount": sum * 100,
            "subscription_duration": `+${duration}month`
        })
    }, [sum])

    useEffect(()=>{
        if(discount){
            setCreateSubscriptionPlan({
                ...createSubscriptionPlan,
                "is_discount_matrix": "1"
            })
        }
        else {
            setCreateSubscriptionPlan({
                ...createSubscriptionPlan,
                "is_discount_matrix": "0",
                "discount_code": ""
            })
        }
        
    }, [discount])

    const handleSubmit = (e) => {
        e.preventDefault()
        setPaymentMade(true)
        dispatch(createSubscriptionPayment(createSubscriptionPlan))
    }

    const handleOptionChange = (e) => {
        setDuration(e.value)
    }

    const options = ['1', '3', '6', '12'].map((item) => {
        const disc = (subscriptionPlans[selectedPlan - 1]?.discount_matrix[item] / 100 / (item * subscriptionPlans[selectedPlan - 1]?.amount / 100) * 100).toFixed(0)
        return ({
            value: item,
            label: item === '1' ? `${item} miesiąc` : <div>{item} {item === '3' ? 'miesiące' : 'miesięcy'} {disc > 0 && <>- <span className="discount-value"> rabat {disc}%</span></>}</div>
        })
    })

    const changeCode = (e) =>{
        setCode(e.target.value)
    }
    const handleDiscount = () => {
        setDiscount(!discount)
    }

    useEffect(() =>{
        if(siteMessage){
            dispatch({type: DISCOUNT_CODE_FAILED_RESET})
        }
    }, [code])

    useEffect(() => {
        if (selectedPlan) {
            const price = duration * subscriptionPlans[selectedPlan - 1]?.amount / 100
            const discountValue = subscriptionPlans[selectedPlan - 1]?.discount_matrix[duration] / 100
            setPrice(price)
            setMonthlyDiscountValue(discountValue)
            setWithoutDiscount("")

            if(discountValue){
                setWithoutDiscount(price)
            }
            if(!discount) {
                setSum(price)
            }
        }
    }, [selectedPlan, duration, discount])

    useEffect(() =>{
        const price = duration * subscriptionPlans[selectedPlan - 1]?.amount / 100
        if (discount && discountSum) {
            if(price < discountSum)setSum(0)
            else setSum(price - discountSum)
        }
        else if(discount){
            setSum(price)
        }

    }, [discountSum, discount, duration])

    useEffect(()=>{
        if(discountCodeFailed){
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'auto'
            });
        }
        else{
            window.scrollTo(0,0);
        }
    }, [discountCodeFailed])

    const handleDiscountActive = () => {
        if(code){
            const data= {
                "discount_code": `${code}`,
                "email": `${user_email}`
            }
            dispatch(activationDiscount(data))
        }
    }

    const handleDiscountCode = () => {
        setDiscountCodeValue(0)
        dispatch({type: 'DISCOUNT_CODE', payload: ""})
    }
    if (!selectedPlan) {
        return <Redirect to='/premium' />;
    }

    return (
        <>
        <form className="subscription-extension" onSubmit={handleSubmit}>
            <Link onClick={handleDiscountCode} to="/settings/subscription" className="form__step-back prev-arrow">Powrót</Link>
            <div className="profile__plan setting-subscription">
                <div className="profile__plan--icon">{subscription_plan_name}</div>
                <div className="profile__plan--wrapper">
                    <div className="profile__plan--name">Posiadasz pakiet {subscription_plan_name}</div>
                </div>
            </div>

            <div className="form__item promo-code">
                <div className="description">Wpisz kod promocyjny</div>
                <label className="form__label visually-hidden">Wpisz kod promocyjny</label>
                <input type="text" className="form-text form__field" name="promo-code" value={code} onChange={changeCode}/>
                <button className="btn-renewal" type="button" onClick={handleDiscountActive}>Aktywuj kod rabatowy</button>
            </div>

            <div className="payment">
                <div className="block-title">Chcę opłacić wybrany okres z góry</div>
                <div className="select-wrapper">
                    <div className="content__type--title" >Płatność za okres</div>

                    <Dropdown handleFilter={handleOptionChange} options={options} width={"100%"} height={48} dropdownIndicator={true} />

                </div>
            </div>
            <div className="switcher">
                <label className="switch">
                    <input type="checkbox" checked={discount ? false : true} onChange={handleDiscount} />
                    <span className="slider round"></span>
                </label>
                <div className="switch__description">Wyłącz rabat jeśli chcesz nas wesprzeć bardziej</div>
            </div>

            <div className="payment__summary">
                <div className="payment__summary--price payment-disp">
                    <div className="summary-title">Plan {selectedPlan === 3 ? "VIP" : "Premium"}</div>
                    <div className="plan-price">{selectedPlan ? subscriptionPlans[selectedPlan - 1]?.amount / 100 : subscriptionPlans[1]?.amount / 100} zł</div>
                </div>

                <div className="payment__summary--period payment-disp">
                    <div className="summary-title">Okres</div>
                    <div className="period">{duration} msc</div>
                </div>

                <div className="payment__summary--discount payment-disp">
                    <div className="summary-title">Rabat {discount && discountCodeValue ? <span className="discount-value"> + kod promocyjny</span>: ""}</div>
                    <div className="discount-amount">{discount ? discountSum : 0} zł</div>
                    
                </div>

                <div className="payment__summary--sum payment-disp">
                    <div className="summary-title">Suma</div>
                    <div className="summary">
                        {withoutDiscount && discount &&
                            <div className="form__plan--oldPrice">{withoutDiscount}</div>
                        }
                        {sum} zł
                    </div>

                </div>
            </div>

            <button className="button form-submit small-button setting-subscription">Płacę</button>
        </form>
                    <SiteNotice type={"Error message"}/>
</>
    )
}

export default SubscriptionExtension

