import { Link, Redirect, useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sendEmailToResetPass } from '../../actions/user'
import { EMAIL, EMAIL_TO_RESET_PASS_RESET, RESET_PASSWORD, RESET_PASSWORD_RES } from '../../constants/types'
import SiteNotice from '../SiteNotice'

const PasswordReset = () => {
    const { loggedIn, emailToReset, emailToResetFailed, passwordResetFailed } = useSelector((state) => state.user)
    const [email, setEmail] = useState({mail: ""})

    const dispatch = useDispatch()
    const history = useHistory()

    const changeEmail = (e) => {
        setEmail({
            mail: e.target.value
        })
    }
    const handleSendEmail = (e) => {
        e.preventDefault()
        dispatch(sendEmailToResetPass(email))
        dispatch({type: EMAIL, payload: email})
    }

    useEffect(()=>{
        if(emailToReset && !emailToResetFailed){
            history.push('/user/password-new')
        }

    }, [emailToReset, emailToResetFailed])

    useEffect(()=>{
        if(emailToResetFailed){
            dispatch({type: EMAIL_TO_RESET_PASS_RESET})
        }
    }, [email, dispatch])

    useEffect(()=>{
        dispatch({type: RESET_PASSWORD, payload: false})
        if(passwordResetFailed){
            dispatch({type: RESET_PASSWORD_RES})
        }
    }, [])

    if (loggedIn) {
        return <Redirect to='/' />;
    }
    return (
        <form className="user-reset-form" onSubmit={handleSendEmail}>
        <div className={emailToResetFailed ? "reset-content--alert" : "reset-content"}>
            <h2 className="block-title">Nie pamiętasz hasła?</h2>
            <p className="block-description">Podaj swój adres e-mail, na który wyślemy hasło tymczasowe</p>
            <div className="form__item">
                <div className="description">Adres e-mail</div>
                <label className="form__label visually-hidden">Adres e-mail</label>
                <input className="form-text form__field" required="required" value={email.mail} onChange={changeEmail}/>
            </div>
            <div className="form-wrapper">
                <input className="button form-submit small-button" type="submit" value="Pobierz" />
                <p>Powrót do strony <Link to="/user/login">Logowania</Link></p>
            </div>
        </div>
            <SiteNotice  type={"Error message"} />
        </form>
    )
}

export default PasswordReset