import { SET_ARTICLES, SET_SEARCH_VALUE, GET_CREATORS } from "../constants/types";

const initialState = {
    articles: {
        rows: []
    },
    searchValue: "",
    vote: ""

}
const articlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ARTICLES:
            return {
                ...state, articles: action.payload
            }
        case SET_SEARCH_VALUE:
            return {
                ...state, searchValue: action.payload
            }
        case GET_CREATORS:
            return {
                ...state, creators: action.payload
            }
            case 'SET_VOTE':
                return {
                    ...state, vote: action.payload
                }
        default:
            return state;
    }
}

export default articlesReducer