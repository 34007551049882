import Select, {components} from 'react-select'
import {useEffect, useState} from "react";
import {useSelector} from 'react-redux'
import { IsFreeAccount } from '../../utils/userDetails';

const Dropdown = (props) => {

    const freeAccount = IsFreeAccount()
    const light = {
        themePageBackground: "#ffff",  // white;
        themePageText: "#151515", // $col-8:
        borderColor: "#E0E0E0", // $col-11:
        backgroundButton: "#2B2B2BFF", //  $col-9;
        themeOtherText: "#6B767EFF", // $col-10;
        textOnBlackButton: "#ffff", // white;
        active: "#000", // $col-1;
        filter: "none", // black;
        logo:  "none", // none;
        profileBackground: "#e0e0e0", //$col-11:
        blueText: '#3b4d5a', // $col-2;
        greyBack:"#F2F3F4", // #F2F3F4;
        lightGrey:"#cccc",
    }


    const dark = {
        themePageBackground: "#151515",  // white;
        themePageText: "#ececec", // $col-8:
        borderColor: "#3B4D5AFF", // $col-11:
        backgroundButton: "#2B2B2BFF", //  $col-9;
        themeOtherText: "#6B767EFF", // $col-10;
        textOnBlackButton: "white", // white;
        active: "#ffff", // $col-1;
        filter: "invert(198%) sepia(24%) saturate(347%) hue-rotate(201deg) brightness(150%) contrast(100%)", // black;
        logo:  "invert(100%) sepia(0%) saturate(0%) hue-rotate(393deg) brightness(200%) contrast(100%)", // none;
        profileBackground: "transparent", //$col-11:
        blueText: '#E0E0E0', // $col-2;
        greyBack:"#3a3a3a", // #F2F3F4
        lightGrey:"#B3B3B3",
    }

    const [mode,setMode] = useState(light)
    const { settingPreferences, loggedIn } = useSelector((state) => state.user)
    const { subscription_plan_name } = useSelector((state) => state.user.userDetails)

    useEffect(() => {
        settingPreferences.dark_mode === "1" ? setMode(dark) : setMode(light)
    }, [settingPreferences])


    const IndicatorsContainer = props => {
        return (
            <div style={{ zIndex:1, width:24 }}>
                <components.IndicatorsContainer {...props} />
            </div>
        );
    };

    const MenuList = props => {
        return (
                <div style={{border: `1px ${mode.lightGrey} solid`}}>
                    <components.MenuList {...props} />
                </div>
        );
    };


    const colourStyles = {
        control: (styles, { data, isDisabled, isFocused, isSelected, is }) => ({
            ...styles,
            width: props.width || 125,
            height:props.height || 50,
            backgroundColor: mode.themePageBackground,
            color:mode.themePageText,
            cursor:"pointer",
            borderColor: isSelected
                ? mode.themePageText
                : isFocused
                    ? mode.active
                    :  mode.lightGrey,

            ':hover': {
                ...styles[':hover'],
                borderColor:mode.active,
            },

        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                fontSize:14,
                padding: "11px 0 11px 17px",
                fontWeight: isSelected ? 700 : 500,
                backgroundColor: mode.themePageBackground,
                borderColor:mode.lightGrey,
                color: isDisabled
                    ? mode.themePageBackground
                    : isSelected,
                cursor: isSelected ? 'default' : 'pointer',

                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (isSelected ? data.color : mode.themePageBackground),
                },
            };
        },
        menuList: styles => ({
            ...styles,
            padding:0,
            borderColor:mode.lightGrey,
            backgroundColor: mode.lightGrey,
        }),
        menu: (provided, state) => ({
            ...provided,
            padding:0,
            borderColor:mode.lightGrey,
            backgroundColor: mode.lightGrey,
        }),
        indicatorSeparator: styles => ({
            ...styles,
            display:"none"
        }),
        singleValue: styles => ({
            ...styles,
            fontSize:14,
            color:mode.themePageText,
            paddingLeft:8,
            fontWeight:600,
        }),
        dropdownIndicator: styles => ({
        ...styles,
        opacity:!props.dropdownIndicator ? 1 : 0
    }),

    };
    const disabledSelect = !loggedIn || (props.type==='sorting' && freeAccount) ? true : false
    return (
        <Select
            isDisabled={disabledSelect}
            onChange={props.handleFilter}
            defaultValue={props.defaultValue || props.options[0]}
            components={{ IndicatorsContainer, MenuList }}
            isSearchable={false}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'black',
                },
            })}
            options={props.options}
            styles={colourStyles}/>
    )
}

export default Dropdown