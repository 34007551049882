import { useSelector } from "react-redux"

export const useSiteMessage = () => {
    const { siteMessage } = useSelector((state) => state.user)
    const { siteMessagePayments } = useSelector((state) => state.subscriptions)

        //TODO - rewrite when api returns correct messages
        //const message = siteMessage.split(':').slice(2).join('')

    if(siteMessage){
        if(siteMessage.data){
            return siteMessage.data.message
        }
        else{
            if(siteMessage.includes('Sorry, unrecognized username or password.')){
                return 'Błędny login lub hasło'
            }
            if(siteMessage.includes('This IP address is temporarily blocked.')){
                return 'Zbyt wiele nieudanych prób logowania. Twój adres IP został tymczasowo zablokowany'
            }
            if(siteMessage.includes('is already taken.')){
                return "Ten adres e-mail jest już zajęty"
            }
            if(siteMessage.includes('Twoje aktualne hasło nie istnieje')){
                return 'Twoje aktualne hasło nie istnieje lub jest nieprawidłowe'
            }
            if(siteMessage.includes('Flags updated!')){
                return 'Twoi ulubieni twórcy zostali zaktualizowani'
            }
            if(siteMessage.includes('change password')){
                return 'Twoje hasło zostało zaktualizowane'
            }
            if(siteMessage.includes('The user has not been activated')){
                return 'Twoje konto nie zostało jeszcze aktywowane lub jest zablokowane'
            }
            if(siteMessage.includes('Incorrect email address or code')){
                return 'Nieprawidłowy kod pin'
            }
            if(siteMessage.includes('This User was not found or invalid')){
                return 'Konto o podanym adresie e-mail nie istnieje'
            }
            if(siteMessage.includes('The recovery password is not valid')){
                return 'Hasło tymczasowe jest nieprawidłowe'
            }
            if(siteMessage.includes('name, new_pass, and temp_pass fields are required')){
                return 'Email, hasło tymczasowe i nowe hasło są wymagane.'
            }
            if(siteMessage.includes('Kod polecenia został zapisany.')){
                return siteMessage
            }
            if(siteMessage.includes('Utworzono kampanię')){
                return siteMessage
            }
            if(siteMessage.includes('Nieprawidłowy kod afiliacyjny')){
                return siteMessage
            }
            if(siteMessage.includes('You can edit field Campaign affiliate only by 7 days')){
                return 'Możesz użyć kodu polecenia tylko przez pierwszych 7 dni od założenia konta.'
            }
        }
    }
}