import { useSelector } from "react-redux"
import {Link} from "react-router-dom";

const ContactRules = () => {

    const {nativeApp} = useSelector(state =>state.user)

    return (
        <div className="content">
            <ul className="aboutUs-wrapper">
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Kontakt, zasady</div>
                    <div className="aboutUs-text">
                        <div>Problemy techniczne, błędy, propozycje współpracy i komplementy – prosimy o kontakt na: <a className="contact-mail color-link color-link--contact-page" href="mailto:newsy@infopigula.pl">newsy@infopigula.pl</a></div>
                    </div>
                </li>
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Dane wydawcy:</div>
                    <div className="aboutUs-text">
                        <div>VYSOKO Group Łukasz Wysoczański</div>
                        <div>Al. Obrońców Lwowa 12/1</div>
                        <div>56-100 Wołów</div>
                        <div>NIP 9880264456</div>
                        <div>tel. 512 326 069</div>
                    </div>
                    {nativeApp &&
                        <div className="aboutUs-title">
                            <Link className="regulations-link regulations-bold color-link color-link--contact-page" to="/">www.infopigula.pl</Link>
                        </div>
                    } 
                </li>
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Ważne:</div>
                    <div className="aboutUs-text">
                        <div>
                            Infopiguła <strong>nie jest</strong> agregatem mediów informacyjnych.
                        </div>
                        <div>
                            Infopiguła nie kopiuje żadnych treści innych mediów.
                        </div>
                        <div>
                            Wszystkie treści na stronie <Link className="regulations-link regulations-bold" to="/">www.infopigula.pl</Link> oraz w aplikacji Infopiguła stanowią wyłączną pracę autorską redakcji Infopiguły i są zastrzeżone.
                        </div>
                        <div>
                            Wyrażamy zgodę na udostępnianie naszych treści, warunkiem jest podanie źródła.
                        </div>
                        <div>
                            Kopiowanie naszych treści jest zabronione.
                        </div>
                        <div>
                            Do informacji, które naszym zdaniem warto rozszerzyć, dodajemy linki prowadzące do losowych artykułów wyszukanych w internecie celem poszerzenia kontekstu.
                        </div>
                    </div>
                </li>
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Regulamin serwisu (<Link to='/user/regulations'>kliknij i przeczytaj</Link>)</div>
                </li>
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Polityka prywatności (<Link to='/user/privacy-policy'>kliknij i przeczytaj</Link>)</div>
                </li>
            </ul>
        </div>
    )
}

export default ContactRules