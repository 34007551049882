import { useSelector } from "react-redux"

const AboutUs = () => {

    const {nativeApp} = useSelector(state =>state.user)

    return (
        <div className="content">
            <ul className="aboutUs-wrapper">
            <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Kontakt</div>
                    <div className="aboutUs-text">
                        <div>Problemy techniczne, błędy, propozycje współpracy i komplementy – prosimy o kontakt na: <a className="contact-mail" href="mailto:newsy@infopigula.pl">newsy@infopigula.pl</a></div>
                    </div>
                </li>
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Infopiguła – idea</div>
                    <div className="aboutUs-text">
                        <div>Bez narracji. Bez emocji. W pigułce.</div>
                        <div>Minimalistyczny serwis z autorskim doborem wydarzeń wg kryterium istotności.</div>
                        <div>3 w 1: portal, newsletter i aplikacja. </div>
                        <div>Przeczesujemy agencje prasowe i mainstream, źródła naukowe, lokalne, czy branżowe.</div>
                        <div>Ukazujemy się w poniedziałki, środy i piątki. Otrzymasz od nas powiadomienie w apce i mailem.</div>
                        <div>Newsy piszemy w maksymalnym skrócie.</div>
                        <div>Zyskasz pełen obraz przemian w Polsce i na świecie.</div>
                        <div>Czytasz krótko – wiesz wszystko. </div>
                    </div>
                </li>
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Kim jesteśmy</div>
                    <div className="aboutUs-text">
                        <div className="aboutUs-paragraph">
                            <div>
                                <span className="aboutUs-bold">Łukasz Wysoczański</span> – CEO {'&'} redakcja naczelna.
                            </div>
                            Przedsiębiorca z Wrocławia, pasjonat informacji (od dziecka). Pracując 365 dni w roku tworzę większą część kontentu i rozwijam projekt. Nie odpowiadał mi poziom mediów, więc w 2014 r. postanowiłem je robić po swojemu.
                        </div>

                        <div className="aboutUs-paragraph">
                            <span className="aboutUs-bold">Łukasz Żuk</span> – redakcja, marketing, wieloletni partner projektu.
                            <div>Szef działu sprzedaży w Just Join IT i Rocket Jobs. Motocyklista, barcelonista, dusza towarzystwa. Olsztynianin w Gdańsku.
                            </div>
                        </div>

                        <div className="aboutUs-paragraph">
                            <span className="aboutUs-bold">Artur Kubicki</span> – redakcja, a trochę marketing.
                            <div>Wulkan pozytywnej energii, absolwent dziennikarstwa, manager w międzynarodowej korporacji. Również barcelonista i też z Gdańska.
                            </div>
                        </div>

                        <div className="aboutUs-paragraph">
                            <span className="aboutUs-bold aboutUs-abv" onClick={()=>{window.open("https://www.abventor.com")}}>Abventor</span> – profesjonalne studio deweloperskie z Krakowa odpowiadające za graficzne koncepty i ich oprogramowanie.
                        </div>

                        <div className="aboutUs-paragraph">
                            <span className="aboutUs-bold">Michał Majewski</span> – programista, twórca poprzedniej aplikacji, która cieszyła się uznaniem czytelników.
                        </div>
                        <div className="aboutUs-paragraph">
                            <span className="aboutUs-bold">Jakub Mrugalski</span> – wykonawca newslettera, na którym opieraliśmy się przez szereg lat, wydawca prasówek z newsami z IT #unknownews, redaktor Niebezpiecznik.pl. Zapraszamy na mrugalski.pl
                        </div>
                       {/* <div className="aboutUs-paragraph">
                            Wkrótce przedstawimy nowe osoby, które od niedawna z nami redagują.
                        </div>*/}
                    </div>
                </li>
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Nasza idea - Low Information Diet</div>
                    <div className="aboutUs-text">
                        <div>
                            Nie serwujemy śmieciowych informacji.
                        </div>
                        <div>
                            Zachowaj czas i energię na to, na co masz realny wpływ: na rozwój, rodzinę, karierę, aktywny tryb życia. Czytaj mniej, wiedz więcej.
                        </div>
                        <div>
                            Sensacja, polityczna narracja, wywołanie emocji... tego Ci oszczędzimy. Wierzymy w Twój
                            intelekt i zdolność do własnej oceny.
                        </div>
                        <div>
                            Wierzymy, że w XXI wieku jest komercyjne miejsce na rzetelność i minimalizm.
                        </div>
                        <div>
                            Interesują nas fakty i świat.
                        </div>
                    </div>
                </li>
                <li className="aboutUs-paragraph">
                    <div className="aboutUs-title">Bezpłatnie</div>
                    <div className="aboutUs-text">
                        <div>
                            Każdy może korzystać z aplikacji i serwisu.
                        </div>
                        <div>
                            Nie przekazujemy nikomu i nie nadużywamy Waszych adresów @.
                        </div>
                        {!nativeApp &&
                            <>
                                <div>
                                    Bezpłatnie otrzymujesz 10-14 newsów co każde wydanie (z minimum 40 dostępnych w pełnej wersji),
                                    ale nie masz dostępu do wydań specjalnych, najważniejszego newsa, newsów live ani pozytywu kończącego wydanie.
                                </div>
                                <div>
                                    Większość interakcji z newsami na stronie pozostaje nieaktywna.
                                </div>
                            </>
                        }
                        <div>
                            Jesteś na bieżąco informowany o nowościach w naszym projekcie.
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default AboutUs