import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../modals/Modal'
import { SHOW_MODAL, CLOSE_MODAL, SET_SEARCH_VALUE } from '../../constants/types'
import { isRecommendedRole } from '../../utils/userDetails'

const SidebarLeft = (props) => {
    const { loggedIn, searchVisibility, nativeApp, userRole } = useSelector((state) => state.user)
    const { searchValue } = useSelector((state) => state.articles)
    const [searchData, setSearchData] = useState("")

    const dispatch = useDispatch()

    const openModal = (e) => {
        props.setShowModal(prev => !prev)
        props.setTypeModal('unavailableForNotLoggedInModal')
        dispatch({type: SHOW_MODAL, payload: 'modal--change'})
    }
    const closeModal = () => {
        props.setShowModal(false)
        dispatch({type: CLOSE_MODAL})
    }

    const handleSearchInput = (e) =>{
        setSearchData(e.target.value)
    }

    const handleSearchInputSubmit = (e) => {
        e.preventDefault()
        dispatch({type: SET_SEARCH_VALUE, payload: searchData})
        window.scrollTo(0,0)
    }
    
    useEffect(() =>{
        if(!searchValue){
            setSearchData("")
        }
        else{
            setSearchData(searchValue)
        }
    },[searchValue])

    const handleClearInput = () => {
        dispatch({type: SET_SEARCH_VALUE, payload: ""})
        setSearchData("")
    }

    const recommendedRole = isRecommendedRole(userRole)
    return (
        <>
            <div className="form__search__container">
            {searchVisibility
                &&
            <form className="form__search" method="get" action="/search" onSubmit={handleSearchInputSubmit}>
                <input className="form__text find__text" placeholder="Szukaj" value={searchData} onChange={handleSearchInput} />

                {searchData &&
                    <div className="clear-search" onClick={handleClearInput}></div>
                }

                <div className="form__edit" >
                    <input className="form__submit" type="submit" />
                </div>
            </form>}
            </div>
                <div className="sidebar__menu">
                <ul className="listReset sidebar__list">
                    {loggedIn && (
                        <li className="sidebar__item acc-management"><Link to='/settings/personalization'>Zarządzanie Kontem</Link></li>
                    )}
                    {!nativeApp &&
                        <li className="sidebar__item premium">{loggedIn ? <Link to='/premium'>Premium</Link> : <Link to='#' onClick={openModal}>Premium</Link>}</li>
                    }
                    <li className="sidebar__item recommended">{loggedIn ? <Link to='/recommended'>Polecane</Link> : <Link to='#' onClick={openModal}>Polecane</Link>}</li>
                    <li className="sidebar__item my-content">{loggedIn ?<Link to='/mycontent'>Twórcy</Link> : <Link to='#' onClick={openModal}>Twórcy</Link>}</li>
                    {recommendedRole &&
                            <li className="sidebar__item affiliate-page">
                                <Link to='/affiliate-settings'>Ustawienia afiliacji</Link>
                            </li>
                        }
                </ul>
                </div>
           
        </>
    )
}

export default SidebarLeft