import ShareButtons from "./ShareButtons";
import Interweave from "interweave";
import VoteRating from './VoteRating'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SHOW_MODAL } from '../../constants/types'
import parse from 'html-react-parser'

const ArticleWrapper = (props) => {
    const { isMobile, loggedIn, settingPreferences } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const isInstaView = settingPreferences.display_mode === 'slider' && isMobile === "1"

    let sourceLink = props.source_external_link_override ? props.source_external_link_override : props.source_external_link
    let logoClassic = props.type === "myContent" ? props.creator_logo_img : props.source_logo_img
    let linkClassic = props.type === "myContent" ? props.creator_external_link : sourceLink

    const logo = isInstaView ? props.sliderImg : logoClassic
    const link = isInstaView ? props.linkImg : linkClassic
    const isCreatorsFilter = props.type === "myContent"

    const openModal = () => {
        dispatch({type: SHOW_MODAL, payload: 'modal--change'})
    }
    const handleDisableRating = () => {
        openModal()
        props.typeModal("disableVotingPanel")
        props.showModal(true)
    }

    if (props.voteActive) {
        const slider = document.querySelector('.slick-slider')
        if (slider) slider.addEventListener('click', props.closeAll)
    }
    
    return (
     <>
         <div className="article__wrapper">
             <div className={`article__icon filter-icon ${props.flagged ? "article__fav--added" : "article__fav "}`} onClick={() => props.addingToFavorite(props.nid, (props.flagged ? 0 : 1))}/>
             <ShareButtons shareError={props.shareError} title={props.title_news} body={props.field_news_content} position={props.position}/>
                {logo
                    ? props.type === "myContent" 
                    ? <div className="article__logo--creators" style={{ backgroundImage: `url(${parse(logo).props.src})` }} onClick={()=>{window.open(link)}}></div>
                    :<div className="logo__background">
                        {link
                            ? (loggedIn
                                ? <Link to="#" onClick={() => { window.open(link) }}><Interweave content={logo} /></Link>
                                : <Link to="#" onClick={props.shareError}><Interweave content={logo} /></Link>)
                            : <Interweave content={logo} />
                        }
                    </div>
                    : <div className="article__logo--background" > <div className="article__logo bi-logo" /></div>
                } 
             <div className="article__comment article__icon filter-icon" onClick={props.functionalityNotAvailable}/>
                {isCreatorsFilter ?
                    <div className="article__rating article__icon filter-icon" onClick={handleDisableRating}>
                    </div> :
                    <div id={props.nid} className={props.vote.user_vote || props.newRating ? "article__rating--alreadyVoted article__icon" : "article__rating article__icon filter-icon"} onClick={props.votingPanel}>
                        <span id={props.nid} onClick={props.votingPanel}>{(props.actualCount ? props.actualCount : props.vote.vote_count) >= 1 ? (props.voteAverage ? props.voteAverage.toFixed(2) : props.vote.vote_average.toFixed(2)) : "-"}</span>
                    </div>
                }
         </div>
         <div className={props.voteActive ? "article__content activeVoting" : "article__content voteRating"}>
             <div className={props.voteActive ? "rate__weight rate__weight--displaying" : "rate__weight"}>
                 <div className="rate__weight__title">Oceń wagę</div>
                 <VoteRating
                     id={props.nid}
                     rating={props.rating}
                     setRating={props.setRating}
                     closeVotingPanel={props.closeVotingPanel}
                     actualRating={props.vote.user_stars}
                     newRating={props.newRating}
                     articlesVoting={props.articlesVoting}

                 />
                 <div id={props.nid} className="close__button" onClick={props.closeVotingPanel}/>

             </div>
         </div>
         <div id={props.nid} className={props.voteActive ? "votingPanel" : ""} onClick={props.closeVotingPanel}/>
     </>
    )
}

export default ArticleWrapper