import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import Personalization from '../components/accountManagement/Personalization'
import Subscription from '../components/accountManagement/Subscription'
import AccountSettings from '../components/accountManagement/AccountSettings'
import SubscriptionExtension from '../components/accountManagement/SubscriptionExtension'
import { useSelector } from 'react-redux'

const AccountManagement = () => {
    const { loggedIn, deleteAcc, isSmallMobile, nativeApp } = useSelector((state) => state.user)

    if (!loggedIn && !deleteAcc) {
        return <Redirect to='/' />;
    }
    return (
        <div className="content period-fee">
            <div className="content-inner">
                <div className="content-filter">
                    <div className="content-wrapper">
                        <div className='content-settings'><NavLink className='content-link' activeClassName="active-filter" to='/settings/personalization'>Personalizacja</NavLink></div>
                        {!nativeApp &&
                            <div className='content-settings'><NavLink className='content-link' activeClassName="active-filter" to='/settings/subscription'>Subskrypcja</NavLink></div>
                        }
                        <div className='content-settings'><NavLink className='content-link' activeClassName="active-filter" to='/settings/account'>{`Ustawienia ${isSmallMobile === '1'? '' : 'konta'}`}</NavLink></div> 
                    </div>
                </div>
                <Switch>
                    <Route path={'/settings/subscription'} exact component={Subscription} />
                    <Route path={'/settings/subscription/extension'} component={SubscriptionExtension} />
                    <Route path={'/settings/personalization'} component={Personalization} />
                    <Route path={'/settings/account'} component={AccountSettings} />
                </Switch>
            </div>
        </div>
    )
}

export default AccountManagement

