import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Modal from '../modals/Modal'
import { logout, changePassword, getAffiliateCreatorUid, addAffiliateCode } from '../../actions/user'
import { SHOW_MODAL, CLOSE_MODAL, CHANGE_PASSWORD_RESET, AFFILIATE_RESET } from '../../constants/types'
import validationForm from '../../validation/validationForm'
import SiteNotice from '../SiteNotice'
import { useCookies } from 'react-cookie'

const AccountSettings = () => {
    const { userDetails, changePasswordFailed, changePasswordSuccessful, siteMessage, addAffiliateCodeSuccessful, addAffiliateCodeFailed, affiliateCreatorUid } = useSelector((state) => state.user)
    const [showModal, setShowModal] = useState(false)
    const [passVisibility, setPassVisibility] = useState({
        existingpass: false,
        pass: false
    })
    const [errors, setErrors] = useState({})
    const [errorAffiliate, setErrorAffiliate] = useState({})
    const [correctAffiliateData, setCorrectAffiliateData] = useState(false)
    const [correctData, setCorrectData] = useState(false)
    const [formData, setFormData] = useState({
        existingpass: "",
        pass: ""
    })
    const inputTypes = {
        existingpass: true,
        pass: true
    }
    const [dataAffiliateCode, setDataAffiliateCode] = useState({
        campaignCode: ""
    })
    const [ cookies, setCookie] = useCookies(['cookie'])    
    const history = useHistory();
    const dispatch = useDispatch()

    const handleLogout = () => {
        dispatch(logout())
        history.push("/");
        const userLog = {
            "customer_id": 0
        }
        console.log(JSON.stringify(userLog))
    }
    const openModal = () => {
        setShowModal(prev => !prev)
        dispatch({ type: SHOW_MODAL, payload: 'modal--removeAcc' })
    }

    const closeModal = () => {
        setShowModal(false)
        dispatch({ type: CLOSE_MODAL })
    }
    const togglePassVisibility = (e) => {
        if (e.target.id === "existingpass") {
            setPassVisibility(prev => ({ ...prev, existingpass: !prev.existingpass }))
        }
        if (e.target.id === "pass") {
            setPassVisibility(prev => ({ ...prev, pass: !prev.pass }))
        }
    }

    const changeAffiliateCode = (e) => {
        setDataAffiliateCode({
            campaignCode: e.target.value})
    }

    const sendAffiliateCode = (e) => {
        e.preventDefault()
        setErrorAffiliate(validationForm(false, dataAffiliateCode, true, {campaignCode: true}))
        setCorrectAffiliateData(true)
    }
    const changePasswordSubmit = (e) => {
        e.preventDefault()
        setErrors(validationForm(true, formData, true, inputTypes))
        setCorrectData(true)
    }

    const changeFormData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    useEffect(() => {
        if (Object.keys(errors).length === 0 && correctData) {
            dispatch(changePassword(formData, userDetails.uid))
            setFormData({
                existingpass: "",
                pass: ""
            })
        }
    }, [errors, correctData])

    useEffect(() => {
        if (Object.keys(errorAffiliate).length === 0 && correctAffiliateData) {
            dispatch(getAffiliateCreatorUid(dataAffiliateCode.campaignCode))
            setFormData({
                campaignCode: ""
            })
        }
    }, [errorAffiliate, correctAffiliateData])


    useEffect(() => {
        if (!cookies.affiliate) return
        setDataAffiliateCode({
            campaignCode: cookies.affiliate
        })
    }, [])

    useEffect(() => {
        if (!affiliateCreatorUid) return
        if (affiliateCreatorUid.uid) dispatch(addAffiliateCode(affiliateCreatorUid, userDetails.uid))
    }, [affiliateCreatorUid])

    useEffect(() => {
        if(siteMessage){
            dispatch({type: AFFILIATE_RESET})
        }
    }, [dataAffiliateCode])

    useEffect(() => {
        if(siteMessage){
            dispatch({type: CHANGE_PASSWORD_RESET})
        }
    }, [formData])

    useEffect(()=>{
        if(siteMessage){
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'auto'
            });
        }
        else{
            window.scrollTo(0,0);
        }
    },[siteMessage])

    useEffect(() => {
        setErrorAffiliate({
            campaignCode: ''
        })
    }, [dataAffiliateCode])

    return (
        <>
            <div className="profile profile--alert">
                <form className="user-login-form user-login-form--settings" onSubmit={changePasswordSubmit}>

                    <div className="form__item">
                        <div className="description">Adres e-mail</div>
                        <label className="form__label visually-hidden">Adres e-mail</label>
                        <input className="form-text form__field input-disable" value={userDetails.user_email} readOnly/>
                    </div>

                    <p className="block-title">Hasło</p>

                    <div className={errors.existingpass ? "form__item input-error" : "form__item"}>
                        <div className="description">Obecne hasło</div>
                        <label className="form__label visually-hidden">Hasło</label>
                        <input name="existingpass" className="form-text form__field" placeholder="Hasło" type={passVisibility.existingpass ? "text" : "password"} value={formData.existingpass} onChange={changeFormData} />
                        <span  id="existingpass" className={passVisibility.existingpass ? "form__field-icon form__field-icon--settings form__field-icon--hide" : "form__field-icon form__field-icon--settings form__field-icon--show"} onClick={togglePassVisibility}></span>
                    </div>
                    {errors.existingpass &&
                    <div className="form__item">
                        <p className="form-error form-error--settings">{errors.existingpass}</p>
                    </div>}

                    <div className={errors.pass ? "form__item input-error" : "form__item"}>
                        <label className="form__label visually-hidden">Nowe hasło</label>
                        <input name="pass" className="form-text form__field" placeholder="Nowe hasło" type={passVisibility.pass ? "text" : "password"} value={formData.pass} onChange={changeFormData} />
                        <span id="pass" className="form__field-icon form__field-icon--settings" onClick={togglePassVisibility}></span>
                        <span  id="pass" className={passVisibility.pass ? "form__field-icon form__field-icon--settings form__field-icon--hide" : "form__field-icon form__field-icon--settings form__field-icon--show"} onClick={togglePassVisibility}></span>
                    </div>
                    {errors.pass &&
                    <div className="form__item">
                         <p className="form-error form-error--settings">{errors.pass}</p>
                    </div>}

                    <div className="form-wrapper mobile account_settings">
                        <input className="button form-submit save__changes" type="submit" value="Zapisz zmiany" />
                    </div>
                </form>
                <form className="user-login-form user-login-form--settings" onSubmit={sendAffiliateCode}>
                <p className="block-title">Kod polecenia</p>
                <div className={errorAffiliate.campaignCode ? "form__item input-error" : "form__item"}>
                        <div className="description">Kod polecenia</div>
                        <label className="form__label visually-hidden">Kod</label>
                        <input name="referralCode" className="form-text form__field" value={dataAffiliateCode.campaignCode} onChange={changeAffiliateCode} />
                    </div>
                    {errorAffiliate.campaignCode &&
                    <div className="form__item">
                        <p className="form-error form-error--settings">{errorAffiliate.campaignCode}</p>
                    </div>}

                    <div className="form-wrapper mobile account_settings">
                        <input className="button form-submit save__changes" type="submit" value="Zatwierdź kod" />
                    </div>
                </form>

                <button className="log-out btnReset" onClick={handleLogout}>Wyloguj się</button>
                <button className="remove-acc btnReset" onClick={openModal}>Usuń konto</button>
            </div>
            {showModal &&
                <Modal type="deleteAccountModal" closeModal={closeModal} />
            }
            {(changePasswordFailed || changePasswordSuccessful) && <SiteNotice passwordChange={true} />}

            {(addAffiliateCodeSuccessful || addAffiliateCodeFailed) && <SiteNotice />}



        </>
    )
}

export default AccountSettings

