import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../abv/src/images/info-logo.svg'
import { SHOW_MODAL } from '../../constants/types'

import { useRef, useEffect,} from "react";
import { useDeviceDetect } from '../../hooks/useDeviceDetect'
import { isRecommendedRole } from '../../utils/userDetails'

const SidebarLefMobile = (props) => {
    const { loggedIn, modal, userDetails, nativeApp, userRole } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const deviceDetect = useDeviceDetect()

    const openModal = (e) => {
        dispatch({type: SHOW_MODAL, payload: 'modal--change'})
    }
    const closeMobileSidebar = () => {
        dispatch({ type: 'CLOSE_MODAL', payload: 'mobile-sidebar' })
    }
    let useClickOutside = (handler) => {
        let domNode = useRef();

        useEffect(() => {
            let maybeHandler = (e) => {
                if (!domNode.current?.contains(e.target)) {
                    handler();
                }
            };
            document.addEventListener("mousedown", maybeHandler);
            return () => {
                document.removeEventListener("mousedown", maybeHandler);
            };
        });
        return domNode;
    };

    let domNode = useClickOutside(() => {
        if(modal === 'mobile-sidebar') dispatch({ type: 'CLOSE_MODAL', payload: 'mobile-sidebar' })
    });

    const handlePwaButton = () => {
        closeMobileSidebar()
        if(loggedIn && deviceDetect === 'desktop') return
        props.setShowModal(true)
        if(!loggedIn){
            props.setShowModal(true)
            props.setTypeModal('unavailableForNotLoggedInModal')
        }
        if(loggedIn){
            if(deviceDetect === 'iosOtherBrowser'){
                props.setShowModal(true)
                props.setTypeModal('iosInfoModalOtherBrowser')
            }
            if(deviceDetect === 'iosSafari'){
                props.setShowModal(true)
                props.setTypeModal('iosInfoModalSafari')
            }
            if(deviceDetect === 'androidOpera'){
                props.setShowModal(true)
                props.setBrowserType("opera")
                props.setTypeModal('androidInfoModalFirefoxOpera')
            }
            if(deviceDetect === 'androidFirefox'){
                props.setShowModal(true)
                props.setBrowserType("firefox")
                props.setTypeModal('androidInfoModalFirefoxOpera')
            }
            
        }
        openModal()
    }
    const recommendedRole = isRecommendedRole(userRole)
    return (
        <div className={modal === "mobile-sidebar" ? "mobile__menu menu__open" : "mobile__menu"} >
            <div className="mobile__menu__content"  ref={domNode}>
                <div className="header__logo mobile" onClick={closeMobileSidebar}>
                    <Link to='/'>
                        <img src={logo} alt="logo" />
                    </Link>
                </div>

                {loggedIn ? (
                    <>
                        <div className="header__profile__full">
                            <div className="header__profile mobile">
                                {nativeApp
                                    ? <div className="header__profile--name">infopiguła</div>
                                    : <div className="header__profile--name">{userDetails.subscription_plan_name}</div>
                                }
                            </div>
                        </div>
                        <div className="sidebar__menu">
                            <ul className="listReset sidebar__list">
                                <li className="sidebar__item acc-management" onClick={closeMobileSidebar}><Link to='/settings/personalization'>Zarządzanie Kontem</Link></li>
                                {!nativeApp &&
                                    <li className="sidebar__item premium" onClick={closeMobileSidebar}><Link to='/premium'>Premium</Link></li>
                                }
                                <li className="sidebar__item recommended" onClick={closeMobileSidebar}><Link to='/recommended'>Polecane</Link></li>
                                <li className="sidebar__item my-content" onClick={closeMobileSidebar}><Link to='/mycontent'>Twórcy</Link></li>
                                {recommendedRole &&
                                    <li className="sidebar__item affiliate-page" onClick={closeMobileSidebar}><Link to='/affiliate-settings' >Ustawienia afiliacji</Link></li>
                                }
                            </ul>
                        </div>
                    </>

                ) : (
                    <>
                        <Link to="/user/login" className="btn btn-user form-submit" onClick={closeMobileSidebar}>Zaloguj się</Link>
                        <div className="sidebar__register">
                            <h4 className="block-title">Zarejestruj się w Infopigule</h4>
                                <ul className="register__list listReset">
                                    <li className="register__item">czytaj wszystko, co ma znaczenie</li>
                                    <li className="register__item">odblokuj interakcje serwisu</li>
                                    <li className="register__item">pobierz apkę na telefon</li>
                                </ul>
                            <Link to="/user/register" className="btn btn--sidebar" onClick={closeMobileSidebar}>Zarejestruj się</Link>
                        </div>
                    </>
                )
                }

                <div className="social-wrapper">
                    {!nativeApp &&
                        <div className="pwa-wrapper">
                            <button className="pwa-button btn-hide" onClick={handlePwaButton}><span className="pwa-title"> Infopiguła</span> - pobierz na telefon</button>
                        </div>
                    }
                  
                    <ul className="sidebar__menu__links--mobile"> 
                        <li className="sidebar__item-link" onClick={closeMobileSidebar}><Link to='/contact-rules'>Kontakt, zasady</Link></li>
                        <li className="sidebar__item-link" onClick={closeMobileSidebar}><Link to='/aboutus'>O nas</Link></li>
                        {loggedIn && <li className="sidebar__item-link" onClick={closeMobileSidebar}><Link to='/favorites'>Ulubione</Link></li>}
                        <li className="sidebar__item-link" onClick={closeMobileSidebar}><Link to='/faq'>FAQ + Tutorial</Link></li>
                    </ul>
                    <div className="social__icons">
                        <div className="social__icon facebook"><Link to="#" onClick={() => { window.open("https://www.facebook.com/infopigula") }}></Link></div>
                        <div className="social__icon instagram"><Link to="#" onClick={() => { window.open(" https://www.instagram.com/infopigula") }}></Link></div>
                    </div>
                </div>
                <button className="modal__close btnReset" onClick={closeMobileSidebar}>
                    <span className="visually-hidden">Close modal window</span>
                </button>
            </div>
        </div>
    )
}

export default SidebarLefMobile