import DeleteAccountModal from './DeleteAccountModal'
import PlansModal from './PlansModal'
import PremiumModal from './PremiumModal'
import UnsubscribingModal from './UnsubscribingModal '
import UnavailableForNotLoggedInModal from './UnavailableForNotLoggedInModal'
import UnavailableForFreeModal from './UnavailableForFreeModal'
import ImageModal from "./ImageModal"
import FunctionalityNotAvailableModal from './FunctionalityNotAvailableModal'
import UnavailableLiveModal from './UnavailableLiveModal'
import DisabledVotingPanel from './DisabledVotingPanel'
import IosInfoModalSafari from './IosInfoModalSafari'
import IosInfoModalOtherBrowser from './IosInfoModalOtherBrowser'
import AndroidInfoModalFirefoxOpera from './AndroidInfoModalFirefoxOpera'
import UnavailableFilterForFreeModal from './UnavailableFilterForFreeModal'

const ModalSwitch = (props) => {
    switch (props.type) {
        case "plansModal":
            return <PlansModal closeModal={props.closeModal} />
        case "deleteAccountModal":
            return <DeleteAccountModal closeModal={props.closeModal} />
        case "premiumModal":
            return <PremiumModal closeModal={props.closeModal} />
        case "unsubscribingModal":
            return <UnsubscribingModal closeModal={props.closeModal} />
        case "unavailableForNotLoggedInModal":
            return <UnavailableForNotLoggedInModal closeModal={props.closeModal} />
        case "unavailableForFreeModal":
            return <UnavailableForFreeModal  closeModal={props.closeModal} />
        case "unavailableFilterForFreeModal":
            return <UnavailableFilterForFreeModal  closeModal={props.closeModal} />
        case "imageModal":
            return <ImageModal closeModal={props.closeModal} nid={props.nid} articles={props.articles}/>
        case "functionalityNotAvailable":
            return <FunctionalityNotAvailableModal closeModal={props.closeModal} nid={props.nid} />
        case "unavailableLiveModal":
            return <UnavailableLiveModal closeModal={props.closeModal} />
        case "disableVotingPanel":
            return <DisabledVotingPanel closeModal={props.closeModal} />
        case "iosInfoModalSafari":
            return <IosInfoModalSafari closeModal={props.closeModal} />
        case "iosInfoModalOtherBrowser":
            return <IosInfoModalOtherBrowser closeModal={props.closeModal} />
        case "androidInfoModalFirefoxOpera":
            return <AndroidInfoModalFirefoxOpera closeModal={props.closeModal} browserType={props.browserType}/>
        default:
            return
    }
}

export default ModalSwitch