import { Link } from "react-router-dom"
const UnavailableForFreeModal = ({ closeModal }) => {
    return (
        <>
            <div className="modal__sectionTitle">Jest to dział specjalny. Dostępne w planie Premium.</div>
            <div className="modal__wrapper">
                <Link to='/premium' className="modal__confirmAction btn form-submit" onClick={closeModal}>Zobacz plany subskrypcji</Link>
            </div>
            <button className="modal__close btnReset" onClick={closeModal}/>
        </>
    )
}

export default UnavailableForFreeModal