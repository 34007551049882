import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const SidebarLeftDown = () => {
    const { loggedIn } = useSelector((state) => state.user)

    return (
        <>
            <div className={loggedIn ? "sidebar__menu__links" : "sidebar__menu__links width"}>
                <ul className="sidebar__links">
                    <li className="sidebar__item-link"><Link to='/contact-rules'>Kontakt, zasady</Link></li>
                    <li className="sidebar__item-link"><Link to='/aboutus'>O nas</Link></li>
                    {loggedIn && <li className="sidebar__item-link"><Link to='/favorites'>Ulubione</Link></li>}
                    <li className="sidebar__item-link"><Link to='/faq'>FAQ + Tutorial</Link></li>
                </ul>
            </div>
        </>
    )
}

export default SidebarLeftDown