import { useSelector } from "react-redux"

export const IsFreeAccount = () => {
    const { loggedIn } = useSelector((state) => state.user)
    const { subscription_plan_name } = useSelector((state) => state.user.userDetails)
    return loggedIn && subscription_plan_name === "Free"
}

export const showLessMoreText = (e, showTab, setShowTab) => {
    showTab.forEach(el => {
        const articleItemsArray = Array.from(document.querySelectorAll('.article__item'))
        let heightOffset = ''
        articleItemsArray.map(article => {;
            if(parseInt(article.id) === parseInt(e.target.id)){
                heightOffset = article.offsetHeight
            }
        })

        if (el.id === parseInt(e.target.id)) {
            setShowTab(() => {
                const actualPosition = window.pageYOffset
                let index = showTab.findIndex(item => item.id === parseInt(e.target.id))
                const newList = [...showTab];
                newList[index] = {
                    ...newList[index],
                    value: newList[index].value ? false : true
                }
                if(newList[index].value && newList[index].heightMin < heightOffset) {
                    newList[index] = {
                        ...newList[index],
                        heightMax: heightOffset
                    }
                }

                if(newList[index].heightMin && newList[index].heightMax && !newList[index].differenceHeight) {
                    newList[index] = {
                        ...newList[index],
                        differenceHeight: newList[index].heightMax - newList[index].heightMin
                    }
                }

                if(newList[index].differenceHeight && newList[index].value) {
                    window.scrollTo(0, actualPosition - newList[index].differenceHeight);
                }
                
                return newList
            })
        }
        if (el.id === undefined) {    
            setShowTab(() => {
                let index = showTab.findIndex(item => item.id === parseInt(e.target.id))
                if (index !== -1) return
                const newList = [...showTab];
                newList[showTab.length] = {
                    ...newList[index],
                    id: parseInt(e.target.id),
                    value: false,
                    heightMin: heightOffset
                }
                return newList
            })
        }
    })
}


export const calculateNewsCharsToHide = (item, maxCharactersNumber, showReadMoreBtn, maxLength) => {
    if (item.field_news_content.length >= maxLength) {

        const cutText = item.field_news_content.substr(0, maxLength)
        const charNumberToCloseParagraph = cutText.lastIndexOf('</p>')
        const cutText_2 = item.field_news_content.substring(651)
        const charNumberToCloseParagraph_2 = cutText_2.indexOf('</p>')
    
        if (charNumberToCloseParagraph > maxLength - 150) maxCharactersNumber = charNumberToCloseParagraph
        else if (charNumberToCloseParagraph_2 && charNumberToCloseParagraph_2 < maxLength + 50) maxCharactersNumber = maxLength + charNumberToCloseParagraph_2 + 1
        else maxCharactersNumber = maxLength
    
        if (item.field_news_content.length + 100 <= maxCharactersNumber) {
            showReadMoreBtn = false
            maxCharactersNumber = item.field_news_content.length
        }

        return [maxCharactersNumber, showReadMoreBtn]
    }
}

export const isRecommendedRole = (userRole) => {
    const recommendedRole = userRole && userRole.find(item => item.target_id === 'polecajacy')
    return recommendedRole
}
export const scroll = () => {
    window.scrollTo(0, 0)
}
