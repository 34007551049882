import { Link } from 'react-router-dom'
import logo from '../abv/src/images/info-logo.svg'
import { useSelector, useDispatch } from 'react-redux'
import { SET_SEARCH_VALUE } from '../constants/types'
const Header = () => {
    const {userDetails, loggedIn} = useSelector((state) => state.user)
    const dispatch = useDispatch()

    const handleClearInput = () => {
        dispatch({type: SET_SEARCH_VALUE, payload: ""})
    }
    return (
        <div className="header__inner inner">
            <div className="header__logo" onClick={handleClearInput}>
                <Link to='/'>
                    <img src={logo} alt="logo" />
                </Link>
            </div>
        </div>
    )
}

export default Header
