import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => {

    const Article = () => {
        return (
            <>
                <rect x="15" y="15" rx="4" ry="4" width="130" height="10"/>
                <rect x="155" y="15" rx="3" ry="3" width="130" height="10"/>
                <rect x="295" y="15" rx="3" ry="3" width="90" height="10"/>
                <rect x="395" y="15" rx="3" ry="3" width="130" height="10"/>
                <rect x="535" y="15" rx="3" ry="3" width="110" height="10"/>
                <rect x="15" y="50" rx="3" ry="3" width="90" height="10"/>
                <rect x="115" y="50" rx="3" ry="3" width="60" height="10"/>
                <rect x="185" y="50" rx="3" ry="3" width="190" height="10"/>
                <rect x="385" y="50" rx="3" ry="3" width="120" height="10"/>
                <rect x="525" y="50" rx="3" ry="3" width="120" height="10"/>
                <rect x="15" y="90" rx="3" ry="3" width="130" height="10"/>
                <rect x="160" y="90" rx="3" ry="3" width="120" height="10"/>
                <rect x="290" y="90" rx="3" ry="3" width="95" height="10"/>
                <rect x="395" y="90" rx="3" ry="3" width="250" height="10"/>
                <rect x="15" y="130" rx="3" ry="3" width="130" height="10"/>
                <rect x="160" y="130" rx="3" ry="3" width="175" height="10"/>
                <rect x="345" y="130" rx="3" ry="3" width="140" height="10"/>
                <rect x="495" y="130" rx="3" ry="3" width="150" height="10"/>
                <circle cx="25" cy="180" r="8"/>
                <circle cx="140" cy="180" r="8"/>
                <circle cx="310" cy="180" r="21"/>
                <circle cx="495" cy="180" r="8"/>
                <circle cx="625" cy="180" r="8"/>
            </>
        )
    }

    const Recommended = () =>{
        return(
            <>
                <rect x="90" y="9" rx="3" ry="3" width="88" height="9" />
                <rect x="91" y="31" rx="3" ry="3" width="500" height="5" />
                <rect x="91" y="45" rx="3" ry="3" width="500" height="5" />
                <rect x="91" y="59" rx="3" ry="3" width="500" height="5" />
                <circle cx="40" cy="40" r="40" />
            </>
        )
    }

    const Creators = () =>{
        return(
            <>
                <rect x="1" y="15" rx="3" ry="3" width="20" height="20" />
                <rect x="101" y="11" rx="3" ry="3" width="500" height="5" />
                <rect x="101" y="25" rx="3" ry="3" width="500" height="5" />
                <rect x="101" y="39" rx="3" ry="3" width="500" height="5" />
                <circle cx="70" cy="25" r="25" />
            </>
        )
    }

    const Settings = () =>{
        return(
            <>
                <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                <rect x="0" y="99" rx="5" ry="5" width="400" height="150" />
            </>
        )
    }

    const Free = () =>{
        return(
            <>
                <rect x="0" y="0" rx="3" ry="3" width="50" height="10" />
                <rect x="70" y="0" rx="3" ry="3" width="220" height="10" />
            </>
        )
    }

    const Input = () =>{
        return(
            <>
                <rect x="0" y="25" rx="3" ry="3" width="100" height="15" />
            </>
        )
    }

    const SliderContent = () =>{
        return(
            <>
                <rect x="12" y="0" rx="3" ry="3" width="38" height="6" />
                <rect x="0" y="176" rx="3" ry="3" width="54" height="6" />
                <rect x="0" y="85" rx="3" ry="3" width="38" height="6" />
                <rect x="0" y="261" rx="3" ry="3" width="42" height="6" />
                <rect x="0" y="44" rx="3" ry="3" width="50" height="6" />
                <rect x="0" y="220" rx="3" ry="3" width="54" height="6" />
                <rect x="0" y="130" rx="3" ry="3" width="45" height="6" />
                <rect x="0" y="306" rx="3" ry="3" width="49" height="5" />
                <rect x="35" y="23" rx="3" ry="3" width="35" height="6" />
                <rect x="39" y="199" rx="3" ry="3" width="35" height="6" />
                <rect x="31" y="109" rx="3" ry="3" width="35" height="6" />
                <rect x="35" y="285" rx="3" ry="3" width="35" height="6" />
                <rect x="38" y="68" rx="3" ry="3" width="35" height="6" />
                <rect x="42" y="244" rx="3" ry="3" width="35" height="6" />
                <rect x="33" y="153" rx="3" ry="3" width="35" height="6" />
                <rect x="37" y="329" rx="3" ry="3" width="35" height="6" />
                <rect x="0" y="23" rx="3" ry="3" width="24" height="6" />
                <rect x="0" y="199" rx="3" ry="3" width="28" height="6" />
                <rect x="0" y="109" rx="3" ry="3" width="20" height="6" />
                <rect x="0" y="285" rx="3" ry="3" width="24" height="5" />
                <rect x="0" y="68" rx="3" ry="3" width="27" height="6" />
                <rect x="0" y="244" rx="3" ry="3" width="31" height="5" />
                <rect x="0" y="153" rx="3" ry="3" width="22" height="6" />
                <rect x="0" y="329" rx="3" ry="3" width="26" height="6" />
                <rect x="62" y="0" rx="3" ry="3" width="24" height="6" />
                <rect x="66" y="176" rx="3" ry="3" width="24" height="6" />
                <rect x="58" y="85" rx="3" ry="3" width="24" height="6" />
                <rect x="62" y="261" rx="3" ry="3" width="24" height="6" />
                <rect x="64" y="44" rx="3" ry="3" width="24" height="6" />
                <rect x="68" y="220" rx="3" ry="3" width="24" height="6" />
                <rect x="60" y="130" rx="3" ry="3" width="24" height="6" />
                <rect x="64" y="306" rx="3" ry="3" width="24" height="6" />
                <rect x="99" y="0" rx="3" ry="3" width="24" height="6" />
                <rect x="103" y="176" rx="3" ry="3" width="24" height="6" />
                <rect x="94" y="85" rx="3" ry="3" width="24" height="6" />
                <rect x="98" y="261" rx="3" ry="3" width="24" height="6" />
                <rect x="101" y="44" rx="3" ry="3" width="24" height="6" />
                <rect x="105" y="220" rx="3" ry="3" width="24" height="6" />
                <rect x="97" y="130" rx="3" ry="3" width="24" height="6" />
                <rect x="101" y="306" rx="3" ry="3" width="24" height="6" />
                <rect x="145" y="23" rx="3" ry="3" width="24" height="6" />
                <rect x="149" y="199" rx="3" ry="3" width="24" height="6" />
                <rect x="141" y="109" rx="3" ry="3" width="24" height="6" />
                <rect x="145" y="285" rx="3" ry="3" width="24" height="6" />
                <rect x="148" y="68" rx="3" ry="3" width="24" height="6" />
                <rect x="152" y="244" rx="3" ry="3" width="24" height="6" />
                <rect x="143" y="153" rx="3" ry="3" width="24" height="6" />
                <rect x="147" y="329" rx="3" ry="3" width="24" height="6" />
                <rect x="200" y="0" rx="3" ry="3" width="24" height="6" />
                <rect x="204" y="176" rx="3" ry="3" width="24" height="6" />
                <rect x="195" y="85" rx="3" ry="3" width="24" height="6" />
                <rect x="199" y="261" rx="3" ry="3" width="24" height="6" />
                <rect x="202" y="44" rx="3" ry="3" width="24" height="6" />
                <rect x="206" y="220" rx="3" ry="3" width="24" height="6" />
                <rect x="197" y="130" rx="3" ry="3" width="24" height="6" />
                <rect x="201" y="306" rx="3" ry="3" width="24" height="6" />
                <rect x="181" y="23" rx="3" ry="3" width="24" height="6" />
                <rect x="185" y="199" rx="3" ry="3" width="24" height="6" />
                <rect x="176" y="109" rx="3" ry="3" width="24" height="6" />
                <rect x="180" y="285" rx="3" ry="3" width="24" height="6" />
                <rect x="183" y="68" rx="3" ry="3" width="24" height="6" />
                <rect x="187" y="244" rx="3" ry="3" width="24" height="6" />
                <rect x="179" y="153" rx="3" ry="3" width="24" height="6" />
                <rect x="183" y="329" rx="3" ry="3" width="24" height="6" />
                <rect x="236" y="0" rx="3" ry="3" width="38" height="6" />
                <rect x="240" y="176" rx="3" ry="3" width="34" height="6" />
                <rect x="232" y="85" rx="3" ry="3" width="42" height="6" />
                <rect x="236" y="261" rx="3" ry="3" width="38" height="6" />
                <rect x="239" y="44" rx="3" ry="3" width="35" height="6" />
                <rect x="243" y="220" rx="3" ry="3" width="31" height="6" />
                <rect x="234" y="130" rx="3" ry="3" width="40" height="6" />
                <rect x="238" y="306" rx="3" ry="3" width="36" height="5" />
                <rect x="216" y="23" rx="3" ry="3" width="58" height="6" />
                <rect x="220" y="199" rx="3" ry="3" width="54" height="6" />
                <rect x="212" y="109" rx="3" ry="3" width="62" height="6" />
                <rect x="216" y="285" rx="3" ry="3" width="58" height="5" />
                <rect x="219" y="68" rx="3" ry="3" width="55" height="6" />
                <rect x="223" y="244" rx="3" ry="3" width="51" height="5" />
                <rect x="214" y="153" rx="3" ry="3" width="60" height="6" />
                <rect x="218" y="329" rx="3" ry="3" width="56" height="6" />
                <rect x="135" y="0" rx="3" ry="3" width="52" height="6" />
                <rect x="139" y="176" rx="3" ry="3" width="52" height="6" />
                <rect x="131" y="85" rx="3" ry="3" width="52" height="6" />
                <rect x="135" y="261" rx="3" ry="3" width="52" height="6" />
                <rect x="138" y="44" rx="3" ry="3" width="52" height="6" />
                <rect x="142" y="220" rx="3" ry="3" width="52" height="6" />
                <rect x="133" y="130" rx="3" ry="3" width="52" height="6" />
                <rect x="137" y="306" rx="3" ry="3" width="52" height="6" />
                <rect x="82" y="23" rx="3" ry="3" width="52" height="6" />
                <rect x="86" y="199" rx="3" ry="3" width="52" height="6" />
                <rect x="78" y="109" rx="3" ry="3" width="52" height="6" />
                <rect x="82" y="285" rx="3" ry="3" width="52" height="6" />
                <rect x="84" y="68" rx="3" ry="3" width="52" height="6" />
                <rect x="88" y="244" rx="3" ry="3" width="52" height="6" />
                <rect x="80" y="153" rx="3" ry="3" width="52" height="6" />
                <rect x="84" y="329" rx="3" ry="3" width="52" height="6" />
            </>
            )
    }

    const SliderFooter = () =>{
        return(
            <>

                <circle cx="330" cy="15" r="12" />
                <circle cx="30" cy="15" r="12" />
                <circle cx="90" cy="15" r="12" />
                <circle cx="270" cy="15" r="12" />
                <rect x="150" y="3" rx="3" ry="3" width="60" height="25" />
            </>
        )

    }



    return (
        <ContentLoader
            speed={2}
            viewBox={props.type === "article" && "0 0 656 210"
                || props.type === "recommended" && "0 0 656 80"
                || props.type === "creators" && "0 0 658 60"
                || props.type === "settings" && "0 0 265 230"
                || props.type === "free" && "0 0 658 20"
                || props.type === "input" && "0 0 100 50"
                || props.type === "sliderContent" && `0 0 274 335`
                || props.type === "sliderFooter" && "0 0 375 30"
            ||"0 0 656 210"}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            {props.type === "article" && <Article />}
            {props.type === "recommended" && <Recommended />}
            {props.type === "creators" && <Creators />}
            {props.type === "settings" && <Settings />}
            {props.type === "free" && <Free />}
            {props.type === "input" && <Input />}
            {props.type === "sliderContent" && <SliderContent />}
            {props.type === "sliderFooter" && <SliderFooter />}
        </ContentLoader>
    )
};

export default Loader