import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
const FiltersMobile = (props) => {
    const [releasePeriodMobile, setReleasePeriodMobile] = useState(props.releasePeriod)
    const [releaseMobile, setReleaseMobile] = useState(props.opt)
    const { form_options } = useSelector((state) => state.articles.articles)
    const [inputSearch, setInputSearch] = useState("")
    const {settingPreferences}= useSelector((state) => state.user)
    const dispatch = useDispatch()

    useEffect(()=>{
        props.setArticlesDataMobile({
            ...props.articlesDataMobile,
            keys: props.searchValueMobile
        })
        setInputSearch(props.searchValueMobile)
    }, [])
    const handleSearchInput = (e) => {
        props.setArticlesDataMobile({
            ...props.articlesDataMobile,
            keys: e.target.value
        })
        dispatch({type: 'SET_SEARCH_VALUE', payload: ""})
        setInputSearch(e.target.value)
    }
    const handleFilter = () => {
        props.setActiveFilter(true)
        props.setLoadedArticles([])
        props.setMobileFilters(false)
        props.setArticlesData(props.articlesDataMobile)
        props.setReleasePeriod(releasePeriodMobile)
        props.setOpt(releaseMobile)
        props.setChangeReleases(false)
        props.setFiltersMobile(true)
        props.setSearchValueMobile(inputSearch)
    }

    const handleClick = (e) => {
        setReleaseMobile(form_options?.last_releases)
        if (e.target.id === 'all') {
            props.setArticlesDataMobile({
                ...props.articlesDataMobile,
                page: 0,
                release_publication_date_ago: "",
                show_last_release: "",
                release_nids: ""
            })
        }
        else if (e.target.id === 'last') {
            props.setArticlesDataMobile({
                ...props.articlesDataMobile,
                page: 0,
                show_last_release: 1,
                release_publication_date_ago: "",
                release_nids: ""
            })
        }
        else {
            const sortDate = Math.floor(new Date(Date.now() - e.target.id * 24 * 60 * 60 * 1000).getTime() / 1000)
            props.setArticlesDataMobile({
                ...props.articlesDataMobile,
                page: 0,
                release_publication_date_ago: sortDate,
                show_last_release: "",
                release_nids: ""
            })
        }

        setReleasePeriodMobile({
            value: e.target.id,
            label: e.target.textContent
        })
    };

    useEffect(()=>{
        if(settingPreferences.display_mode === 'slider'){
            setReleaseMobile(form_options?.last_releases)
            setReleasePeriodMobile({
                value: 'last',
            label: 'Ostatnie'
            })
        }
    }, [settingPreferences.display_mode])

    const [releaseNumbers, setReleaseNumbers] = useState([])
    const [addToReleaseNumbers, setAddToReleaseNumbers] = useState(false)

    const add = (index, e) => {
        addRelease(index, e)
        setAddToReleaseNumbers(true)
    }

    useEffect(()=> {
        setReleaseNumbers(
            list => {
                const releaseMobileChecked = releaseMobile.filter(el => el.checked === true);
                const checked = releaseMobileChecked.map(el => el.target_id)
                return checked
            }
        ) 
    }, [releaseMobile])

    useEffect(()=>{
        if(addToReleaseNumbers){
            props.setArticlesDataMobile({
                ...props.articlesDataMobile,
                page: 0,
                show_last_release: "",
                release_publication_date_ago: "",
                release_nids: releaseNumbers.join(',')
            })
            setAddToReleaseNumbers(false)
        }
          
    }, [releaseNumbers])

    const addRelease = (index, e) => {
        setReleasePeriodMobile({
            value: "",
            label: ""
        })
        if (releaseMobile[index].checked) {
            setReleaseMobile(list => {
                const index = list.findIndex(el => el.target_id === e.target.id);
                const newList = [...list];
                newList[index] = {
                    ...newList[index],
                    checked: false,
                }
                return newList
            })
        }
        else {
            setReleaseMobile(list => {
                const index = list.findIndex(el => el.target_id === e.target.id);
                const newList = [...list];
                newList[index] = {
                    ...newList[index],
                    checked: true,
                }
                return newList
            })
        }
    }

    const relasesTemporalDistancing = props.options.map((release) => (
        <div id={release.value} className={releasePeriodMobile.value === release.value ? 'filtration__item active' : 'filtration__item'} onClick={handleClick}>
            {release.label}
        </div>
    ))

    const releases = releaseMobile?.map((release, index) => (
        <div className="release">
            <input id={release.target_id} value={release.value} type="checkbox" className="release--checkbox" onChange={(e)=>add(index, e)} checked={releaseMobile[index].checked ? true : false} />
            <label htmlFor={release.target_id} className="release--title">{release.value.replaceAll('.', ' / ')}</label>
        </div>
    ))

    if (releaseMobile?.findIndex(el => el.checked === true) === -1) {
        if (releasePeriodMobile.label === "") {
            setReleasePeriodMobile({
                value: 'last',
                label: 'Ostatnie'
            })
        }
    }

    const sortSwitcher =[
        {id:"default", label:'Domyślnie'},
        {id:"byRating", label:'Wg wagi'}
    ]

    const changeSortType = (e) => {
       props.setSortType(e.target.id)
    }

    useEffect(() => {
        if (props.sortType === 'byRating'){
            props.setArticlesDataMobile({
                ...props.articlesDataMobile,
                sort_options: 'rating_results'
        })
    }
        else {
            props.setArticlesDataMobile({
                ...props.articlesDataMobile,
                sort_options: ""
            })
        }
    }, [props.sortType])

    return (
        <>
            <section className="filterBlock">
                <form className="form__search" method="get" action="/search">
                    <input className="form__text" placeholder="Wyszukiwanie słowem" value={props.articlesDataMobile.keys} onChange={handleSearchInput} />
                    <div className="form__edit">
                        <input className="form__submit search-mobile" type="text" />
                    </div>
                </form>
                <div className="filtration__title">Filtrowanie</div>
                <ul className="filtration__content">
                    {relasesTemporalDistancing}
                </ul>
                <div className="filtration__dates">
                    {releases}
                </div>

                <div className="display__mode">
                    <div className="display__mode--title">Sortowanie </div>

                    <div className="text__switcher filter">
                        {sortSwitcher.map((item) => {
                            return <div id={item.id}
                                className={`display__mode--item ${item.id} ${props.sortType === item.id && "active"}`}
                                onClick={changeSortType}>{item.label}
                            </div>
                        })}
                    </div>
                </div>

                <button type="button" className="form__plan--submit" onClick={handleFilter}>Filtruj</button>
                <button type="button" className="form__plan--submit no_background" onClick={() => { props.setMobileFilters(false) }}>Anuluj</button>

            </section>
        </>
    )
}

export default FiltersMobile