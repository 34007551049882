import React from 'react'
import PlansList from '../PlansList'
import { useSelector } from 'react-redux'

const PlansModal = ({ closeModal }) => {
    const { subscriptionPlans } = useSelector((state) => state.subscriptions)
    const Split = (e) => {
        const penny = e.toString().split(".")[1]
        const price = e.toString().split(".")[0]
        return(
            <div className="plans__price plans__price--special">{price}<span className="penny">{penny}</span><span className="plans__currency">pln</span></div>
        )
    }
    console.log(subscriptionPlans)
    const plans = subscriptionPlans.map((subscription, index) => (
        <div className="plans__col">
            <h4 className="plans-title">{subscription.name}</h4>
            <ul className="plans__list">
                <PlansList plan={index} classname={"plans__list--item"} />
            </ul>

            <div className="plans__col--wrapper">
              {Split(subscriptionPlans[index].amount / 100)}
                <button type="button" name="plan" value={subscription.id} className="modal__cancelAction btn btn--sidebar" onClick={closeModal}>Wybierz</button>
            </div>
        </div>
    ))

    return (
        <>
            <div className="modal__sectionTitle">Porównanie planów</div>
            <div className="modal__wrapper">
                <div className="plans__wrapper">
                    {plans}
                </div>
            </div>
            <button className="modal__close btnReset" onClick={closeModal}>
                <span className="visually-hidden">Close modal window</span>
            </button>
        </>
    )
}

export default PlansModal