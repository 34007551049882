import Home from '../pages/Home'
import { useCookies } from 'react-cookie'

const Affiliate = () => {
    const [ cookies, setCookie] = useCookies(['cookie']);

    const affiliateUrl = window.location.href.split('/').reverse()
    let date = new Date()
    date.setDate(date.getDate() + 7);

    if (affiliateUrl[0].length === 4 && affiliateUrl[1] === 'affiliate') setCookie('affiliate', affiliateUrl[0], { expires: date })

    return <Home />
}

export default Affiliate