import { useEffect } from "react"
import { useDispatch } from 'react-redux'
import Header from "./Header"
import { DELETE_ACCOUNT_RESET } from '../constants/types'

const DeleteAccountConfirm = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: DELETE_ACCOUNT_RESET })
    }, [])

    return (
        <div className="page__layout">
            <header className="page__header header">
                <Header />
            </header>
            <div className="page__mainWpr">
                <main className="page__main">
                    <div className="user-register-form user-register-confirmation">
                        <h2 className="block-title">Twoje konto zostało usunięte</h2>
                        <p className="confirmation-msg">Wszelkie Twoje dane zostały usunięte z naszego serwisu. Dziękujemy za dotychczasowe korzystanie z Infopiguły. Zapraszamy ponownie w przyszłości.</p>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default DeleteAccountConfirm