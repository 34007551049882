export const GET_TOKEN = 'GET_TOKEN'
export const GET_TOKEN_FAILED = 'GET_TOKEN_FAILED'
export const LOGIN = 'LOGIN'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGIN_FAILED_RESET = 'LOGIN_FAILED_RESET'
export const LOGOUT = 'LOGOUT'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_RESET = 'DELETE_ACCOUNT_RESET'
export const REGISTER = 'REGISTER'
export const REGISTER_RESET = 'REGISTER_RESET'
export const REGISTER_FAILED = 'REGISTER_FAILED'
export const REGISTER_FAILED_RESET = 'REGISTER_FAILED_RESET'
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION'
export const ACCOUNT_ACTIVATION_RESET = 'ACCOUNT_ACTIVATION_RESET'
export const ACCOUNT_ACTIVATION_FAILED = 'ACCOUNT_ACTIVATION_FAILED'
export const ACCOUNT_ACTIVATION_FAILED_RESET = 'ACCOUNT_ACTIVATION_FAILED_RESET'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_ARTICLES = 'SET_ARTICLES'
export const SET_FAVOURITE_CREATORS = 'SET_FAVOURITE_CREATORS'
export const RESET_FAVOURITE_CREATORS = 'RESET_FAVOURITE_CREATORS'
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const GET_CREATORS = 'GET_CREATORS'
export const SET_SUBSCRIPTIONS_PLANS = 'SET_SUBSCRIPTIONS_PLANS'
export const CREATE_SUBSCRIPTIONS_PLAN = 'CREATE_SUBSCRIPTIONS_PLAN'
export const SHOW_MODAL = 'SHOW_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CHANGE_RENEVAL_PERIOD = 'CHANGE_RENEVAL_PERIOD'
export const SELECTED_PLAN = 'SELECTED_PLAN'
export const DISCOUNT_CODE = 'DISCOUNT_CODE'
export const SET_VISIBILITY = 'SET_VISIBILITY'
export const EMAIL = 'EMAIL'
export const EMAIL_TO_RESET_PASS = 'EMAIL_TO_RESET_PASS'
export const DISCOUNT_CODE_FAILED = 'DISCOUNT_CODE_FAILED'
export const DISCOUNT_CODE_FAILED_RESET = 'DISCOUNT_CODE_FAILED_RESET'
export const EMAIL_TO_RESET_PASS_FAILED = 'EMAIL_TO_RESET_PASS_FAILED'
export const EMAIL_TO_RESET_PASS_RESET = 'EMAIL_TO_RESET_PASS_RESET'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
export const RESET_PASSWORD_RES = 'RESET_PASSWORD_RES'
export const ACCOUNT_SETTINGS_LOADED = 'ACCOUNT_SETTINGS_LOADED'
export const SMALL_MOBILE = 'SMALL_MOBILE'
export const NATIVE_APP = 'NATIVE_APP'
export const ADD_AFFILIATE_CODE = 'ADD_AFFILIATE_CODE'
export const ADD_AFFILIATE_CODE_FAILED = 'ADD_AFFILIATE_CODE_FAILED'
export const GET_AFFILIATE_CREATOR_UID = 'GET_AFFILIATE_CREATOR_UID'
export const GET_AFFILIATE_CREATOR_UID_FAILED = 'GET_AFFILIATE_CREATOR_UID_FAILED'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const CREATE_CAMPAIGN_RESET = 'CREATE_CAMPAIGN_RESET'
export const GET_ALL_CAMPAIGNS = 'GET_ALL_CAMPAIGNS'
export const AFFILIATE_RESET = 'AFFILIATE_RESET'
export const GET_AFFILIATE_CREATOR_UID_RESET = 'GET_AFFILIATE_CREATOR_UID_RESET'
export const GET_USER_ROLES = 'GET_USER_ROLES'