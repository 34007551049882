import {
    GET_TOKEN,
    GET_TOKEN_FAILED,
    LOGIN,
    LOGIN_FAILED,
    LOGIN_FAILED_RESET,
    LOGOUT,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_RESET,
    REGISTER,
    REGISTER_RESET,
    REGISTER_FAILED,
    REGISTER_FAILED_RESET,
    ACCOUNT_ACTIVATION,
    ACCOUNT_ACTIVATION_RESET,
    ACCOUNT_ACTIVATION_FAILED,
    ACCOUNT_ACTIVATION_FAILED_RESET,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_FAILED,
    CHANGE_PASSWORD_RESET,
    RESET_PASSWORD,
    GET_USER_DETAILS,
    SET_USER_DETAILS,
    SHOW_MODAL,
    CLOSE_MODAL,
    CHANGE_RENEVAL_PERIOD,
    SET_FAVOURITE_CREATORS,
    RESET_FAVOURITE_CREATORS,
    SET_VISIBILITY,
    EMAIL_TO_RESET_PASS,
    EMAIL,
    DISCOUNT_CODE,
    DISCOUNT_CODE_FAILED,
    DISCOUNT_CODE_FAILED_RESET,
    EMAIL_TO_RESET_PASS_FAILED,
    EMAIL_TO_RESET_PASS_RESET,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_RES,
    ACCOUNT_SETTINGS_LOADED,
    SMALL_MOBILE,
    NATIVE_APP,
    ADD_AFFILIATE_CODE,
    ADD_AFFILIATE_CODE_FAILED,
    GET_AFFILIATE_CREATOR_UID,
    GET_AFFILIATE_CREATOR_UID_FAILED,
    CREATE_CAMPAIGN,
    CREATE_CAMPAIGN_RESET,
    GET_ALL_CAMPAIGNS,
    AFFILIATE_RESET,
    GET_AFFILIATE_CREATOR_UID_RESET,
    GET_USER_ROLES
} from '../constants/types'

const token = JSON.parse(localStorage.getItem('csrf_token'));

const initialState = token ? {
    searchVisibility:false,
    loggedIn: true,
    loginFailed: false,
    changePasswordFailed: false,
    deleteAcc: false,
    addReferralCodeSuccessful: false,
    affiliateCreatorUid: undefined,
    userDetails: {
        subscription_plan_id: ""
    },
    modal: "",
    settingPreferences: {
        dark_mode: '',
        justified_text: '',
        send_releases_by_email: '',
        slider_background: '',
        font: '',
        font_size: '',
        display_mode: ''
    },
    siteMessage: "",
    typeMessage: "",
    isCreatorsUpdate: false,
    isMobile: "",
    discountCodeFailed: "",
    isAccountSettingsLoaded: false,
    isSmallMObile: undefined,
    nativeApp: false,
    createCampaignSuccessful: false,
    allCampaigns: undefined,
    addAffiliateCodeFailed: undefined,
    affiliateClients: [],
    userRole: undefined,
    againCall:false
} : {
    loggedIn: false,
    token: "",
    registerSuccess: false,
    modal: "",
    userDetails: {
        subscription_plan_id: ""
    },
    settingPreferences: {
        dark_mode: '',
        justified_text: '',
        send_releases_by_email: '',
        slider_background: '',
        font: '',
        font_size: ''
    },
    siteMessage: "",
    registerData: "",
    accActivationFailed: "",
    emailToResetFailed: "",
    passwordResetFailed: "",
    emailToReset: "",
    mail: "",
    resetPass: false,
    nativeApp: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TOKEN:
            localStorage.setItem('token', JSON.stringify(action.payload))
            return {
                ...state, token: action.payload
            }
        case GET_TOKEN_FAILED:
            localStorage.removeItem('token')
            localStorage.removeItem('csrf_token')
            return {
                ...state, loggedIn: false,
            }
        case GET_USER_DETAILS:
            return {
                ...state, settingPreferences: action.payload, userDetails: action.payload
            }
        case SET_USER_DETAILS:
            return {
                ...state, settingPreferences: action.payload,
            }
        case LOGIN:
            // window.location.reload(false);
            localStorage.setItem('token', JSON.stringify(action.payload.data.access_token))
            localStorage.setItem('csrf_token', JSON.stringify(action.payload.data.csrf_token))
            localStorage.removeItem('firebaseToken')
            localStorage.removeItem('firebaseNewToken')
            return {
                ...state, loggedIn: true, loginFailed: false, email: action.payload.data.current_user.name, token: action.payload.data.access_token
            }
        case LOGIN_FAILED:
            return {
                ...state, loggedIn: false, loginFailed: true, siteMessage: action.payload
            }
        case LOGIN_FAILED_RESET:
            return {
                ...state, loggedIn: false, loginFailed: false, siteMessage: ""
            }
        case LOGOUT:
            localStorage.removeItem('token')
            localStorage.removeItem('csrf_token')
            localStorage.removeItem('mode')
            localStorage.removeItem('firebaseToken')
            localStorage.removeItem('firebaseNewToken')
            return {
                ...state, loggedIn: false, userDetails: "", settingPreferences: "", email: "", userRole: undefined
            }
        case DELETE_ACCOUNT:
            localStorage.removeItem('token')
            localStorage.removeItem('csrf_token')
            localStorage.removeItem('mode')
            return {
                ...state, loggedIn: false, deleteAcc: true
            }
        case DELETE_ACCOUNT_RESET:
            return {
                ...state, deleteAcc: false
            }
        case REGISTER:
            localStorage.setItem('registerData', JSON.stringify(action.payload))
            return {
                ...state, registerData: action.payload, alreadyExist: false, registerSuccess: true
            }
        case ACCOUNT_ACTIVATION: {
            return {
                ...state, accActivation: true, accActivationFailed: false,
            }
        }
        case ACCOUNT_ACTIVATION_RESET: {
            localStorage.removeItem('registerData')

            return {
                ...state, accActivation: false, accActivationFailed: false,
            }
        }
        case ACCOUNT_ACTIVATION_FAILED:
            return {
                ...state, accActivation: false, accActivationFailed: true, siteMessage: action.payload
            }
        case ACCOUNT_ACTIVATION_FAILED_RESET: 
            return {
                ...state, accActivation: false, accActivationFailed: false
            }
        case REGISTER_RESET:
            return {
                ...state, registerSuccess: false, siteMessage: ""
            }
        case REGISTER_FAILED:
            return {
                ...state, alreadyExist: true, registerSuccess: false, siteMessage: action.payload
            }
        case REGISTER_FAILED_RESET:
            return {
                ...state, alreadyExist: false, registerSuccess: false, siteMessage: ""
            }
        case CHANGE_PASSWORD:
            return {
                ...state, changePasswordSuccessful: true, changePasswordFailed: false, typeMessage: "confirm", siteMessage: "change password"
            }
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state, changePasswordSuccessful: false, changePasswordFailed: true, typeMessage: "alert", siteMessage: action.payload
            }
        case CHANGE_PASSWORD_RESET:
            return {
                ...state, changePasswordFailed: false, siteMessage: "", addAffiliateCodeSuccessful: undefined, addAffiliateCodeFailed: undefined,
            }
        case RESET_PASSWORD:
            return {
                ...state, resetPass: action.payload
            }
        case RESET_PASSWORD_FAILED:
            return {
                ...state,  passwordResetFailed: true, typeMessage: "alert", siteMessage: action.payload
            }
        case RESET_PASSWORD_RES:
            return {
                ...state, siteMessage: "", passwordResetFailed: false
            }
        case EMAIL_TO_RESET_PASS:
            return {
                ...state, emailToReset: action.payload
            }
        case EMAIL_TO_RESET_PASS_FAILED:
            return {
                ...state, emailToResetFailed: true, typeMessage: "alert", siteMessage: action.payload
            }
        case EMAIL_TO_RESET_PASS_RESET:
            return {
                ...state,  siteMessage: "", emailToResetFailed: false
            }
        case EMAIL:
            return {
                ...state, mail: action.payload
            }
        case CHANGE_RENEVAL_PERIOD:
            return {
                ...state, renevalPeriod: action.payload
            }
        case SHOW_MODAL:
            document.querySelector("body").classList.add("show-modal")
            return {
                ...state, modal: action.payload
            }
        case CLOSE_MODAL:
            document.querySelector("body").classList.remove("show-modal")
            return {
                ...state, modal: ""
            }
        case SET_FAVOURITE_CREATORS:
            return {
                ...state, siteMessage: action.payload, typeMessage: "confirm", isCreatorsUpdate: true
            }
        case RESET_FAVOURITE_CREATORS:
            return {
                ...state, siteMessage: "", typeMessage: "", isCreatorsUpdate: false
            }
        case "MOBILE":
            return {
                ...state, isMobile: action.payload
            }
        case SMALL_MOBILE:
            return {
                ...state, isSmallMobile: action.payload
            }
        case SET_VISIBILITY:
            return {
                ...state, searchVisibility: action.payload
            }
        case DISCOUNT_CODE:
            return {
                ...state, discountCodeFailed: false, discountCode: action.payload
            }
        case DISCOUNT_CODE_FAILED:
            return {
                ...state, discountCodeFailed: true, siteMessage: action.payload
            }
        case DISCOUNT_CODE_FAILED_RESET:
            return {
                ...state, discountCodeFailed: false, siteMessage: ""
            }
        case ACCOUNT_SETTINGS_LOADED:
            return {
                ...state, isAccountSettingsLoaded: action.payload
            }
        case NATIVE_APP:
            return {
                ...state, nativeApp: true
            }
        case ADD_AFFILIATE_CODE:
            return {
                ...state, addAffiliateCodeSuccessful: true, addAffiliateCodeFailed: false, typeMessage: "confirm", siteMessage: "Kod polecenia został zapisany."
            }
        case ADD_AFFILIATE_CODE_FAILED:
            return {
                ...state, addAffiliateCodeSuccessful: false, addAffiliateCodeFailed: true, typeMessage: "alert", siteMessage: action.payload, affiliateCreatorUid: false,
            }
        case AFFILIATE_RESET:
            return {
                ...state, addAffiliateCodeSuccessful: undefined, addAffiliateCodeFailed: false,  siteMessage: "",
                changePasswordSuccessful: undefined, changePasswordFailed: undefined, 
            }
        case GET_AFFILIATE_CREATOR_UID:
            return {
                ...state, affiliateCreatorUid: action.payload
            }

        case GET_AFFILIATE_CREATOR_UID_RESET:
            return {
                ...state, affiliateCreatorUid: undefined
            }

        case GET_AFFILIATE_CREATOR_UID_FAILED:
            return {
                ...state, addAffiliateCodeSuccessful: false, addAffiliateCodeFailed: true, typeMessage: "alert", siteMessage: "Nieprawidłowy kod afiliacyjny", affiliateCreatorUid: false,
            }
            
        case CREATE_CAMPAIGN:
            return {
                ...state, createCampaignSuccessful: true, typeMessage: "confirm", siteMessage: `Utworzono kampanię o nazwie ${action.payload.title[0].value} , shorttag: ${action.payload.short_tag[0].value}`
            }
        case CREATE_CAMPAIGN_RESET:
            return {
                ...state, createCampaignSuccessful: false, siteMessage: ""
            }
        case GET_ALL_CAMPAIGNS:
            return {
                ...state, allCampaigns: action.payload
            }
        case 'GET_AFFILIATE_CLIENTS':
            return {
                ...state, affiliateClients: action.payload
            }
        case GET_USER_ROLES:
            return {
                ...state, userRole: action.payload
            }
            case 'SET_ARTICLES_ONCE_AGAIN':
                return {
                    ...state, againCall: action.payload
                }

        default:
            return state;
    }
}

export default userReducer