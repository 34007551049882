import { useEffect, useState } from 'react'
import { Link, useHistory, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { register, getAffiliateCreatorUid } from '../actions/user'
import Modal from './modals/Modal'
import validationForm from '../validation/validationForm'
import icon1 from '../abv/src/images/icon-1.png'
import icon2 from '../abv/src/images/icon-2.png'
import icon3 from '../abv/src/images/icon-3.png'
import { getSubscriptionPlans } from '../actions/subscriptions'
import { SHOW_MODAL, CLOSE_MODAL, REGISTER_FAILED_RESET, GET_AFFILIATE_CREATOR_UID_RESET } from '../constants/types'
import SiteNotice from './SiteNotice'
import { useCookies } from 'react-cookie'

const RegisterForm = () => {
    const history = useHistory()
    const { registerSuccess, loggedIn, alreadyExist, nativeApp, affiliateCreatorUid } = useSelector((state) => state.user)
    const { subscriptionPlans } = useSelector((state) => state.subscriptions)
    const [passVisibility, setPassVisibility] = useState(false)
    const [errors, setErrors] = useState({})
    const [correctData, setCorrectData] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [firstPage, setFirstPage] = useState(true)
    const [checkboxValue, setCheckboxValue] = useState(false)
    const [formData, setFormData] = useState({
        email: "",
        pass: "",
        plan: "2",
    })
    const inputTypes = {
        email: true,
        pass: true,
        checkboxValue: true
    }
    const [ cookies, setCookie] = useCookies(['cookie'])
    const [affiliateCode, setAffiliateCode] = useState("")    
    const dispatch = useDispatch()

    useEffect(() => {
        if (!alreadyExist) {
            window.scrollTo({
                top: 0,
                behavior: 'auto'
            });
        }
    }, [alreadyExist])
  

    const togglePassVisibility = () => {
        setPassVisibility(!passVisibility)
    }
    useEffect(() => {
        dispatch(getSubscriptionPlans())
    }, [dispatch])

    const openModal = () => {
        setShowModal(prev => !prev)
        dispatch({ type: SHOW_MODAL, payload: 'modal--plans' })

    }
    const changeCheckboxValue = () => {
        setCheckboxValue(!checkboxValue)
    }
    const changeFormData = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
        if (showModal) {
            setShowModal(false)
            dispatch({ type: CLOSE_MODAL })
        }
    }
    const changeAffiliateCode = (e) => {
        setAffiliateCode(e.target.value)
    }
    
    const data = {
        name: { "value": formData.email },
        mail: { "value": formData.email },
        pass: { "value": formData.pass }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (formData.plan !== "3") {
            if(affiliateCode) dispatch(getAffiliateCreatorUid(affiliateCode))
            setErrors(validationForm(true, formData, checkboxValue, inputTypes))
            setCorrectData(true)
        }

        if (formData.plan === "3") {
            dispatch(register(data, formData.plan))
        }
    }

    function closeModal(e) {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
        setShowModal(false)
        dispatch({ type: CLOSE_MODAL })
    }

    const handleNextStep = () => {
        setErrors(validationForm(true, formData, checkboxValue, inputTypes))
        setCorrectData(true)
    }

    useEffect(() => {
        if (!cookies.affiliate) return
        setAffiliateCode(cookies.affiliate)
    }, [])

    useEffect(() => {
        if (Object.keys(errors).length === 0 && correctData && formData.plan === "3") {
            setFirstPage(false)
            window.scrollTo(0, 0);
        }
        if (Object.keys(errors).length === 0 && correctData && formData.plan !== "3") {
            if (affiliateCode) {
                if (affiliateCreatorUid && affiliateCreatorUid.uid) {
                    dispatch(register({
                        ...data,
                        field_user_affiliate: { "value": affiliateCreatorUid.uid },
                        field_campaign_affiliate: {"value": affiliateCreatorUid.id}
                    }))
                } 
                else if (affiliateCreatorUid !== undefined) dispatch(register(data))
            }
            else dispatch(register(data))
           
        }
    }, [errors, correctData, affiliateCreatorUid])

    useEffect(() => {
        if (!registerSuccess) return

        setFormData({
            ...formData,
            email: "",
            pass: "",
        })
        setCheckboxValue(false)
        dispatch({type: GET_AFFILIATE_CREATOR_UID_RESET})

        history.push('/user/register-activation')
    }, [registerSuccess, history])

    useEffect(() => {
        if (alreadyExist) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'auto'
            });
        }
    }, [alreadyExist])

    useEffect(()=>{
        if(alreadyExist){
            dispatch({type: REGISTER_FAILED_RESET})
        }
    }, [formData, checkboxValue, dispatch])

    if (loggedIn) {
        return <Redirect to='/' />;
    }

    {/* TODO: second step of registration */}
    // const registrationMargin = formData.plan !== "1" ? "registrationMargin--small": "registrationMargin--large"
    const registrationMargin = formData.plan === "2" ? "registrationMargin--small": "registrationMargin--large"
    const plans = subscriptionPlans.map((subscription, index) => (
        <div className={`form__plan--item ${registrationMargin}`}>
            <input value={subscription.id} type="radio" className="form__plan--checkbox" name="plan" onChange={changeFormData} checked={formData.plan === subscription.id ? true : false} />
            <label>
                <div className="form__plan--option registration">
                    <div className="form__plan--title">{subscription.name}</div>
                    {/* TODO: second step of registration */}
                    {/* {subscriptionPlans[index].name !== "Free" ?
                     <div className="form__plan--price promo"><div className="form__plan--oldPrice">{subscriptionPlans[index].amount / 100}</div> 0 <span className="plans__currency">pln</span> </div> : 
                     <div className="form__plan--price">{subscriptionPlans[index].amount / 100} <span className="plans__currency">pln</span> </div>
                    } */}
                    {subscriptionPlans[index].name === "Premium" ?
                     <div className="form__plan--price promo"><div className="form__plan--oldPrice">{subscriptionPlans[index].amount / 100}</div> 0 <span className="plans__currency">pln</span> </div> : 
                     <div className="form__plan--price">{subscriptionPlans[index].amount / 100} <span className="plans__currency">pln</span> </div>
                    }

                </div>
                <span className="option-label registration btn">{formData.plan === subscription.id ? "Wybrany" : "Wybierz"}</span>
            </label>
        </div>
    ))

    const secondStep = subscriptionPlans.map((subscription, index) => {
        const i = JSON.parse(formData.plan)
        return (
            <>
                <div className="form__plan--choosen">
                    <div className="form__plan--col">
                        <div className="form__plan--title">Plan {subscriptionPlans[i -1].name}</div>
                        {/* <div className="form__plan--subtitle">Pierwszy miesiąc za darmo!</div> */}
                    </div>
                    {/* <div className="form__plan--price promo"><div className="form__plan--oldPrice">{subscriptionPlans[i -1].amount / 100}</div> 0 <span className="plans__currency">pln</span> </div> */}
                    <div className="form__plan--price form__plan--priceVip">{subscriptionPlans[i -1].amount / 100} <span className="plans__currency">pln</span> </div>
                </div>
                <div className="form__plan--info">
                    Teraz otrzymasz na swój adres @ unikalny kod PIN do aktywacji konta w serwisie.
                    Następnie aby opłacić konto VIP skorzystaj z zakładki "Premium" w menu serwisu.
                    <div className="bold">Dopóki tego nie zrobisz, korzystać będziesz z promocyjnego miesiąca Plan Premium</div>

                </div>
               
                {/* <div className="form__plan--info">Aby odebrać darmowy miesiąc konta premium wybierz pośrednika autoodnowy z karty. Po upływie próbnego okresu będziesz płacił <span className="form__plan--monthlyprice">{subscriptionPlans[i -1].amount / 100} zł / m-c.</span></div>
                <div className="form__plan--info bold">W każdej chwili w naszym serwisie jednym klikiem anulujesz zlecenie. Łatwo i szybko.</div> */}
            </>
        )
    })

    return (
        <>
            <form className="user-register-form" onSubmit={handleSubmit}>
                <div className={alreadyExist ? "register-content--alert" : "register-content"}>
                    {firstPage ? (
                        <>
                            <h2 className="block-title">Rejestracja</h2>
                            {!nativeApp && <p className="block-subtitle">Wypróbuj pełną wersję serwisu za 0 zł.</p>}
                            <div className={errors.email ? "form__item input-error" : "form__item"}>
                                <div className="description">Adres e-mail</div>
                                <label className="form__label visually-hidden">Adres e-mail</label>
                                <input className="form-text form__field" name="email" value={formData.email} onChange={changeFormData} />

                            </div>

                            {errors.email &&
                                <div className="form__item">
                                    <p className="form-error">{errors.email}</p>
                                </div>}
                            <div className={errors.pass ? "form__item input-error" : "form__item"}>
                                <label className="form__label visually-hidden">Hasło</label>
                                <input className="form-text form__field" name="pass" placeholder="Hasło" type={passVisibility ? "text" : "password"} value={formData.pass} onChange={changeFormData} />
                                <span className={passVisibility ? "form__field-icon form__field-icon--hide" : "form__field-icon form__field-icon--show"} onClick={togglePassVisibility}></span>
                            </div>

                            {errors.pass &&
                                <div className="form__item">
                                    <p className="form-error">{errors.pass}</p>
                                </div>}
                            {!nativeApp &&
                                <section className="form__plan--list">
                                    <div className="form__plan--description">
                                        <div className="form__plan--text">Wybierz plan</div>
                                        <div className="form__plan--compare" onClick={openModal}>Zobacz porównanie planów</div>
                                    </div>

                                    <div className="form__plan--wrapper registration">
                                        {plans}
                                    </div>
                                    {formData.plan === "2" && <div className="free__month">Pierwszy miesiąc za darmo!</div>}
                                </section>
                            }
                            <div className="form__item form__item--affiliateCode">
                                <div className="description">Kod polecenia (nieobowiązkowe)</div>
                                <label className="form__label visually-hidden">Kod polecenia</label>
                                <input className="form-text form__field" name="affiliateCode" value={affiliateCode} onChange={changeAffiliateCode} />
                            </div>
                            <div className="form-regulations">
                                    <div className="reg-accept">
                                        <input type="checkbox" name="reg" value={checkboxValue} onChange={changeCheckboxValue} checked={checkboxValue ? true : false} />
                                        <label className="test" htmlFor="reg">Rejestrując się wyrażasz zgodę na    
                                        <span> <Link to='#' className="reg-link" onClick={()=>{window.open('/#/user/privacy-policy')}}>politykę prywatności</Link></span>, 
                                        <span> <Link to='#' className="reg-link" onClick={()=>{window.open('/#/user/rodo')}}>RODO</Link></span>
                                        <span> oraz akceptujesz </span>
                                        <Link to='#' className="reg-link" onClick={()=>{window.open('/#/user/regulations')}}>regulamin serwisu</Link>
                                        </label>
                                    </div>
                                    {errors.checkbox && <p className="form-error checkbox">{errors.checkbox}</p>}
                            </div>
                            {formData.plan !== "3"? (
                                <input className="btn-user btn btn--fullWidth btn-register mb-0" type="submit" value="Aktywuj" />

                            ) : (
                                <button type="button" className="btn-user btn btn--fullWidth btn-register" onClick={handleNextStep}>Przejdź dalej</button>
                            )}
                            <div className="register-wrapper">
                                <p>Masz już konto?<Link to="/user/login">Zaloguj się</Link></p>
                            </div>
                        </>
                    ) : (
                        <>
                            <button className="form__step-back prev-arrow" onClick={() => setFirstPage(!firstPage)}>Powrót</button>
                            {secondStep[0]}
                            <div className="form__plan--icons">
                                {/* <div className="form__plan--icon payment__list--item">
                                    <input type="radio" name="choosePlan" />
                                    <label>
                                        <img className="applePay" src={icon1} alt="x" />
                                    </label>
                                </div>

                                <div className="form__plan--icon payment__list--item">
                                    <input type="radio" name="choosePlan" />
                                    <label>
                                        <img src={icon3} alt="x" />
                                    </label>
                                </div>

                                <div className="form__plan--icon payment__list--item">
                                    <input type="radio" name="choosePlan" />
                                    <label>
                                        <img src={icon2} alt="x" />
                                    </label>
                                </div> */}
                            </div>
                            <input className="btn-user btn--fullWidth btn-register mb-0" type="submit" value="Aktywuj" />
                        </>
                    )}
                </div>
                {alreadyExist && <SiteNotice message={"Użytkownik już istnieje"} type={"Error message"} />
                }
            </form>
            {showModal &&
                <Modal type="plansModal" closeModal={closeModal} />
            }
            
        </>
    )
}

export default RegisterForm