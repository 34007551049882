import { Link } from "react-router-dom"
const UnavailableLiveModal = ({ closeModal }) => {
    return (
        <>
            <div className="modal__sectionTitle">Newsy tak ważne, że powinieneś je otrzymać od razu wraz z powiadomieniem. </div>
            <div  className="modal__sectionTitle modal__sectionTitle--subtitle">Dostępne w planie Premium</div>
            <div className="modal__wrapper">
                <Link to='/premium' className="modal__confirmAction btn form-submit" onClick={closeModal}>Zobacz plany subskrypcji</Link>
            </div>
            <button className="modal__close btnReset" onClick={closeModal}/>
        </>
    )
}

export default UnavailableLiveModal