import { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../../actions/user'
import {EMAIL_TO_RESET_PASS, RESET_PASSWORD_RES} from '../../constants/types'
import SiteNotice from '../SiteNotice'
import validationForm from '../../validation/validationForm'

const PasswordNew = () => {
    const dispatch = useDispatch()
    const { resetPass, loggedIn, mail, passwordResetFailed } = useSelector((state) => state.user)

    const [passwords, setPasswords] = useState({
        new_pass: "",
        temp_pass: "",
        name: ""

    })

    const [passVisibility, setPassVisibility] = useState({
        existingpass: false,
        new_pass: false, 
    })

    const [correctData, setCorrectData] = useState(false)
    const [errors, setErrors] = useState({})

    const inputTypes = {
        pass: true,
    }

    const togglePassVisibility = (e) => {
        if (e.target.id === "temp_pass") {
            setPassVisibility(prev => ({ ...prev, temp_pass: !prev.temp_pass }))
        }
        if (e.target.id === "new_pass") {
            setPassVisibility(prev => ({ ...prev, new_pass: !prev.new_pass }))
        }
    }

    useEffect(() =>{
        setPasswords({
            ...passwords,
            name: mail
        })
        dispatch({type: EMAIL_TO_RESET_PASS, payload: ""})
    },[])

    useEffect(()=>{
        if(passwordResetFailed){
            dispatch({type: RESET_PASSWORD_RES})
        }
    }, [passwords, dispatch])
    
    const changePassword = (e) => {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = {
            pass: passwords.new_pass
        }
        setErrors(validationForm(true, formData, false, inputTypes))
        setCorrectData(true)
    }


    useEffect(() => {
        if (Object.keys(errors).length === 0 && correctData) {
            dispatch(resetPassword(passwords))
        }
    }, [errors, correctData])


    if (loggedIn) {
        return <Redirect to='/' />;
    }
    return (
        !resetPass ? (
            <form className="user-reset-form" onSubmit={handleSubmit}>
                <div className={passwordResetFailed ? "reset-content--alert" : "reset-content"}>
                    <h2 className="block-title">Sprawdź swoją skrzynkę pocztową i ustaw nowe hasło</h2>
                    {!mail &&
                        <div className="form__item">
                            <div className="description">Adres e-mail</div>
                            <label className="form__label visually-hidden">Adres e-mail</label>
                            <input id="name" name="name" className="form-text form__field" required="required" value={passwords.name} onChange={changePassword} />
                        </div>}
                    <div className="form__item">
                        <div className="description">Hasło tymczasowe otrzymane mailem</div>
                        <label className="form__label visually-hidden">Hasło</label>
                        <input id="temp_pass" name="temp_pass" className="form-text form__field" placeholder="Hasło" type={passVisibility.temp_pass ? "text" : "password"} value={passwords.temp_pass} onChange={changePassword} />
                        <span id="temp_pass" className={passVisibility.temp_pass ? "form__field-icon form__field-icon--hide" : "form__field-icon form__field-icon--show"} onClick={togglePassVisibility}></span>
                    </div>
                    <div className={errors.pass ? "form__item input-error" : "form__item"}>
                        <div className="description">Nowe hasło</div>
                        <label className="form__label visually-hidden">Hasło</label>
                        <input id="new_pass" name="new_pass" className="form-text form__field" placeholder="Hasło" type={passVisibility.new_pass ? "text" : "password"} value={passwords.new_pass} onChange={changePassword} />
                        <span id="new_pass" className={passVisibility.new_pass ? "form__field-icon form__field-icon--hide" : "form__field-icon form__field-icon--show"} onClick={togglePassVisibility}></span>
                    </div>
                    {errors.pass &&
                        <div className="form__item">
                            <p className="form-error">{errors.pass}</p>
                        </div>
                    }
                    <div className="form-wrapper">
                        <input className="button form-submit" type="submit" value="Zmień hasło" />
                    </div>
                </div>
                <SiteNotice  type={"Error message"} />
            </form>
        ) : (
            <div className="user-reset-form">
                    <div className="reset-content">
                        <h2 className="block-title">Twoje nowe hasło zostało zapisane!</h2>
                        <Link to="/user/login" className="btn form-submit">Przejdź do strony logowania</Link>
                    </div>
            </div>
        )
    )
}

export default PasswordNew