import { useState } from 'react'
import {useSelector} from 'react-redux'
import ArticleWrapper from './AricleWrapper'
import Highlighter from "react-highlight-words";
import ReleaseBar from './ReleaseBar';
import {parseEntities} from 'parse-entities'
import Interweave from 'interweave'
import { showLessMoreText, calculateNewsCharsToHide } from '../../utils/userDetails'
const Articles = (props) => {
    const {settingPreferences, loggedIn} = useSelector((state) => state.user)

    const defaultStyles = {
        fontSize: 14,
        fontFamily: 'Lato',
        textAlign: 'left'
    }
    const changedStyles = {
        fontSize: parseInt(settingPreferences.font_size),
        fontFamily: settingPreferences.font,
        textAlign: settingPreferences.justified_text === '1' ? 'justify' : 'left'
    }

    const type = props.articlesType
    const styles = loggedIn && settingPreferences ? changedStyles : defaultStyles

    const [newsProperties, setnewsProperties] = useState([{
        id: undefined,
        value: false,
    }])

const search = props.searchValueMobile ? props.searchValueMobile : props.searchValue
    const article = props.loadedArticles.map((item, index) => {
        const ReleaseNumbers = props.loadedArticles.map(arti => arti.title_release)

        let showReleaseBar = false;
        if (ReleaseNumbers[index - 1] !== item.title_release) {
            showReleaseBar = true;
        }

        let maxCharactersNumber = 650
        let showReadMoreBtn = true
        let showNews = false
        const maxLength = 650

        const calculate = calculateNewsCharsToHide(item, maxCharactersNumber, showReadMoreBtn, maxLength)
        maxCharactersNumber = calculate ? calculate[0]: 650
        showReadMoreBtn = calculate ? calculate[1]: true

        newsProperties.map(el => {
            if (el.id === item.nid && el.value === false) {
                return showNews = true
            }
        })

        const handleShowMoreLess = (e) => {
            showLessMoreText(e, newsProperties, setnewsProperties, item )
        }

        const parseContent = parseEntities(item.field_news_content)
        const myContent = props.articlesType === 'myContent'
        const showReadLeesMore = (props.isFavoritesPage && !item.group_target_id) || myContent
        return (
                item.access === false ? null : (
                    <div key={item.nid} id={item.nid} className="article__item">
                        {item.topNews ? <div className="article__notd">News wydania</div> : null}
                        {showReleaseBar && !props.isFavoritesPage  && props.articlesType !== 'live' &&
                        <ReleaseBar releaseNumber={item.title_release} releaseDate={item.release_date_range} index={index}/>
                        }
                        
                        <div className="article__content" style={styles}>
                            <div className="row">
                                {item.field_special_news === 1 && <div className="article__notd">News wydania</div>}
                                {item.field_news_positive === 1 && <div className="article__notd positive">Pozytyw na koniec</div>}
                            </div>
                            {!search ?
                            <>
                                {showNews ? '' : <Interweave content={showReadLeesMore ? parseContent.substr(0, maxCharactersNumber) : parseContent} />}
                                {showNews && <Interweave content={parseContent} />}

                                {showReadLeesMore && showReadMoreBtn && item.field_news_content.length > 650 && <button id={item.nid} className={`btn-readLessMore ${showNews ? 'readMore' : ''}`} onClick={(e) => handleShowMoreLess(e)}>{showNews? 'Pokaż mniej' : 'Pokaż więcej'}</button>}  
                            </>
                                 :
                                <Highlighter
                                    highlightClassName="highlight"
                                    searchWords={[search] || []}

                                    autoEscape={true}
                                    textToHighlight={parseEntities(item.field_news_content.replace(/<\/p>|<p>/g, `\n`).replace(/<[^>]*>?/gm, ''))}
                                />
                            }
                        </div>
                        {(item.field_multimedia_slider || item.youtube_video) &&
                            <div onClick={(e) => props.openMulimedia(e, item.nid)} className="btn btn--sidebar multimedia__button">Multimedia</div>
                        }
                        <ArticleWrapper
                            title_news={item.title_news}
                            field_news_content={item.field_news_content}
                            creator_logo_img={item.creator_logo_img}
                            creator_external_link={item.creator_external_link}
                            source_external_link={item.source_external_link}
                            source_external_link_override={item.source_external_link_override}
                            source_logo_img={item.source_logo_img}
                            field_news_source={item.field_news_source}
                            nid={item.nid}
                            flagged={item.flagged}
                            vote={item.vote}
                            voteActive={item.voteActive}
                            rating={props.rating}
                            setRating={props.setRating}
                            closeVotingPanel={props.closeVotingPanel}
                            newRating={item.newRating}
                            articlesVoting={props.articlesVoting}
                            votingPanel={props.votingPanel}
                            position={"top"}
                            voteAverage={item.vote_average}
                            actualCount={item.actual_count}
                            addingToFavorite={props.addingToFavorite}
                            shareError={props.shareError}
                            functionalityNotAvailable={props.functionalityNotAvailable}
                            type={type}
                            typeModal={props.typeModal}
                            showModal={props.showModal}
                            />
                    </div>
                )
            )
        }
    )

    return (
        <div className="article">
            {article}
        </div>
    )
}

export default Articles