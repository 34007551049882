import axios from "axios";
import { useState } from "react";

export const useServerCheck = () => {
    const [showMaintenanceMode, setShowMaintenanceMode] = useState(false)

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            try {
                if (error.message === 'Network Error') setShowMaintenanceMode(true)
                else if (String(error.request.status).startsWith('5')) setShowMaintenanceMode(true)
                return Promise.reject(error);
            } catch (errorValue) {
                return Promise.reject(errorValue);
            }
        },
    );

    return showMaintenanceMode
}