/* eslint-disable no-undef */
import React from "react"
import { HashRouter, Route } from "react-router-dom"
import Routes from "./routes/Routes"
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { If, Then, Else } from "react-if";
import { getToken } from './actions/user'
import { GET_TOKEN_FAILED, SMALL_MOBILE, NATIVE_APP } from './constants/types'
import './abv/assets/css/styles.css'
import { getSubscriptionPlans } from './actions/subscriptions'
import { getUserDetails, sendToken, getUserRoles } from './actions/user'
import ScrollToTop from './components/ScrollToTop'
import { nanoid } from 'nanoid'
import { useDeviceDetect } from './hooks/useDeviceDetect'
import { DEVICE_TYPES } from './constants/const'
import MaintenanceMode  from './pages/MaintenanceMode'
import { useServerCheck } from './hooks/useServerCheck'


function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch()
  const { loggedIn, userDetails, settingPreferences, isMobile, isSmallMobile, nativeApp, userRole } = useSelector((state) => state.user)

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      dispatch(getToken())
    }
    else {
      dispatch({ type: GET_TOKEN_FAILED })
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getSubscriptionPlans())
    if (loggedIn) {
      dispatch(getUserDetails())
    }
    if (!loggedIn) {
      const item = document.querySelectorAll("div")
      item.forEach(el => {
        el.classList.remove("dark")
        el.classList.add("light")
      })
    }
  }, [dispatch, loggedIn])
  
  useEffect(() => {
    if(!userDetails.uid || userRole !== undefined) return
    dispatch(getUserRoles(userDetails.uid))
  }, [userDetails])

  const displayMode = localStorage.getItem('mode')

  useEffect(() => {
    localStorage.removeItem('mode');
  }, [])

  const pwaBtnDesktop = document.querySelector('.pwa-button-desktop')
  const pwaBtnMobile = document.querySelector('.pwa-button')

  useEffect(() =>{
    if(loggedIn){
      pwaBtnDesktop && pwaBtnDesktop.classList.add('pwa-desktop')
      pwaBtnMobile && pwaBtnMobile.classList.add('pwa-mobile', 'btn-hide')  
    }
    else{
      pwaBtnDesktop && pwaBtnDesktop.classList.remove('pwa-desktop')
      pwaBtnMobile && pwaBtnMobile.classList.remove('pwa-mobile', 'btn-hide')  
    }
  }, [loggedIn, pwaBtnDesktop, pwaBtnMobile])

  const deviceDetect = useDeviceDetect()
  
  useEffect(() => {
    if (!DEVICE_TYPES.includes(deviceDetect)) return
    pwaBtnMobile?.classList.remove('btn-hide')
  }, [deviceDetect, pwaBtnMobile])

  useEffect(() => {
    if (loggedIn) {
      if (settingPreferences.dark_mode || userDetails.dark_mode) {
        const item = document.querySelectorAll("div")
        let mode = localStorage.getItem('mode');
        const darkMode = settingPreferences.dark_mode ? settingPreferences.dark_mode : userDetails.dark_mode

        localStorage.setItem('mode', darkMode);

        mode = mode ? mode : settingPreferences.dark_mode
        if (mode === "1") {
          item.forEach(el => {
            el.classList.remove("light")
            el.classList.add("dark")
          })
        }

        if (mode === "0") {
          item.forEach(el => {
            el.classList.remove("dark")
            el.classList.add("light")
          })
        }
      }
    }
  }, [userDetails.dark_mode, settingPreferences.dark_mode, displayMode])


  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  let widthBollean = (width <= 1050 ? true : false)
  let smallWidthBollean = (width <= 380 ? true : false)

  if(smallWidthBollean) {
    if (isSmallMobile !== '1') {
      dispatch({ type: SMALL_MOBILE, payload: '1' })
    }
  }

  if(!smallWidthBollean) {
    if (isSmallMobile  !== '0') {
      dispatch({ type: SMALL_MOBILE, payload: '0' })
    }
  }

  if (widthBollean) {
    if (isMobile !== "1") {
      dispatch({ type: 'MOBILE', payload: "1" })
    }
  }

  if (!widthBollean) {
    if (isMobile !== "0") {
      dispatch({ type: 'MOBILE', payload: "0" })
    }
  }

const pwaButton = window.innerWidth <= 1050 ? 'pwa-button' : 'pwa-button-desktop'

let deferredPrompt;
var btnAdd = document.querySelector(`.pwa-button`);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPrompt = e;
      let btnAdd = document.querySelector(`.pwa-button`);
      if(localStorage.getItem('pwa-installed') === 'true') localStorage.setItem('pwa-installed', false)
      btnAdd.classList.remove('btn-hide')
      btnAdd?.addEventListener('click', (e) => {
        if (btnAdd.classList.contains('pwa-mobile') || btnAdd.classList.contains('pwa-desktop')) {
          deferredPrompt.prompt();
          deferredPrompt.userChoice
            .then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
                btnAdd.style.display = 'none';
                localStorage.setItem('pwa-installed', true)
              } else {
                console.log('User dismissed the A2HS prompt');
                btnAdd.style.display = 'block';
              }
              deferredPrompt = null;
            });
        }
      });

    });

    window.addEventListener('appinstalled', () => {
      hideInstallPromotion();
      deferredPrompt = null;
      console.log('PWA was installed');
    });
  }, [btnAdd])


  window.runOnNativeApp=function(){
    if(nativeApp) return
    dispatch({type: NATIVE_APP})
  }
  const pwaInstalled = localStorage.getItem('pwa-installed')

  useEffect(() => {
    if (!btnAdd || !pwaInstalled) return
    if (pwaInstalled === 'true') btnAdd.style.display = 'none'
    else btnAdd.style.display = 'block'
  }, [pwaInstalled, loggedIn, btnAdd])

  const isNewFirebaseToken = localStorage.getItem('firebaseNewToken')


  useEffect(() => {
    if(isNewFirebaseToken && isNewFirebaseToken === 'false') return
  
    const device_id = nanoid(35)
    const token = localStorage.getItem('firebaseToken')
    if(!token) return

    if (loggedIn) {
      if (userDetails.uid) sendToken(token, userDetails.uid, device_id)
    }
    else sendToken(token, null, device_id)
    
  }, [isNewFirebaseToken, userDetails])

  const maintenanceMode = useServerCheck()

  return (
    <div className="App">
      <HashRouter>
        <If condition={maintenanceMode}>
          <Then>
            <MaintenanceMode />
          </Then>
          <Else>
            <ScrollToTop />
            <Route component={Routes} />
          </Else>
        </If>
      </HashRouter>
    </div>
  );
}

export default App;

