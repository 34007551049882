import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import ActivePlan from '../ActivePlan'
import Modal from '../modals/Modal'
import { SHOW_MODAL, CLOSE_MODAL, SELECTED_PLAN } from '../../constants/types'
import icon1 from '../../abv/src/images/icon-1.png'
import icon2 from '../../abv/src/images/icon-2.png'
import icon3 from '../../abv/src/images/icon-3.png'

const Subscription = () => {
    const dispatch = useDispatch()
    const { subscription_renewal, subscription_plan_name, subscription_plan_id } = useSelector((state) => state.user.userDetails)
    const { nativeApp } = useSelector(state => state.user)
    const [showModal, setShowModal] = useState(false)
    const openModal = () => {
        setShowModal(prev => !prev)
        dispatch({ type: SHOW_MODAL, payload: 'modal--change' })
    }

    const closeModal = () => {
        setShowModal(false)
        dispatch({ type: CLOSE_MODAL })
    }

    const handleSelectedPlan = () =>{
        dispatch({ type: SELECTED_PLAN, payload: JSON.parse(subscription_plan_id)})
    }
    return (
        <div className="profile">
            <div className="profile__subscription">
                <ActivePlan />
                {!nativeApp && <Link to='/premium' className="profile__plan--change">Zmień plan</Link>}
            </div>

            {subscription_plan_name && subscription_plan_name !== "Free" && (
                <>
                {/* //TODO: turn on when  auto-renewal will be available*/}
                
                    {/* <div className="profile__payment">
                        {subscription_renewal ? (
                            <>
                                <div className="profile__payment--subtitle">Posiadasz comiesięczną, automatycznie odnawialną subskrypcję.</div>
                                <button className="btnReset btn-cancel" onClick={openModal}>Anuluj automatyczne odnawianie planu</button>
                            </>
                        ) : (
                            <>
                                <div className="profile__payment--title">Przejdź na autoodnawianie</div>
                                <div className="profile__payment--subtitle">Cykliczny pobór opłaty po wygaśnięciu obecnego pakietu - możesz w każdej chwili anulować na tej stronie</div>
                                <form className="payment__list">
                                    <div className="form__plan--icons setting-subscription">
                                        <div className="form__plan--icon payment__list--item">
                                            <input type="radio" name="choosePlan" />
                                            <label>
                                                <img src={icon1} alt="x" />
                                            </label>
                                        </div>

                                        <div className="form__plan--icon payment__list--item">
                                            <input type="radio" name="choosePlan" />
                                            <label>
                                                <img src={icon3} alt="x" />
                                            </label>
                                        </div>

                                        <div className="form__plan--icon payment__list--item">
                                            <input type="radio" name="choosePlan" />
                                            <label>
                                                <img src={icon2} alt="x" />
                                            </label>
                                        </div>
                                    </div>
                                    <button className="btn btn--sidebar setting-subscription">Przedłużam i płacę</button>
                                </form>
                            </>
                        )}

                    </div> */}
                    {!nativeApp && !subscription_renewal&& (
                        <div className="term__extent">
                            <div className="term__extent--title">Przedłużanie wybranego okresu płacąc z góry.</div>
                            <Link className="btn btn--sidebar small-button setting-subscription" to='/settings/subscription/extension' onClick={handleSelectedPlan}>Przedłuż</Link>
                        </div>
                    )}
                </>
            )}
            {showModal &&
                <Modal type="unsubscribingModal " closeModal={closeModal} />
            }
            
        </div>

    )
}

export default Subscription

