import axios from 'axios'
import { GET_TOKEN, GET_TOKEN_FAILED, LOGIN, LOGIN_FAILED, LOGOUT, DELETE_ACCOUNT, REGISTER, REGISTER_FAILED, ACCOUNT_ACTIVATION, ACCOUNT_ACTIVATION_FAILED, CHANGE_PASSWORD, CHANGE_PASSWORD_FAILED, RESET_PASSWORD, GET_USER_DETAILS, SET_USER_DETAILS, CHANGE_RENEVAL_PERIOD, SET_FAVOURITE_CREATORS, EMAIL_TO_RESET_PASS, DISCOUNT_CODE, DISCOUNT_CODE_FAILED, EMAIL_TO_RESET_PASS_FAILED,RESET_PASSWORD_FAILED, ACCOUNT_SETTINGS_LOADED, ADD_AFFILIATE_CODE, ADD_AFFILIATE_CODE_FAILED, GET_AFFILIATE_CREATOR_UID, CREATE_CAMPAIGN, GET_ALL_CAMPAIGNS, GET_AFFILIATE_CREATOR_UID_FAILED, GET_USER_ROLES } from '../constants/types'
import {API_URL, setHeaders} from '../api/index'

export const getToken = () =>  async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: `${API_URL}/jwt/token`,
            headers: setHeaders()
          })
          dispatch({type: GET_TOKEN, payload: res.data.token})
    } catch (error) {
        console.log(error)
        dispatch({type: GET_TOKEN_FAILED})
    }
}
export const login = (formData) => async (dispatch) => {
    try {
      const res = await axios({
        method: 'post',
        url: `${API_URL}/user/login?_format=json`,
        data: formData
      })
        dispatch({
            type: LOGIN,
            payload: res
        })
        getUserDetails()
    } catch (error) {
        dispatch({ type: LOGIN_FAILED, payload: error.response?.data.message})
    }
}

export const register = (formData, plan) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${API_URL}/user/register?_format=json`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                ...formData,
                //TODO: add when will be backend access to role field
                // 'roles': ['regular_user']
            }
        })
        // if(plan !== "1"){
        //    await axios({
        //         method: 'post',
        //         url: `${API_URL}/api/v1/create_user_subscription?_format=json`,
        //         data: {
        //             "subscription_plan_id": plan,
        //             "subscription_renewal_period": "+1month",
        //             "subscription_duration": "+1month",
        //             "subscription_user_id": res.data.uid[0].value
        //           }
        //     })
        // }
        dispatch({
            type: REGISTER,
            payload: res.data.name[0].value
        })
    } catch (error) {
        dispatch({ type: REGISTER_FAILED, payload: error.response.data.message})
    }
}

export const accountActivation = (formData) => async (dispatch) => {
    try {
      const res = await axios({
        method: 'post',
        url: `${API_URL}/api/v1/user_register_code_verification?_format=json`,
        data: formData
      })
        dispatch({
            type: ACCOUNT_ACTIVATION,
            payload: true
        })
    } catch (error) {
        dispatch({ type: ACCOUNT_ACTIVATION_FAILED, payload: error.response.data})
  }
}

export const sendEmailToResetPass = (email) =>  async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${API_URL}/user/lost-password?_format=json`,
            data: email
        })
        dispatch({
            type: EMAIL_TO_RESET_PASS,
            payload: res
        })
    } catch (error) {
        dispatch({ type: EMAIL_TO_RESET_PASS_FAILED, payload: error.response.data.message})
    }
}

export const resetPassword = (data) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${API_URL}/user/lost-password-reset?_format=json`,
            data
        })
        dispatch({
            type: RESET_PASSWORD,
            payload: true
        })
    } catch (error) {
        dispatch({ type: RESET_PASSWORD_FAILED, payload: error.response.data.message})
    }
}

export const changePassword = (formData, uid) => async (dispatch) => {
    try {
        await axios({
            method: 'patch',
            url: `${API_URL}/user/${uid}?_format=json`,
            headers: setHeaders(),
            data: {
                "pass": [{
                    "existing": formData.existingpass,
                    "value": formData.pass,
                }]
            }
        })
        dispatch({ type: CHANGE_PASSWORD})
    } catch (error) {
        console.log(error.response)
        dispatch({ type: CHANGE_PASSWORD_FAILED, payload: error.response.data.message})

    }
}

export const deleteAccount = () => async (dispatch) => {
    try {
        await axios({
            method: 'patch',
            url: `${API_URL}/api/v1/user_account_cancel?_format=json`,
            headers: setHeaders(),
            data: {}
        })
        dispatch({ type: DELETE_ACCOUNT })
    } catch (error) {
        console.log(error)
    }
}

export const logout = () => async (dispatch) => {
    try {
        dispatch({ type: LOGOUT })
    } catch (error) {
        console.log(error)
    }
}

export const getUserDetails = () => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: `${API_URL}/api/current_user?_format=json`,
            headers: setHeaders()
        })
        dispatch({
            type: GET_USER_DETAILS,
            payload: res.data[0].data
        })
        dispatch({type: ACCOUNT_SETTINGS_LOADED, payload: true})
    } catch (error) {
        console.log(error)
    }
}

export const setUserDetails = (preferences) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'patch',
            url: `${API_URL}/api/v1/canonical_user_preferences?_format=json`,
            headers: setHeaders(),
            data: preferences
        })
        dispatch({
            type: SET_USER_DETAILS,
            payload: JSON.parse(res.config.data)

        })
    } catch (error) {
        console.log(error)
    }
}

export const extendSubscription = (duration) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'patch',
            url: `${API_URL}/api/v1/canonical_user_preferences?_format=json`,
            headers: setHeaders(),
            params: {
                "subscription_duration_extend": `+${duration}month`
            }
        })
        dispatch({
            type: SET_USER_DETAILS,
            payload: res.data[0].data
        })
    } catch (error) {
        console.log(error)
    }
}

export const changeRenevalPeriod = () => async (dispatch) => {
    try {
        const res = await axios({
            method: 'patch',
            url: `${API_URL}/api/v1/canonical_user_subscription?_format=json`,
            headers: setHeaders(),
            data: {
                "subscription_renewal_period": "",
                "subscription_renewal" :  false
            }
        })
        dispatch({
            type: CHANGE_RENEVAL_PERIOD,
            payload: res
        })
    } catch (error) {
        console.log(error)
    }
}

export const setFavouritesCreators = (flagData) => async (dispatch) => {
    try {
      const res = await axios({
        method: 'patch',
        url: `${API_URL}/api/v1/canonical_user_favourites_creators?_format=json`,
        headers: setHeaders(),
        data: {
          "creator_flags": [
            ...flagData
          ]
        }
      })
      dispatch({
        type: SET_FAVOURITE_CREATORS,
        payload: res.data
      })
     
    } catch (error) {
      console.log(error)
    }
  }

  export const activationDiscount = (data) => async (dispatch) => {
    try {
      const res = await axios({
        method: 'post',
        url: `${API_URL}/api/v1/validate_discount_code`,
        headers: setHeaders(),
        data
      })

      dispatch({
        type: DISCOUNT_CODE,
        payload: res.data[0].data
      })
    } catch (error) {
      dispatch({ type: DISCOUNT_CODE_FAILED, payload: error.response.data[0]})

    }
  }


  export const sendToken = async (firebaseToken, uid, device_id)  => {
    try {

        const res = await axios({
            method: 'post',
            url: `${API_URL}/entity/firebase_token`,
            headers: setHeaders(),
            data: {
                uid: [uid],
                token: [firebaseToken],
                device_id: [`${device_id}=`]
            }
          })
          
          console.log(res)
    } catch (err) {
        console.log(err);
    }
  }

  
  export const getAffiliateCreatorUid = (shortTag) => async (dispatch) => {

    try {
        const res = await axios({
            method: 'get',
            url: `${API_URL}/api/affiliate/shorttag/${shortTag}`,
            headers: setHeaders(),
        })

        if(res.data.uid) dispatch({type: GET_AFFILIATE_CREATOR_UID, payload: res.data})
        else dispatch({type: GET_AFFILIATE_CREATOR_UID_FAILED})

    } catch (error) {
        console.log(error);
    }
}


export const createAffiliateCampaign = (data) => async (dispatch) => {

    try {
        const res = await axios({
            method: 'post',
            url: `${API_URL}/entity/affiliate_campaign`,
            headers: setHeaders(),
            data: {
                "title": [{"value": data.campaignTitle}]
            }
        })

        dispatch({type: CREATE_CAMPAIGN, payload: res.data})

    } catch (error) {
        console.log(error);
    }
}

export const getAffiliateCampaign = (id) => async (dispatch) => {
    try {

        const res = await axios({
            method: 'get',
            url: `${API_URL}/affiliate_campaign/${id}?_format=json`,
            headers: setHeaders(),
        })

       console.log(res);
    } catch (error) {
        console.log(error);
    }
}


export const addAffiliateCode = (value, uid) => async (dispatch) => {
    try {

        await axios({
            method: 'patch',
            url: `${API_URL}/user/${uid}?_format=json`,
            headers: setHeaders(),
            data: {
                field_user_affiliate: { "value": value.uid },
                field_campaign_affiliate: {"value": value.id}

            }
        })

        dispatch({ type: ADD_AFFILIATE_CODE, payload: ''})
    } catch (error) {
        dispatch({ type: ADD_AFFILIATE_CODE_FAILED, payload: error.response.data.message })
    }
}

export const getAllAffiliateCampaigns = () => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: `${API_URL}/api/my-campaigns`,
            headers: setHeaders(),
        })

        dispatch({ type: GET_ALL_CAMPAIGNS, payload: res.data })

    } catch (error) {
        console.log(error);
    }
}


export const getAllAffiliateClients = (idCampaign) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: `${API_URL}/api/my-campaigns/${idCampaign}/clients`,
            headers: setHeaders(),
        })

        dispatch({ type: 'GET_AFFILIATE_CLIENTS', payload: res.data })

    } catch (error) {
        console.log(error);
    }
}

export const getUserRoles = (uid) => async (dispatch) => {
    try {

        const res = await axios({
            method: 'get',
            url: `${API_URL}/user/${uid}?_format=json`,
            headers: setHeaders()
        })

        dispatch({
            type: GET_USER_ROLES,
            payload: res.data.roles ?  res.data.roles : false
        })
    } catch (error) {
        console.log(error)
    }
}