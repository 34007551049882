import { Redirect } from 'react-router-dom'
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCreators } from '../actions/articles'
import { API_URL } from '../api'
import Interweave from 'interweave'
import { useState } from 'react'
import { setFavouritesCreators } from '../actions/user'
import { RESET_FAVOURITE_CREATORS } from '../constants/types'
import SiteNotice from '../components/SiteNotice'
import Loader from "../components/home/Loader"
import { GET_CREATORS } from '../constants/types'
import {parseEntities} from 'parse-entities'


const MyContent = () => {
    const { loggedIn, isCreatorsUpdate, siteMessage } = useSelector((state) => state.user)
    const { creators } = useSelector((state) => state.articles)
    const [creatorsList, setCreatorsList] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        setCreatorsList(creators)
    }, [creators])

    const [isPageReload, setIsPageReload] = useState(false)
    
    useEffect(()=>{
        dispatch({ type: GET_CREATORS, payload: []})
        setIsPageReload(true)
    }, [])

    useEffect(() => {
        if(isPageReload) dispatch(getCreators({recommended: "All", field_private_value: 0}))
    }, [isPageReload])

    const changeValue = (item) => {
        setCreatorsList(list => {
            const index = list.findIndex(el => el.tid === item.tid);
            const newList = [...list];
            newList[index] = {
                ...newList[index],
                flagged: item.flagged === "1" ? "0" : "1",
            }
            return newList;
        })
    }

    const changeFlagData = creatorsList?.map(item => ({
        id: item.tid,
        action: item.flagged === "1" ? "flag" : "unflag"
    }))

    useEffect(() => {
        if (isCreatorsUpdate) (
            dispatch({ type: RESET_FAVOURITE_CREATORS })
        )
    }, [creatorsList, dispatch])

    const creatorsListShowing = creatorsList?.map(item => (
        <li key={item.tid} className="myContent__item">
            <input
                id={item.tid}
                type="checkbox"
                name="creators"
                checked={item.flagged === "1" ? true : false}
                onChange={() => changeValue(item)}
            />
            <div className="myContent__item--logo" style={{ backgroundImage: `url(${API_URL}${item.logo})` }} ></div>
            <label className="myContent__item--name">
                <a href={item.external_link} className="myContent__item--title">{parseEntities(item.name)}</a>
                <Interweave content={parseEntities(item.description)} />
            </label>
        </li>
    ))

    const handleCreatorsFlagSubmit = (e) => {
        e.preventDefault()
        dispatch(setFavouritesCreators(changeFlagData))
    }

    useEffect(()=>{
        if(siteMessage){
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'auto'
            });
        }
    },[siteMessage])

    if (!loggedIn) {
        return <Redirect to='/' />;
    }
    return (
        <div className="content">
            <div className="content-inner">
                {creators?.length !== 0 &&
                    <div className="myContent">
                        <h4 className="block-title">Twórcy</h4>
                        <div className="myContent__description">Wybierz twórców, z którymi chcesz być na bieżąco, a z każdym nowym wydaniem Infopiguły otrzymasz ich wszystkie nowe treści zestrumieniowane w zakładce Twórcy.</div>
                        <form className={isCreatorsUpdate ? "myContent__creators myContent__creators--confirm" : "myContent__creators"} onSubmit={handleCreatorsFlagSubmit}>
                            <ul className="myContent__list listReset">
                                {creatorsListShowing || [...Array(5)].map((x, i) =>
                                    <Loader type="creators"/>
                                )}
                            </ul>
                            <input className="btn form-submit btn-myContent" type="submit" value="Zatwierdź" />
                        </form>
                    </div>}
            </div>
            {isCreatorsUpdate && <SiteNotice />}
        </div>
    )
}

export default MyContent