import { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux'
const ReleasesSelect = (props) => {
    const [showOptions, setShowOptions] = useState(false);
    const { form_options } = useSelector((state) => state.articles.articles)

    const handleClick = (e) => {
        props.setOpt(form_options?.last_releases)
        props.setReleasePeriod({
            value: e.target.id,
            label: e.target.textContent
        });
        setShowOptions(false);
        props.handleFilter(e)
    };

    let useClickOutside = (handler) => {
        let domNode = useRef();

        useEffect(() => {
            let maybeHandler = (e) => {
                if (!domNode.current?.contains(e.target)) {
                    handler();
                }
            };
            document.addEventListener("mousedown", maybeHandler);
            return () => {
                document.removeEventListener("mousedown", maybeHandler);
            };
        });
        return domNode;
    };

    const handleShowOptions = () => {
        setShowOptions(!showOptions);
    };

    const releases = props.opt?.map((release, index) => (
        <div className="release">
            <input id={release.target_id} value={release.value} type="checkbox" className="release--checkbox" onChange={(e) => props.addRelease(index, e)} checked={props.opt[index].checked ? true : false} />
            <label htmlFor={release.target_id} className="release--title">{release.value.replaceAll('.', ' / ')}</label>
        </div>
    ))

    const relasesTemporalDistancing = props.options.map((release) => (
        <div id={release.value} className={props.releasePeriod.value === release.value ? 'option active' : 'option'} onClick={handleClick}>
            {release.label}
        </div>
    ))

    let domNode = useClickOutside(() => {
        setShowOptions(false);
    });

    return (
        releases ?
            <div ref={domNode}>
                <div className="content-date">
                    <div className="content__type--title" >Wydanie</div>
                    <div className={showOptions ? 'content__type--select show-options' : 'content__type--select'} onClick={handleShowOptions}>
                        <div className="singleValue">{props.releasePeriod.label ? props.releasePeriod.label : "Według daty"}</div>
                    </div>
                </div>
                {showOptions && (
                    <div className='options__list'  >
                        <div className="options__list-content">
                            {relasesTemporalDistancing}
                        </div>
                        <div className="options__list-content">
                            {releases}
                        </div>
                    </div>
                )}
            </div>
            : null
    )
}

export default ReleasesSelect