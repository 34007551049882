import { useSelector } from 'react-redux'

const PremiumModal = ({ closeModal }) => {
    const { subscription_plan_name, subscription_end } = useSelector((state) => state.user.userDetails)
    return (
        <>
            <div className="modal__sectionTitle">Czy na pewno chcesz zrezygnować z planu {subscription_plan_name} i przejść na plan darmowy?</div>
            <div className="modal__sectionDescription">Twój plan {subscription_plan_name} będzie aktywny do <span className="modal__date">{subscription_end}</span></div>
            <div className="modal__wrapper">
                <button className="modal__confirmAction btn form-submit">Przejdź na Free</button>
                <button className="modal__cancelAction btn btn--sidebar" onClick={closeModal}>Anuluj</button>
            </div>

            <button className="modal__close btnReset" onClick={closeModal}>
                <span className="visually-hidden">Close modal window</span>
            </button>
        </>
    )
}

export default PremiumModal