export const useDeviceDetect = () => {

        const userAgent = navigator.userAgent;
        if (/android/i.test(userAgent)) {
            if(userAgent.match(/firefox|fxios/i)){
                return 'androidFirefox'
            }
            if(userAgent.match(/opr\//i)){
                return 'androidOpera'
            }
        }
        if(/iPad|iPhone|iPod/i.test(userAgent)){
            if(userAgent.match(/chrome|chromium|crios/i)){
                return 'iosOtherBrowser'
            }
            if(userAgent.match(/firefox|fxios/i)){
                return 'iosOtherBrowser'
            }
            if(userAgent.match(/opr\//i)){
                return 'iosOtherBrowser'
            }
            if(userAgent.match(/edg/i)){
                return 'iosOtherBrowser'
            }
            if(userAgent.match(/safari/i)){
                return 'iosSafari'
            }
        } else {
            return 'desktop'
        }

}