import {useState, useEffect, useRef} from 'react'
import ReactSlider from "react-slick";
import { Textfit } from 'react-textfit';
import ArticleWrapper from "./AricleWrapper";
import Loader from "./Loader";
import ReactPaginate from 'react-paginate';
import {useSelector} from "react-redux";
import {parseEntities} from 'parse-entities'

const PreviewLength = 10

const settings = {
    infinite: false,
    slidesToShow: 1,
    speed: 300,
    rows: 1,
    slidesPerRow: 1,
    adaptiveHeight: true,
    arrows:false
}

const Slider = (props) => {

    const {slider_background} = useSelector((state) => state.user.settingPreferences)

    const [goTo, setGoTo] = useState(false)
    const nav1 = useRef(null)
    const nav2 = useRef(null)
    const [activeSlide, setActiveSlide] = useState(0)
    const [currentStep, setCurrentStep] = useState(0)
    const [content, setContent] = useState([])

    const ChangeContent = (type) =>{
        !goTo && setGoTo(true)
        const e = {
            target:{
                id: type
            }}
        props.changeSlide(e)
    }

    // const slidesWrapper = document.querySelector('.slider-bottom')
    // if (slidesWrapper) {
    //     const slides = slidesWrapper.querySelectorAll('.slick-slide')
    //     const slidesArray = Array.from(slides)
    //     slidesArray.map(item => { if (!item.classList.contains('slick-active')) return item.classList.add("inactive-slide") })
    // }


    const contentSettings = {
        ...settings,
        dots: true,
        onSwipe: slideSide => {
            if(slideSide === "left" && activeSlide === content.length-1 && props.type !== "global"){
                ChangeContent("global")
            }
            else if(slideSide === "right" && activeSlide === 0 && props.type !== "poland"){
                ChangeContent("poland")
            }
        },
        beforeChange: (currentStep, nextStep) => {
                setActiveSlide(nextStep)
                setCurrentStep(currentStep)
        },
        appendDots: () => (
                    <ReactPaginate
                        breakClassName={'break-me'}
                        pageCount={content.length || 10}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={(e) => {
                            nav1.current.slickGoTo(e.selected)
                        }}
                        containerClassName={'pagination color__'+slider_background}
                        activeClassName={'active'}
                        forcePage={activeSlide}
                    />
            )
    };

    useEffect(() => {
        if (props.loadedArticles.length === 0)
            setContent(props.loadedArticles)
        else   if (props.hasMore){
            props.fetchMoreData()
        }
        else{
            setContent(props.loadedArticles)
            if (activeSlide !== currentStep) return
            if (props.type === "poland" && goTo){
                setTimeout(()=> nav1.current?.slickGoTo(props.loadedArticles.length-1), 100)
            }
            else if (props.type === "global") {
                setTimeout(()=> nav1.current?.slickGoTo(0), 100) // hard kode but no way IN-933 can fix this Timeout
            }
        }
    }, [props.loadedArticles, props.hasMore, content, currentStep])


    return(
        <>
                <ReactSlider
                    asNavFor={nav2.current}
                    ref={nav1}
                    {...contentSettings}
                    className={"slider__content color__"+slider_background}
                >

                    {content.length > 0 ? content.map((item) => {
                    const image = item.field_multimedia_slider?.split(",")
                        return(
                            <div>
                                <Textfit max={18} className={`article__content slider color__${slider_background} ${item.field_multimedia_slider ? "article-img" : ""}`} forceSingleModeWidth={false}  mode="multi">
                                    <div className=" row row--instaview">
                                        {item.field_special_news === 1 && <div className="article__notd">News wydania</div>}
                                        {item.field_news_positive === 1 && <div className="article__notd positive">Pozytyw na koniec</div>}
                                    </div>
                                {item.field_multimedia_slider &&
                                <img className="slider-img" src={image[0]} alt="x" />
                             }
                                    <div>
                                        {parseEntities(item.field_news_content.replace(/<[^>]*>?/gm, ''))}
                                    </div>
                                </Textfit>
                            </div>
                        )
                    }) : [...Array(PreviewLength)].map(() =>
                        <div className={"article__content slider placeholder color__"+slider_background} ><Loader type="sliderContent"/></div>

                    )}
                </ReactSlider>
                <ReactSlider
                    asNavFor={nav1.current}
                    ref={nav2}
                    fade={true}
                    swipe={false}
                    {...settings}
                    className={"slider-bottom"}
                >
                    {content.length > 0 ? content.map((item) => {
                        return(
                            <div>
                                <ArticleWrapper
                                    title_news={item.title_news}
                                    field_news_content={item.field_news_content}
                                    creator_logo_img={item.creator_logo_img}
                                    nid={item.nid}
                                    vote={item.vote}
                                    voteActive={item.voteActive}
                                    rating={props.rating}
                                    setRating={props.setRating}
                                    closeVotingPanel={props.closeVotingPanel}
                                    newRating={item.newRating}
                                    articlesVoting={props.articlesVoting}
                                    votingPanel={props.votingPanel}
                                    position={"right"}
                                    shareError={props.shareError}
                                    flagged={item.flagged}
                                    addingToFavorite={props.addingToFavorite}
                                    voteAverage={item.vote_average}
                                    actualCount={item.actual_count}
                                    functionalityNotAvailable={props.functionalityNotAvailable}
                                    sliderImg={item.source_logo_img}
                                    linkImg={item.source_external_link_override ? item.source_external_link_override:  item.source_external_link}
                                    closeAll={props.closeAll}
                                />
                            </div>
                        )
                    }) : [...Array(PreviewLength+1)].map(() =>
                        <div className="slider__footer"><Loader type="sliderFooter"/></div>


                    )}
                </ReactSlider>
        </>
    )
};

export default Slider