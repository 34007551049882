import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { IsFreeAccount } from '../../utils/userDetails'
const SidebarRight = (props) => {
    const { loggedIn, userDetails, nativeApp } = useSelector((state) => state.user)
    const freeAccount = IsFreeAccount()

    const dispatch = useDispatch()

    const openModal = () => {
        dispatch({ type: 'SHOW_MODAL', payload: 'modal--change' })
    }

    const handlePwaButton = () => {
       if(loggedIn) return
        props.setShowModal(true)
        props.setTypeModal('unavailableForNotLoggedInModal')  
        openModal()
    }
    return (
        <>
            {!loggedIn && userDetails.subscription_plan_name !== "Free"  && (
                <>
                 <Link to="/user/login" className="btn btn-user">Zaloguj się</Link>
                    <div className="sidebar__register">
                        <h4 className="block-title">Zarejestruj się w Infopigule</h4>
                        <ul className="register__list listReset">
                            <li className="register__item">czytaj wszystko, co ma znaczenie</li>
                            <li className="register__item">odblokuj interakcje serwisu</li>
                            <li className="register__item">pobierz apkę na telefon</li>
                        </ul>
                        <Link to="/user/register" className="btn btn--sidebar">Zarejestruj się</Link>
                    </div>
                </>
            )}

            <div className="social-wrapper">
                {loggedIn &&
                    <div className="header__inner inner">
                        <div className="header__profile">
                            {nativeApp
                                ? <div className="header__profile--name">infopiguła</div>
                                : <div className="header__profile--name">{userDetails.subscription_plan_name}</div>
                            }
                        </div>
                    </div>
                }

                {freeAccount && (
                        <div className="sidebar-wrapper">
                            <h4 className="block-title">Wykup konto Premium</h4>
                            <Link to="/premium" className="btn btn--sidebar">Zobacz korzyści</Link>
                        </div>
                )}

                <div className="pwa-wrapper">
                        {/* <button className="pwa-button-desktop" onClick={handlePwaButton}><span className="pwa-title"> Infopiguła</span> - pobierz aplikację</button> */}
                    </div>
                    <div>
                    <div className="social__icons">
                        <div className="social__icon facebook"><Link to="#" onClick={() => { window.open("https://www.facebook.com/infopigula") }}></Link></div>
                        <div className="social__icon instagram"><Link to="#" onClick={() => { window.open(" https://www.instagram.com/infopigula") }}></Link></div>
                    </div>
                    
                </div>
                
            </div>
        </>
    )
}

export default SidebarRight
