import {Link} from "react-router-dom";
import { useSelector } from "react-redux"

const Footer = () => {
    const {nativeApp} = useSelector(state =>state.user)

    return (
        <div className="page__footer">
            <div className="footer__item">
                {nativeApp
                    ? <Link className="regulations-link regulations-bold color-link color-link--footer" to="/">www.infopigula.pl</Link>
                    : <a href="mailto:newsy@infopigula.pl">newsy@infopigula.pl</a>
                }
            </div>
            <div className="footer__item">
                <Link to='/contact-rules' className="reg-link">Kontakt</Link>
                <Link to='/contact-rules' className="reg-link">Zasady</Link>      
            </div>
        </div>
    )
}

export default Footer
