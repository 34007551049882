import Header from '../components/Header'

const MaintenaceMode = () => {
    return (
        <div className="maintenance">
            <Header />
            <div className="page__main">
                <p>Witaj.</p>
                <p>Wprowadzamy dla Ciebie pożądane ulepszenia.</p>
                <p>Odśwież swoją stronę/apkę.  Nie pomogło? Sprawdź za kilka minut.</p>
                <p>Jeśli to nie pomoże, wyślij nam zgłoszenie o problemie na <a className="contact-mail" href="mailto:newsy@infopigula.pl">newsy@infopigula.pl</a></p>
                <p>Ostatecznie, wyczyść pamięć  aplikacji lub ją reinstaluj. Postaramy się, abyś nie musiał tego robić w przyszłości</p>
            </div>
        </div>
    )
}

export default MaintenaceMode