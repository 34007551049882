import { useSelector } from 'react-redux'
import parse, { domToReact } from 'html-react-parser'

const PlansList = (props) => {
    const { subscriptionPlans } = useSelector((state) => state.subscriptions)

    const plans = subscriptionPlans.map(item => item.details.replace(/<.?ul>/g, ""))
    const plan = plans?.map(item => parse(item, {
        replace: ({ name, children }) => {
            if (name === 'li') {
                return <li className={props.classname}>{domToReact(children)}</li>;
            }
        },
    }))
    return plan[props.plan]
}

export default PlansList