/* eslint-disable no-undef */
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import { sendToken } from './actions/user'

import "firebase/messaging";
import "firebase/firestore";
import "firebase/remote-config";
import "firebase/database";
import "firebase/performance";
import "firebase/storage";
import "firebase/functions";
import "firebase/auth";
import firebase from 'firebase/app';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);


if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {

    navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function(registration) {
      console.log('Registration sw-firebase successful, scope is:', registration.scope);
      registration.update()
    }).catch(function(err) {
      console.log('Service worker firebase registration failed, error:', err);
    });

    navigator.serviceWorker.register('/sw.js').then(function (registration) {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function (err) {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}

const firebaseConfig = {
  apiKey: "AIzaSyAm4NJhpkwO4-QqQDD8fNotZiHTGDvLpRE",
  authDomain: "ipigula.firebaseapp.com",
  projectId: "ipigula",
  storageBucket: "ipigula.appspot.com",
  messagingSenderId: "500382626381",
  appId: "1:500382626381:web:298958a2127c0790d92204",
  measurementId: "G-6XD02GJRT8"
};


firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();


messaging
  .requestPermission()
  .then(() => {
    return messaging.getToken();
  })
  .then(token => {

    const actualToken = localStorage.getItem('firebaseToken')

    if(token === actualToken){
      localStorage.setItem('firebaseNewToken', false)
    }
    else{
      localStorage.setItem('firebaseToken', token)
      localStorage.setItem('firebaseNewToken', true)

    }

  })
  .catch(err => {
    console.log("No permission", err);
  });


messaging.onMessage(payload => {
    const {title, ...options} = payload.notification;
    navigator.serviceWorker.ready.then(registration => {
      registration.showNotification(title, options);
    });
  });