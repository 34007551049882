import { useEffect } from "react"
import { useDispatch } from 'react-redux'
import Header from "./Header"
import { REGISTER_RESET } from '../constants/types'
import { Link } from 'react-router-dom'
import { ACCOUNT_ACTIVATION_RESET } from '../constants/types'

const RegisterConfirm = () => {
  
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: REGISTER_RESET })
        dispatch({ type: ACCOUNT_ACTIVATION_RESET })
    }, [])

    return (

        <div className="page__layout">
            <header className="page__header header">
                <Header />
            </header>

            <div className="page__mainWpr">
                <main className="page__main">
                    <div className="user-register-form user-register-confirmation">
                        <h2 className="block-title">Twoje konto zostało aktywowane</h2>
                        <div className="register-wrapper">
                        <p className="confirmation-msg">Możesz przejść do panelu logowania  <Link className="button" to="/user/login">Zaloguj się</Link></p>
                        </div>
                    </div>

                </main>

            </div>

        </div>
    )
}

export default RegisterConfirm