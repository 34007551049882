import Interweave from 'interweave'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCreators } from '../actions/articles'
import { API_URL } from '../api'
import Loader from "../components/home/Loader";
import { GET_CREATORS } from '../constants/types'
import {parseEntities} from 'parse-entities'
const Recommended = () => {
    const { creators } = useSelector((state) => state.articles)
    const dispatch = useDispatch()

    const [isPageReload, setIsPageReload] = useState(false)

    useEffect(()=>{
        dispatch({ type: GET_CREATORS, payload: []})
        setIsPageReload(true)
    }, [])

    useEffect(() => {
        if(isPageReload) dispatch(getCreators({recommended: "All" }))
    }, [isPageReload])

    const recommendedCreators = creators?.map(item => (
        item.recommended === "1" && (
            <li className="recommended__item">
            <div className="recommended__item--logo" style={{ backgroundImage: `url(${API_URL}${item.logo})` }}></div>
            <div className="recommended-wrapper">
                <a href={item.external_link} className="recommended__item--title">{parseEntities(item.name)}</a>
                <div className="recommended__item--description">
                    <Interweave content={parseEntities(item.description)} />
                </div>
            </div>

        </li>
        )
    ))

    return (
        <div className="content">
            <div className="content-inner">

                <div className="recommended">
                    <h4 className="block-title">Polecamy</h4>
                    <div className="recommended__description">Wartościowy kontent pod twoją ręką. Twórcy, których polecamy, a których lista będzie rosła z czasem.</div>

                    <ul className="recommended__list listReset">
                        {recommendedCreators || [...Array(5)].map((x, i) =>
                            <Loader type="recommended"/>
                        )}
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default Recommended