import Header from '../Header'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
const PaymentConfirm = () => {
    const { newSubscription } = useSelector((state) => state.subscriptions)

    return (
        <div className="page__layout">
            <header className="page__header header">
                <Header />
            </header>
            <div className="page__mainWpr">
                <main className="page__main">
                    <div className="read-more">
                        <div className="read-moreMsg">{newSubscription ? newSubscription.data.message :
                            <>
                                <p>Jeśli płatność została zrealizowana poprawnie, a subskrypcja nie została aktywowana, odśwież stronę za chwilę.</p>
                                <p>Po zaksięgowaniu płatności zostanie wysłane potwierdzenie na Twój adres e-mail.</p>
                            </>
                        }</div>
                        <Link className="btn btn-readMore" to="/">Powrót do strony głównej</Link>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default PaymentConfirm