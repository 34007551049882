import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import PlansList from '../components/PlansList'
import Modal from '../components/modals/Modal'
import { SHOW_MODAL, CLOSE_MODAL, SELECTED_PLAN } from '../constants/types'
import icon1 from '../abv/src/images/icon-1.png'
import icon2 from '../abv/src/images/icon-2.png'
import icon3 from '../abv/src/images/icon-3.png'
const Premium = () => {
    const [showModal, setShowModal] = useState(false)
    const { loggedIn } = useSelector((state) => state.user)
    const { subscriptionPlans } = useSelector((state) => state.subscriptions)
    const { subscription_plan_id } = useSelector((state) => state.user.userDetails)
    const [inputValue, setInputValue] = useState("")
    const [isRenewal, setIsRenewal] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=>{
        if(subscription_plan_id && !inputValue){
            dispatch({ type: SELECTED_PLAN, payload: JSON.parse(subscription_plan_id)})
        }
    }, [subscription_plan_id])

    const handleInputValue = (e) => {
        setInputValue(e.target.id)
        dispatch({ type: SELECTED_PLAN, payload: JSON.parse(e.target.id) })
    }

    const changePaymentType = (e) => {
        if (e.currentTarget.id === "auto-renewal") setIsRenewal(true)
        else
            setIsRenewal(false)
    }
    const openModal = () => {
        setShowModal(prev => !prev)
        dispatch({ type: SHOW_MODAL, payload: 'modal--change' })
    }

    const closeModal = () => {
        setShowModal(false)
        dispatch({ type: CLOSE_MODAL })
    }
    const plan_id = inputValue ? inputValue : subscription_plan_id
    if (!loggedIn) {
        return <Redirect to='/' />;
    }

    const plans = subscriptionPlans.map((subscription, index) => (
        <div className="form__plan--item" id="freeOption">
            <input id={subscription.id} type="radio" className="form__plan--checkbox" name="planOption" checked={plan_id === subscription.id ? true : false} onChange={handleInputValue} />
            <label>
                {subscription_plan_id === subscription.id && inputValue !== subscription.id ? <span className="your-plan description">Twój plan</span> : null}
                <div className="form__plan--option">
                    <div className="form__plan--title"><span className="TitlePlan">Plan </span>{subscription.name}</div>
                    <div className="form__plan--price">{subscriptionPlans[index].amount / 100}<span className="plans__currency">zł</span> </div>
                </div>
                <span className="option-label btn">{plan_id === subscription.id ? "Wybrany" : "Wybierz"}</span>
            </label>
        </div>
    ))
    const plansTitles = ['Wersja darmowa', 'Korzyści planu Premium', 'Korzyści planu VIP']
    return (
        plan_id ?
            <>
                <div className="content">
                    <div className="content-inner">

                        <form className="acc-renewal user-register-form">

                            <div className="form__plan--wrapper">
                                {plans}
                            </div>

                            <div className="form__plan--content" id="premiumOption">

                                <div className="form__plan--benefits">
                                    <div className="profile__plan--title">{plansTitles[plan_id - 1]}</div>

                                    <ul className="profile__plan--list listReset">
                                        <PlansList plan={plan_id - 1} classname="profile__plan--item" />
                                    </ul>
                                </div>
                                {plan_id !== "1" ? (
                                    <>
                                        {/* <div className="form__item promo-code">
                                            <div className="description">Wpisz kod promocyjny</div>
                                            <label className="form__label visually-hidden">Wpisz kod promocyjny</label>
                                            <input type="text" className="form-text form__field" name="promo-code" />
                                            <button className="btn-renewal premium">Aktywuj kod rabatowy</button>
                                        </div> */}

                                        <div className="payment-renewal">
                                            <div className={isRenewal ? "oneTime-payment" : "auto-renewal active-renewal"} >
                                                <button type="button" id="oneTime-payment" className="btn-renewal" onClick={changePaymentType}>Płatność jednorazowa</button>
                                            </div>
                                            <div className={isRenewal ? "auto-renewal active-renewal" : "auto-renewal "} >
                                                <button type="button" id="auto-renewal" className="btn-renewal" onClick={changePaymentType}>Autoodnawianie</button>
                                            </div>
                                        </div>
                                        {isRenewal ? (
                                            <div className="form__plan--icons">
                                                <div className="form__plan--desc">
                                                    Pracujemy nad tym. Funkcjonalność dostępna wkrótce.
                                                </div>
                                                
                                                
                                                {/* //TODO: turn on when  auto-renewal will be available*/}

                                                {/* <div className="form__plan--icon payment__list--item">
                                                    <input type="radio" name="choosePlan" />
                                                    <label>
                                                        <img className="applePay" src={icon1} alt="x" />
                                                    </label>
                                                </div>

                                                <div className="form__plan--icon payment__list--item">
                                                    <input type="radio" name="choosePlan" />
                                                    <label>
                                                        <img src={icon2} alt="x" />
                                                    </label>
                                                </div>

                                                <div className="form__plan--icon payment__list--item">
                                                    <input type="radio" name="choosePlan" />
                                                    <label>
                                                        <img src={icon3} alt="x" />
                                                    </label>
                                                </div> */}
                                            </div>

                                        ) : (
                                            <div className="form__plan--desc">
                                                Jeżeli chcesz opłacić wybrany okres z góry, przejdź dalej.
                                            </div>
                                        )}
                                    </>
                                ) :

                                     null
                                    // subscription_plan_id !== "1" ? (
                                    //     <button type="button" className="form__plan--submit" onClick={openModal}>Potwierdź</button>
                                    // ) : null
                                }

                            </div>
                            {/* {isRenewal ? (
                                plan_id !== "1" && <button type="button" className="form__plan--submit">Płacę</button>
                            ) : (
                                plan_id !== "1" && <Link to="/settings/subscription/extension" className="form__plan--submit">Przejdź dalej</Link>
                            )} */}

                            {!isRenewal && plan_id !== "1" && <Link to="/settings/subscription/extension" className="form__plan--submit">{plan_id !== subscription_plan_id ? "Zmień plan" : "Przejdź dalej"}</Link> }
                        </form>
                    </div>
                </div>
                {showModal &&
                    <Modal type="premiumModal" closeModal={closeModal} />
                }

            </> : null
    )
}

export default Premium