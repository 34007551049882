import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { deleteAccount } from "../../actions/user";

const DeleteAccountModal = ({ closeModal }) => {
    const {userDetails, deleteAcc} =  useSelector((state) => state.user)
    const history = useHistory();
    const dispatch = useDispatch();

    const handleDeleteAccount = () => {
        dispatch(deleteAccount())
    }

    useEffect(() => {
        if(deleteAcc){
            closeModal()
            history.push("/delete-confirm");
        }
    }, [deleteAcc])

    return (
        <>
            <div className="modal__sectionTitle">Usuń konto</div>
            <div className="modal__sectionDescription">Czy na pewno chcesz usunąć swoje konto? Po usunięciu konta stracisz wszystkie swoje dane i nie otrzymasz od nas więcej żadnej wiadomości.</div>
            <div className="modal__wrapper">
                <button className="modal__confirmAction btn form-submit btn--alert" onClick={handleDeleteAccount}>Usuń konto</button>
                <button className="modal__cancelAction btn btn--sidebar" onClick={closeModal}>Anuluj</button>
            </div>

            <button className="modal__close btnReset" onClick={closeModal}>
                <span className="visually-hidden">Close modal window</span>
            </button>
        </>
    )
}

export default DeleteAccountModal